import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-promotion-tags',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './promotion-tags.component.html',
  styleUrl: './promotion-tags.component.scss'
})
export class PromotionTagsComponent {

  //#region I/O

  hasPendingChanges = input.required<boolean>();

  hasAutoApplicableCoupon = input.required<boolean>();

  coupons = input.required<any[]>();

  fromCartPreview = input.required<boolean>();

  //#endregion
}
