import { Injectable, inject } from '@angular/core';
import { isANoDeliveryDayUser, isANoPaymentMethodUser, isNoAddressUser } from '../../../shared/common/utils';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { OrderService } from '../../../shared/order.service';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { Router } from '@angular/router';
import { CalculationsService } from '../../../shared/calculations.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodSignupService {

  //#region Services

  #localStorageService = inject(LocalStorageService);

  #signalsStoreService = inject(SignalsStoreService);

  #orderService = inject(OrderService);

  #modalContentService = inject(ModalContentService);

  #activeModal = inject(NgbModal);

  #router = inject(Router);

  #calculationsService = inject(CalculationsService);

  //#endregion

  //#region Methods

  constructor() { }

  updateOrder(showDefaultMessage: boolean, isFromCustomBox = false) {

    if (isANoDeliveryDayUser(this.#localStorageService, this.#modalContentService))
      return;

    if (isANoPaymentMethodUser(this.#localStorageService, this.#modalContentService, this.#activeModal))
      return;

    if (isNoAddressUser(this.#localStorageService, this.#modalContentService, this.#activeModal, this.#router))
      return;

    return this.#orderService
      .updateOrder({
        order: this.#setUpFirebaseOrder(),
        firebaseOrderSignal: this.#signalsStoreService.firebaseOrder,
        orderId: isFromCustomBox ? this.#orderService.odooOrder().id : undefined,
        showDefaultMessage
      });
  }

  #setUpFirebaseOrder() {
    const firebaseOrder = this.#signalsStoreService.firebaseOrder();

    if (!firebaseOrder)
      return null

    if (!firebaseOrder?.products)
      return firebaseOrder

    firebaseOrder.products.common = this.#processProducts(firebaseOrder.products.common || []);
    firebaseOrder.products.subscription = this.#processProducts(firebaseOrder.products.subscription || []);

    return firebaseOrder;
  }

  #processProducts(products: any[]): any[] {
    return products.map(product => {
      const totalPrice = this.#calculationsService.calculateProductTotalPrice(product);
      return { ...product, totalPrice };
    });
  }

  //#endregion
}
