@if(isMobile()){
<div class="mobile-nav-bottom">
  <button type="button" class="btn-action" routerLinkActive="active-link" routerLink="/shop">
    <span class="btn-action__icon material-symbols-outlined">nutrition</span>
    <span class="btn-action__text">Products</span>
  </button>
  <button type="button" class="btn-action" routerLinkActive="active-link" routerLink="/favorites">
    <span class="btn-action__icon material-symbols-outlined">favorite</span>
    <span class="btn-action__text">Favorites</span>
  </button>
  <button type="button" class="btn-action" (click)="openModalBundles()">
    <span class="btn-action__icon material-symbols-outlined">orders</span>
    <span class="btn-action__text">Farm box</span>
  </button>
  <button type="button" class="btn-action" routerLinkActive="active-link" routerLink="/cart">
    <span class="btn-action__icon material-symbols-outlined">shopping_cart</span>
    <span class="btn-action__text">Cart</span>
    @if (orderProductsQuantity()) {
    <span class="quanity-badge">
      {{ orderProductsQuantity() }}
    </span>
    }
  </button>
</div>
}