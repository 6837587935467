<div class="container-fluid animated fadeIn">

  <form class="needs-validation">

    <div class="row form-row">
      <div class="col-md-4">
        <div class="form-floating has-validation">
          <input placeholder type="text" class="form-control"
            [class.is-invalid]="!personalInformationValidations.get('firstName')?.()?.valid" name="firstNameCtrl"
            [ngModel]="personalInformation()?.firstName" (blur)="validatePersonalInfoField('firstName', $event)"
            (ngModelChange)="
              updatePersonalInformation({ firstName: $event }, 'firstName')
            " />
          <label for="firstNameCtrl">First Name</label>
          <div *ngIf="!personalInformationValidations.get('firstName')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('firstName')?.()?.error }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-floating">
          <input placeholder type="text" class="form-control" name="lastNameCtrl"
            [class.is-invalid]="!personalInformationValidations.get('lastName')?.()?.valid"
            [ngModel]="personalInformation()?.lastName" (blur)="validatePersonalInfoField('lastName', $event)"
            (ngModelChange)="
              updatePersonalInformation({ lastName: $event }, 'lastName')
            " />
          <label for="lastNameCtrl">Last Name</label>
          <div *ngIf="!personalInformationValidations.get('lastName')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('lastName')?.()?.error }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-floating">
          <input placeholder type="text" class="form-control" maxlength="10" name="phoneCtrl" appOnlyNumber
            [class.is-invalid]="!personalInformationValidations.get('phone')?.()?.valid"
            [ngModel]="personalInformation()?.phone" (blur)="validatePersonalInfoField('phone', $event)"
            (ngModelChange)="
              updatePersonalInformation({ phone: $event }, 'phone')
            " />
          <label for="phoneCtrl">Phone</label>
          <div *ngIf="!personalInformationValidations.get('phone')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('phone')?.()?.error }}
          </div>
        </div>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-12 col-md-6">
        <div class="form-floating">
          <input placeholder type="email" class="form-control" name="emailCtrl"
            [class.is-invalid]="!personalInformationValidations.get('email')?.()?.valid"
            [ngModel]="personalInformation()?.email" (blur)="validatePersonalInfoField('email', $event)"
            (ngModelChange)="
              updatePersonalInformation({ email: $event }, 'email')
            " />
          <label for="emailCtrl">Email</label>
          <div *ngIf="!personalInformationValidations.get('email')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('email')?.()?.error }}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-floating has-validation">
          <mat-form-field subscriptSizing="dynamic" class="w-100">
            <mat-label>Password</mat-label>
            <input placeholder type="password" class="form-control form-control-password" matInput maxlength="30"
              name="passwordCtrl" [class.is-invalid]="!isPasswordValid1()" [ngModel]="personalInformation()?.password"
              (ngModelChange)="
                updatePersonalInformation({ password: $event }, '')
              " type="{{ hidePassword ? 'password' : 'text' }}" />
            <button mat-button matSuffix type="button" (click)="hidePassword = !hidePassword" class="eye-icon-button"
              [attr.aria-label]="'Toggle ' + (hidePassword ? 'visibility' : 'hide')">
              @if (hidePassword) {
              <span class="material-symbols-outlined text-muted">
                visibility
              </span>
              } @else {
              <span class="material-symbols-outlined text-muted">
                visibility_off
              </span>
              }
            </button>
          </mat-form-field>

          <div class="d-flex row row-cols-2" [ngClass]="
              isPasswordValid1() ? 'valid-feedback' : 'invalid-feedback'
            ">
            @for (condition of passwordConditions; track condition) {
            <div class="col" [ngClass]="condition.valid() ? 'text-success' : 'text-danger'">
              <i class="fas" [ngClass]="condition.valid() ? 'fa-check' : 'fa-xmark'"></i>
              {{ condition.label }}
            </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-12 col-md-6">
        <mat-form-field class="w-100"
          [class.is-invalid]="!personalInformationValidations.get('referralSource')?.()?.valid">
          <mat-label>How did you hear about us?</mat-label>
          <mat-select [(ngModel)]="referralSource" [ngModelOptions]="{standalone: true}" [required]="true"
            (ngModelChange)="updatePersonalInformation({ referralSource: $event }, 'referralSource')">
            @for (source of referralSources(); track $index) {
            <mat-option [value]="source.id">{{ source.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <div *ngIf="!personalInformationValidations.get('referralSource')?.()?.valid" class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ personalInformationValidations.get('referralSource')?.()?.error }}
        </div>
      </div>
      @if (selectedReferralSourceInfo()?.hasAdditionalInfo) {
      <div class="col-12 col-md-6 animated fadeIn">
        <div class="form-floating">
          <input placeholder [required]="true" type="text" class="form-control" name="advertisementCtrl"
            [class.is-invalid]="!personalInformationValidations.get('additionalInfo')?.()?.valid"
            [ngModel]="personalInformation()?.additionalInfo"
            (blur)="validatePersonalInfoField('additionalInfo', $event)"
            (ngModelChange)="updatePersonalInformation({additionalInfo: $event }, 'additionalInfo')" />
          <label for="advertisementCtrl">Tell us more - where did you find us?</label>
          <div *ngIf="!personalInformationValidations.get('additionalInfo')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('additionalInfo')?.()?.error }}
          </div>
        </div>
      </div>
      }
    </div>

    <div class="row form-row">
      <p class="signup__sub-head-text">How many people you usually shop for?</p>
      <div class="row mt-2">
        <div class="col-md-2" *ngFor="let option of peopleOptions">
          <div class="form-floating">
            <div class="d-flex flex-column">
              <img [src]="option.img" alt="Option Image" class="option-image d-none d-lg-block">
              <div class="form-check form-check__highlight d-flex flex-row align-items-center mt-2 gap-2">
                <div class="check-1">
                  <input class="form-check-input" type="radio" name="peopleOptions" [id]="'option' + option.name"
                    [value]="option.id" [ngModel]="personalInformation()?.shopfor"
                    (ngModelChange)="updatePersonalInformation({shopfor: $event }, 'shopfor')" />
                  <label class="form-check-label" [for]="'option' + option.name">
                    <small>{{ option.name }}</small>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-5 pt-3">
            <p class="signup__sub-head-text">Do you have dietary restrictions?</p>
            <div class="d-flex flex-wrap gap-3 animated fadeIn">
              @for (dietaryRestriction of this.dietaryRestrictions; track dietaryRestriction) {
              <mat-checkbox [(ngModel)]="dietaryRestriction.completed" [ngModelOptions]="{standalone: true}">
                {{ dietaryRestriction.name }}
              </mat-checkbox>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox [checked]="checkboxValues[0]" (change)="updateCheckboxValue($event.checked, 0)">Receive SMS
            messages</mat-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox [checked]="checkboxValues[1]" (change)="updateCheckboxValue($event.checked, 1)">Receive SMS
            Delivery Notifications</mat-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox [checked]="checkboxValues[2]" (change)="updateCheckboxValue($event.checked, 2)">Receive
            information on our latest product offerings, events and local impact</mat-checkbox>
        </div>
      </div>
    </div>

    <div class="form-row-action">
      <!-- <div class="d-grid gap-2 col-2 mx-auto"> -->
      <button type="button" class="btn btn-secondary"
        [disabled]="!isValidFormPersonalInformation() || !firstStep.completed || personalInformation()?.shopfor == null"
        (click)="continue()">
        Continue
      </button>
      <!-- </div> -->
    </div>

  </form>

</div>