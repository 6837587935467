import { Component, computed, inject } from '@angular/core';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-terms-and-conditions',
    imports: [],
    templateUrl: './terms-and-conditions.component.html',
    styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {
  private signalsStoreService = inject(SignalsStoreService);
  #location = inject(Location);
  loading: boolean = false;

  termAndCondition = '';

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  constructor() {
    fetch('assets/terms_src.html')
      .then(response => response.text())
      .then(htmlContent => this.termAndCondition = htmlContent)
      .catch(error => console.error('Error loading HTML content:', error));
  }

  back() {
    this.#location.back();
  }
}
