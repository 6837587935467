import { Component } from '@angular/core';

@Component({
    selector: 'app-recipe-detail',
    imports: [],
    templateUrl: './recipe-detail.component.html',
    styleUrl: './recipe-detail.component.scss'
})
export class RecipeDetailComponent {

}
