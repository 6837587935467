<div class="d-flex gap-2 px-3">
    @for (tag of filteringTags(); track $index) {
    <span class="badge rounded-pill text-bg-primary text-white pill-container d-flex gap-2 align-items-center animated fadeIn">
        <img class="pill-container__icon" [src]="tag.icon" [alt]="tag.name" (error)="handleImageError($event)">
        <span class="pill-container__name fw-semibold">{{tag.name}}</span>
        <div data-bs-theme="dark">
            <button type="button" class="btn-close" (click)="removeFromFilter(tag)" aria-label="Close"></button>
        </div>
    </span>
    }
</div>