import { WritableSignal } from "@angular/core";

export enum FilterTypes {
    products = 'products',
    subcategories = 'subcategories',
    producers = 'producers',
    tags = 'tags'
}

export interface FilterTypeItem {
    type: FilterType,
    active: WritableSignal<boolean>
}

export type FilterType = FilterTypes.producers | FilterTypes.products | FilterTypes.subcategories | FilterTypes.tags;