import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { InstagramPost } from './types/common.types';
import { catchError, filter, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  private httpClient = inject(HttpClient);

  constructor() { }

  getAllMedia(limit: number) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient
      .get<{ data: InstagramPost[], message: string }>(environment.apis.v3 + '/instagram-posts?limit=' + limit, { headers })
      .pipe(
        catchError((error) => {
          console.log('There was an error trying to get the instagram post: ', error);
          return of(null);
        }),
        filter(res => !!res)
      );
  }
}
