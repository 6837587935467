import { CommonModule } from '@angular/common';
import { Component, OnInit, WritableSignal, signal, input, inject, InputSignal } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SignalsStoreService } from '../../signals-store.service';
import { CartService } from '../../cart.service';
import { FormsModule } from '@angular/forms';
import { OrderService } from '../../order.service';
import { NumberRestrictionDirective } from '../../directives/number-restriction.directive';
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-product-cart-preview',
    templateUrl: './product-cart-preview.component.html',
    styleUrl: './product-cart-preview.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        NumberRestrictionDirective,
        NgbTooltip
    ]
})

export class ProductCartPreview implements OnInit {
  signalsStoreService = inject(SignalsStoreService)
  cartService = inject(CartService)
  orderService = inject(OrderService)
  #router = inject(Router);

  isInputValid!: boolean;
  previousValue!: number;
  selectedQuantity: WritableSignal<any> = signal(1);
  newQuantity: WritableSignal<any> = signal(1);

  product: any = input(null, {
    alias: 'product',
    transform: (product: any) => {
      this.previousValue = product.quantity;
      this.newQuantity.set(product.quantity)
      return product;
    }
  })

  productKey: any = input(null, {
    alias: 'productKey'
  })

  allowModification: InputSignal<boolean> = input(true);

  isJustAddedProductNotification: InputSignal<boolean> = input(false, {
    alias: 'isJustAddedProductNotification'
  });

  isInOrder = input(false);

  ngOnInit(): void {
    this.isInputValid = true;
  }

  private validateInput(inputValue: number): void {
    const regex = /^\d+$/;
    this.isInputValid = regex.test(inputValue.toString());
  }

  onKeyUp(event: any): void {
    const { value } = event?.target
    this.validateInput(+value);
    if (!this.isInputValid)
      return

    this.newQuantity.set(+value)
  }

  updateQuantity() {
    if (!this.newQuantity()) return;
    this.orderService.updateFirebaseProduct(this.productKey(), { ...this.product(), quantity: this.newQuantity() }, this.signalsStoreService.firebaseOrder());
  }

  removeCartProduct() {
    this.orderService.removeFirebaseProduct(this.productKey(), this.product(), this.signalsStoreService.firebaseOrder());
  }

  handleMinus() {
    if (this.newQuantity() <= 1) return;
    this.newQuantity.update((current) => current - 1);
    this.updateQuantity()
  }

  handlePlus() {
    this.newQuantity.update((current) => current + 1);
    this.updateQuantity();
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  goToCustomizeBox() {
    this.signalsStoreService.showCartPreview.set(false);
    this.#router.navigate([`/shop/custom-box/${this.productKey()}/${this.product().id}`]);
  }
}
