import { Component, AfterViewInit, ViewChild, inject, signal, WritableSignal, computed, Inject, PLATFORM_ID, ChangeDetectorRef, Input, Pipe, viewChild, Signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddressService, NavegoAddressResponse } from '../../shared/address.service';
import { PersonalInformation } from './signup.types';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AccountService, PaymentMethod } from '../../shared/account.service';
import { Router, RouterLink } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from '../../shared/constants/date-formtats';
import { LocalStorageService } from '../../shared/local-storage.service';
import { SignalsStoreService } from '../../shared/signals-store.service';
import { Session } from '../../shared/types/session.type';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from '../../shared/constants/databases';
import { ArrayOptionsItem, NewAccountPayload, AccountSettings, DeliveryOptionsResponseItem, PeopleShoppingFor, Reference } from '../../shared/types/account.types';
import { PaymentMethodService } from '../../settings/billing/payment-method/payment-method.service';
import { MatRadioModule } from '@angular/material/radio';
import { PersonalInformationSignupComponent } from './personal-information-signup/personal-information-signup.component';
import { CheckAddressSignupComponent } from './check-address-signup/check-address-signup.component';
import { PaymentMethodSignupComponent } from './payment-method-signup/payment-method-signup.component';
import { ProfileService } from '../../settings/account/profile/profile.service';
import { finalize, first, switchMap, tap } from 'rxjs';
import { ProductsService } from '../../product/products.service';
import { FarmboxChoosingSignupComponent } from './farmbox-choosing-signup/farmbox-choosing-signup.component';
import { HeaderService } from '../../shared/header/header.service';
import { PreferencesSignupComponent } from './preferences-signup/preferences-signup.component';
import { environment } from '../../../environments/environment';
import { SignUpSteps } from '../../shared/types/common.enums';
import { FirebaseCrudService } from '../../shared/firebase-crud.service';
import { ModalContentService } from '../../shared/modal-content/modal-content.service';
import { ApiResponse } from '../../shared/common/types';
import { HTTP_STATUS_CODES } from '../../shared/constants/errors';
import { ModalContentTypes } from '../../shared/constants/modal-content-types';

@Component({
  selector: 'app-signup',
  providers: [provideNativeDateAdapter(),
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS.MONTH_AND_YEAR },
    PaymentMethodService,
    ProfileService
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    RouterLink,
    MatRadioModule,
    CheckAddressSignupComponent,
    PersonalInformationSignupComponent,
    FarmboxChoosingSignupComponent,
    PaymentMethodSignupComponent,
    PreferencesSignupComponent
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})

export class SignupComponent implements AfterViewInit {
  public localStorageService = inject(LocalStorageService);
  private signalsStoreService = inject(SignalsStoreService);
  private addressService = inject(AddressService);
  private profileService = inject(ProfileService);
  private productsService = inject(ProductsService);
  private headerService = inject(HeaderService);
  private firebaseCrudService = inject(FirebaseCrudService);
  #modalContentService = inject(ModalContentService);
  #router = inject(Router);

  accountService = inject(AccountService);
  cdr = inject(ChangeDetectorRef)

  deliveryOption = [
    'Home Delivery',
    'Pick Up',
    'EBT/Snap'
  ];

  // @ViewChild('stepper', { static: false }) matStepper!: MatStepper;
  matStepper: Signal<MatStepper> = viewChild.required('stepper');
  personalInformationComponent: Signal<PersonalInformationSignupComponent> = viewChild.required('personalInformationComponent');
  @ViewChild('firstStep', { static: false }) firstStep!: MatStep;
  @Input() formValidityPersonalInformation: boolean | undefined;
  @Input() formValidityPreferences: boolean | undefined;
  @Input() formValidityPaymentMethod: boolean | undefined;
  @Input() deliveryAddressInformation: any;
  @Input() deliverySelectedDay: any;
  @Input() deliveryOptionSelected: any;
  @Input() personalInformationData: any;
  @Input() shopforValue: any;
  @Input() dietaryRestrictionsValues: any;
  @Input() farmboxCardSubscription: any
  isLinear = true;
  paymentMethodStepControl: FormControl = new FormControl(true);

  personalInformation: WritableSignal<PersonalInformation | null | any> = signal(null)

  referralSource: Reference[] = [];
  deliveryOptions: DeliveryOptionsResponseItem[] = [];
  skipPaymentMethodStep: boolean = false;

  paymentMethod: WritableSignal<PaymentMethod> = signal({
    cardCode: '',
    firstName: '',
    lastName: '',
    cardNumber: '',
    email: '',
    expirationDate: '',
  })

  states: WritableSignal<ArrayOptionsItem[]> = signal([]);

  date = new FormControl({ value: new Date(''), disabled: true });

  isCheckedDietaryRestrictions: boolean = false;
  isCheckedAddAnotherContact: WritableSignal<boolean> = signal(false);
  isCheckedBillingAddress: WritableSignal<boolean> = signal(false);
  isCheckedCouponCode: boolean = false;
  isCheckedTermsAndConditions: boolean = false;

  isGoogleAddressFilled = signal(false);
  isLoadingContent: any = this.addressService.isLoading
  isWaitingResponse: any = signal(false)
  hasDeliveryAddressCoverage: any = signal(false);

  selectedDeliveryDay: WritableSignal<DeliveryOptionsResponseItem | null> = signal(null);
  selectedDeliveryTimeWindow: any = signal(null);
  selectedMonth: any = null;
  selectedYear: any = null;
  isPasswordValid1 = computed(() => !!(this.passwordConditions.every(condition => condition.valid())));
  isValidFormDeliveryAddress = computed(() => this.hasDeliveryAddressCoverage() && !!this.selectedDeliveryDay() && !!this.selectedDeliveryTimeWindow());

  personalInformationValidations = new Map<string, WritableSignal<{ valid: boolean, error: string, validations: string[] }>>();
  shouldDisableCreateClientButton = signal(false);

  paymentMethodValidations = new Map<string, WritableSignal<{ valid: boolean, error: string, validations: string[] }>>();


  isMinLengthValid = computed(() => (this.personalInformation()?.password ?? '').length >= 8)
  hasUpperCase = computed(() => /[A-Z]/.test(this.personalInformation()?.password || ''))
  hasLowerCase = computed(() => /[a-z]/.test(this.personalInformation()?.password || ''))
  hasSpecialChar = computed(() => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.personalInformation()?.password || ''))
  hasNumber = computed(() => /\d/.test(this.personalInformation()?.password || ''))
  passwordConditions = [
    { label: 'At least 8 Characters', iconClass: (this.isMinLengthValid() ? 'fa-check' : 'fa-check'), valid: () => this.isMinLengthValid() },
    { label: 'At least 1 Number (0-9) or 1 Symbol', iconClass: ((this.hasNumber() || this.hasSpecialChar()) ? 'fa-check' : 'fa-check'), valid: () => (this.hasNumber() || this.hasSpecialChar()) },
    { label: 'At least 1 Lowercase (a-z)', iconClass: (this.hasLowerCase() ? 'fa-check' : 'fa-check'), valid: () => this.hasLowerCase() },
    { label: 'At least 1 Uppercase (A-Z)', iconClass: (this.hasUpperCase() ? 'fa-check' : 'fa-check'), valid: () => this.hasUpperCase() }
  ];

  shouldSetUpSettings: boolean = true;

  subscriptionCard: WritableSignal<any[]> = signal([])

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  bundleProducts = computed(() => this.productsService.productsSignal());

  isCompleteStep3 = signal(false);
  isCompleteStep4 = signal(false);

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) return;

    const currentStep = this.localStorageService.get<number>(LOCALSTORAGE_KEYS.SIGNUP_CURRENT_STEP);

    const stepToLoad = this.signalsStoreService.hasSession()
      ? currentStep ?? SignUpSteps.selectYourFarmbox
      : SignUpSteps.deliveryInformation;

    // Load first step or farmbox subscription step if hasSession
    if (stepToLoad > 0) {
      for (let index = 0; index < stepToLoad; index++) {
        this.matStepper().steps.toArray()[index].completed = true;
      }
    }

    if (stepToLoad === SignUpSteps.selectYourFarmbox) {
      this.headerService.getFrequencies();
      this.getBundleProducts();
    } else if (stepToLoad === SignUpSteps.preferences)
      this.getSignupProducts();

    this.matStepper().selectedIndex = stepToLoad;
    this.cdr.detectChanges()
  }


  createNewClient() {
    if (this.shouldDisableCreateClientButton()) return;
    this.shouldDisableCreateClientButton.set(true);
    const payload = this.setUpNewClientPayload();
    if (!payload) return;

    this.accountService
      .create(payload)
      .pipe(
        finalize(() => this.shouldDisableCreateClientButton.set(false))
      )
      .subscribe({
        next: (res: ApiResponse<Session>) => {
          const { data, status } = res;
          if (status === HTTP_STATUS_CODES.CONFLICT)
            return this.#showModalEmailAlreadyInUse(payload.accountInfo.email);
          if (data?.isTimeSlotCapacityFull) {
            this.selectedDeliveryDay.set(null);
            this.selectedDeliveryTimeWindow.set(null);
            this.getDeliveryOptions();
            this.firstStep.editable = true;
            this.matStepper().previous();
          } else if (data) {
            this.headerService.getFrequencies();
            this.getBundleProducts();
            this.matStepper().next();

            const { selectedIndex } = this.matStepper();
            this.localStorageService.set(LOCALSTORAGE_KEYS.SIGNUP_CURRENT_STEP, selectedIndex);
            if (data?.accountInfo?.id)
              this.#saveStepInFirebase(SignUpSteps.selectYourFarmbox, data.accountInfo.id);
          }
        }
      });
  }

  #showModalEmailAlreadyInUse(email: string) {
    this.#modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
      title: 'Email already in use',
      textContent: `Hey! Looks like you already have an account with us. Would you like to login with <b><i>${email}</i></b>?`,
      cancelButtonText: 'Try another email',
      confirmButtonText: 'Click here to login',
    }).closed
      .subscribe((res: { confirm: boolean } | null) => {
        if (!res?.confirm) return this.personalInformationComponent().clearEmailField();
        this.localStorageService.set(LOCALSTORAGE_KEYS.PRELOADED_EMAIL, email);
        this.#router.navigate(['/login']);
      })
  }

  async #saveStepInFirebase(stepIndex: number, accountId?: number) {

    if (!accountId) {

      const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
      if (!sessionStored)
        return;

      accountId = sessionStored.accountInfo.id;
    }

    const id: number = accountId;

    const exists = await this.firebaseCrudService.existsDoc(FIREBASE_COLLECTIONS.SIGNUP_STEP, id.toString());
    const data = {
      stepIndex
    };

    if (exists)
      this.firebaseCrudService.update(FIREBASE_COLLECTIONS.SIGNUP_STEP, id, data);
    else
      this.firebaseCrudService.add(FIREBASE_COLLECTIONS.SIGNUP_STEP, id, data);

  }

  private getDeliveryOptions() {
    this.accountService.getDeliveryOptions(this.addressService.navegoAddress()?.zoneId).subscribe({ next: (options: DeliveryOptionsResponseItem[]) => this.deliveryOptions = options });
  }

  private setUpNewClientPayload(): NewAccountPayload | null {
    try {

      const {
        firstName,
        lastName,
        phone,
        email,
        password,
        dietaryRestrictions = this.dietaryRestrictionsValues,
        shopfor = this.shopforValue,
        contactPerson: contact,
        referralSource,
        additionalInfo,
        receiveNewsletter,
        receiveText,
        receiveTextDelivery
      } = this.personalInformationData() || {};
      const { additionalAddress: additional, zipCode: zip, deliveryNotes: noteDriver } = this.deliveryAddressInformation()
      const { street, zoneId, city, state: stateCode, latitude, longitude } = this.addressService.navegoAddress() as NavegoAddressResponse || {}
      return {
        accountInfo: {
          dietaryPreferences: dietaryRestrictions?.filter((value: any) => value.completed).map((value: any) => { return { id: value.id, name: value.name } }) || null,
          email,
          firstName,
          lastName,
          noteDriver,
          password,
          phone,
        },
        address: {
          additional: additional || null,
          city: city || null,
          latitude: latitude || null,
          longitude: longitude || null,
          stateCode: stateCode || null,
          street: street || null,
          zip: zip || null,
          zoneId: zoneId || this.deliverySelectedDay[0].zoneId
        },
        settings: {
          receiveNewsletter,
          receiveText,
          receiveTextDelivery,
          delivery: {
            type: this.deliveryOptionSelected?.id,
            dayId: this.deliverySelectedDay.id || this.deliverySelectedDay[0]?.deliveryDay || null,
            timeWindowId: this.selectedDeliveryTimeWindow() === -1 ? null : this.selectedDeliveryTimeWindow()
          },
          peopleShoppingForId: shopfor,
          referral: {
            additionalInfo,
            source: referralSource
          },
        },
        contact
      } as NewAccountPayload | any
    } catch (error) {
      console.log('error >>>', error);
      return null;
    }
  }

  updateCreatedClient() {
    const payload = this.setUpUpdateClientPayload();
    this.profileService.putPreferences(payload).pipe(
      tap(() => {
        this.matStepper().next();
      })
    ).subscribe()
  }

  private setUpUpdateClientPayload(): any | null {
    try {
      const { dietaryRestrictions = this.dietaryRestrictionsValues, shopfor = this.shopforValue } = this.personalInformationData() || {};
      return {
        accountInfo: {
          dietaryPreferences: dietaryRestrictions?.filter((value: any) => value.completed).map((value: any) => { return { id: value.id, name: value.name } }) || null,
        },
        settings: {
          peopleShoppingForId: shopfor
        }
      } as any
    } catch (error) {
      console.log('error >>>', error);
      return null;
    }
  }

  continue(customBoxStep: boolean = false) {

    if (customBoxStep)
      this.getSignupProducts();

    this.firstStep.completed = true;
    this.matStepper().next();

    const { selectedIndex } = this.matStepper();
    this.localStorageService.set(LOCALSTORAGE_KEYS.SIGNUP_CURRENT_STEP, selectedIndex);

    if (selectedIndex === SignUpSteps.personalInformation) {
      this.firstStep.editable = false;
      if (this.shouldSetUpSettings) {
        this.getAccountSettings();
        this.shouldSetUpSettings = false;
      }
    } else if (selectedIndex === SignUpSteps.preferences) {
      this.getSignupProducts();
      this.#saveStepInFirebase(SignUpSteps.paymentInformation);
    }
  }

  private getSignupProducts() {
    this.productsService
      .getSignupProducts()
      .pipe(
        tap((response) => {
          this.subscriptionCard.set(response.data);
        })
      ).subscribe()
  }

  private getBundleProducts() {
    this.productsService
      .getProducts(environment.config.bundleCategoryId, true);
  }

  private getAccountSettings() {
    this.accountService.getAccountSettings().subscribe({
      next: (accountSettings: AccountSettings | null) => {
        if (accountSettings) {
          this.setUpAccountSettings(accountSettings);
        }
      }
    });

  }

  private setUpAccountSettings(accountSettings: AccountSettings) {
    this.personalInformation.set({
      contactPerson: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
      },
      dietaryRestrictions: accountSettings.dietaryPreferences?.map((preference: ArrayOptionsItem) => {
        return { ...preference, completed: false };
      }) || [],
      peopleShoppingFor: accountSettings.peopleShoppingFor || [],
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phone: '',
      referralSource: '',
      advertisement: '',
      shopfor: null,
    });

    this.referralSource = accountSettings.references;
  }

  isCompleteStep1(): boolean {
    return (this.hasDeliveryAddressCoverage() && !!this.selectedDeliveryDay()) &&
      ((this.selectedDeliveryDay()?.allowTimeWindow && !!this.selectedDeliveryTimeWindow()) || !this.selectedDeliveryDay()?.allowTimeWindow);
  }
}
