import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { ModalContentService } from '../modal-content/modal-content.service';
import { ModalContentTypes } from '../constants/modal-content-types';
import { DateTime } from 'luxon';
import { LocalStorageService } from '../local-storage.service';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { Session } from '../types/session.type';
import { FirebaseCrudService } from '../firebase-crud.service';
import { environment } from '../../../environments/environment';
import { LoadingService } from '../loading.service';

export function httpInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown> | any> {

  const modalService = inject(ModalContentService);
  const localStorageService = inject(LocalStorageService);
  const firebaseCrudService = inject(FirebaseCrudService);
  const loadingService = inject(LoadingService);

  if (req.method !== 'GET' || req.url.includes('/order'))
    loadingService.validateEndPoint(req.url);

  return next(req).pipe(
    finalize(() => loadingService.isShowGlobalLoading() && loadingService.close()),
    catchError((error) => validateError(req, error, modalService, localStorageService, firebaseCrudService)),
    tap(event => {
      if (event && event.type === HttpEventType.Response) {
        if (!environment.production)
          console.log(req.url, 'returned a response with status', event.status);
      }
    }));
}

function validateError(
  req: HttpRequest<unknown>,
  error: HttpErrorResponse,
  modalService: any,
  localStorageService: LocalStorageService,
  firebaseCrudService: FirebaseCrudService
): Observable<HttpEvent<any>> {

  const preventErrorLog = preventErrorForLog(error.status, error.url);
  if (!preventErrorLog) {
    sendErrorToFirebase(req, error, localStorageService, firebaseCrudService);
  }

  switch (error.status) {
    case 444:
      modalService.openModal(ModalContentTypes.ACCOUNT_SUSPENDED, { closeable: false, closeOthers: true });
      break;
    default:
      break;
  }

  return throwError(() => error);
}

function preventErrorForLog(status: number, url: string | null): boolean {
  if (!url) return false;
  if (status === 401 || status === 403) return true;
  if (status === 404) {
    if (url.indexOf('/login') > -1 ||
      url.indexOf('/order-history') > -1 ||
      url.indexOf('/subscriptions') > -1) {
      return true;
    }
  }
  return false
}

function sendErrorToFirebase(
  httpRequest: HttpRequest<any>,
  error: HttpErrorResponse,
  localStorageService: LocalStorageService,
  firebaseCrudService: FirebaseCrudService
) {
  try {
    const currentUTCDateTime = DateTime.utc();
    const currentDateArray = currentUTCDateTime.toString().split('T');
    const currentStringDate = currentDateArray[0];
    const currentStringTime = currentDateArray[1].split('.')[0];



    let errorLog: any = {
      payload: httpRequest.body ? JSON.stringify(httpRequest.body) : '',
      method: httpRequest.method,
      statusCode: error.status,
      statusText: error.statusText,
      url: error.url,
      timestamp: currentUTCDateTime.toMillis(),
      createdAt: `${currentStringDate} ${currentStringTime}`,
      createdAtDate: currentStringDate,
      createdAtTime: currentStringTime,
      message1: error?.error?.message || 'Unknow error',
      message2: error?.message || 'Unknow error',
      errorName: error?.name || null,
      errorType: error?.type || null,
    }

    // Session data:
    const storedSession: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (storedSession) {
      errorLog.clientId = storedSession.accountInfo.id;
      errorLog.clientToken = storedSession.accountInfo.token;
      errorLog.clientEmail = storedSession.accountInfo.email;
      errorLog.clientFirstName = storedSession.accountInfo.firstName;
      errorLog.clientLastName = storedSession.accountInfo.lastName;
    }

    firebaseCrudService.addWithoutDocId('error_log', errorLog);
  } catch (error) {
    console.log('Error trying to send data to Firebase: ', error);
  }
}
