@if (isContentLoaded()) {
<div class="dropdown animated fadeIn">
    <button class="search-toggler show-on-shop" data-bs-toggle="dropdown" aria-expanded="false"
        data-bs-display="static">
        <span class="material-symbols-outlined filter-icon-color">filter_alt</span>
    </button>
    <div class="dropdown-menu tags-filter dropdown-menu-end mt-2">
        @for (tag of tags(); track $index; let last = $last;) {
        <li>
            <mat-checkbox class="dropdown-item tags-filter__tag" [(ngModel)]="tag.isChecked"
                (ngModelChange)="filterByTags()">
                <section class="d-flex gap-3">
                    <img class="tags-filter__tag__icon" [src]="tag.icon" [alt]="tag.name"
                        (error)="handleImageError($event)">
                    <span class="tags-filter__tag__name">{{tag.name}}</span>
                </section>
            </mat-checkbox>
        </li>
        @if (!last) {
        <li>
            <hr class="dropdown-divider">
        </li>
        }
        }
    </div>
</div>
}