import { Component, computed, inject, input, output, signal } from '@angular/core';
import { Product } from '../../../product/product.types';
import { ResolutionService } from '../../../shared/resolution.service';
import { FavOrDislike } from '../../../shared/product-card/product-card.types';
import { ProductCardV2Component } from '../../../shared/product-card-v2/product-card-v2.component';

@Component({
  selector: 'app-preferences-signup',
  imports: [
    ProductCardV2Component
  ],
  templateUrl: './preferences-signup.component.html',
  styleUrl: './preferences-signup.component.scss'
})
export class PreferencesSignupComponent {

  //#region Services

  #resolutionService = inject(ResolutionService);

  //#endregion

  //#region Inputs / Outputs

  subscriptionCard = input.required<Product[]>();

  onNextStep = output();

  canContinue = signal(false);

  outCanContinue = output<boolean>();

  //#endregion

  //#region Properties

  isMobile = computed(() => this.#resolutionService.isMobile());

  //#endregion

  //#region Methods

  continue() {
    this.outCanContinue.emit(true);
    setTimeout(() => this.onNextStep.emit(), 300);
  }

  clickFavOrDisliked(event: FavOrDislike) {

    this.canContinue.set(true);

    if (!event.isDisliked) return;

    const index = this.subscriptionCard().findIndex(x => x.id === event.id);

    if (index === -1)
      return;

    const product = this.subscriptionCard().splice(index, 1);
    this.subscriptionCard().push(product[0]);
  }

  //#endregion
}
