import { CommonModule } from '@angular/common';
import { Component, OnInit, Signal, computed, inject } from '@angular/core';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../../../shared/constants/modal-content-types';
import { ModalContentData } from '../../../shared/modal-content/modal-content';
import { MatIconModule } from '@angular/material/icon';
import { MembershipService } from './membership.service';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { Membership, MembershipData } from './membership.types';
import { Session } from '../../../shared/types/session.type';
import { LOCALSTORAGE_KEYS } from '../../../shared/constants/databases';

@Component({
    selector: 'app-membership',
    imports: [CommonModule, MatIconModule],
    providers: [MembershipService],
    templateUrl: './membership.component.html',
    styleUrl: './membership.component.scss'
})
export class MembershipComponent implements OnInit {
  modalService = inject(ModalContentService);
  membershipService = inject(MembershipService);
  private localStorageService = inject(LocalStorageService);

  membership: Signal<Membership | null> = computed(() => this.membershipService.membership());
  membershipCardsData = computed(() => this.membershipService.membershipList());

  ngOnInit(): void {
    this.getDetails();
  }

  openModalMembershipDeposit(membership: MembershipData) {
    this.modalService.openModal(ModalContentTypes.MEMBERSHIP_CSA_DEPOSITS, {
      title: 'Want to add a one time CSA deposit?'
    }).closed.subscribe(
      (result: { deposit: number } | null) => {
        if (result && result.deposit >= 0) {
          this.openModalBecomeAMember(result.deposit, membership);
        }
      }
    )
  }

  openModalBecomeAMember(deposit: number, membership: MembershipData) {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!session) return;
    this.modalService.openModal(ModalContentTypes.BECOME_A_MEMBER, {
      title: 'Become a member',
      becomeAMemeber: {
        membershipCost: membership.cost,
        creditCardLastNumbers: session?.creditCard?.number.toString(),
        deposit,
        membershipFrequency: membership.renewalType,
        membershipName: membership.name
      }
    } as ModalContentData).closed.subscribe(
      (result: { deposit: number } | null) => {
        if (result && result.deposit >= 0) {
          this.subscribe(result.deposit);
        }
      }
    )
  }

  openModalCancelMembership() {
    const currentMembership = this.membershipCardsData().find((membership: MembershipData) => membership.id === this.membership()?.id)
    this.modalService.openModal(ModalContentTypes.CONFIRMATION, {
      title: 'Are you sure?',
      textContent: `You are about to cancel your membership and lose all ${currentMembership?.name || 'membership'} benefits.`,
      cancelButtonText: 'Keep membership',
      confirmButtonText: 'Cancel membership',
    }).closed.subscribe(
      (result: { confirm: boolean } | null) => {
        if (result?.confirm) {
          this.cancelMembership();
        }
      }
    )
  }

  subscribe(deposit: number, renew: boolean = false) {
    this.membershipService.subscribe(deposit, renew);
  }

  private getDetails() {
    this.membershipService.get();
  }

  private cancelMembership() {
    this.membershipService.unsubscribe();
  }

}
