<div class="container px-5">
    <div class="row gap-4">
        <section class="col-12" [innerHTML]="message()"></section>
        <section class="col-12">
            <div class="form-floating">
                <input class="form-control" type="password" [(ngModel)]="code" placeholder="Enter code">
                <label>Enter code</label>
            </div>
        </section>
        <section class="d-grid col-12 mx-auto">
            <button class="btn btn-primary w-100" (click)="checkCode()">
                VERIFY CODE
            </button>
            <a href="javascript:void(0)" [routerLink]="['/login']"
                class="link-primary link-underline-opacity-0 w-100 d-flex align-items-center justify-content-end gap-2">
                <span class="material-symbols-outlined">
                    west
                </span>
                Back to login
            </a>
        </section>
    </div>
</div>