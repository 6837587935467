import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NotificationService } from '../../../shared/notification/notification.service';
import { AccountRecoveryService } from '../account-recovery.service';
import { tap } from 'rxjs';

@Component({
    selector: 'app-new-password',
    imports: [FormsModule, CommonModule, RouterLink],
    templateUrl: './new-password.component.html',
    styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent implements OnInit, AfterViewInit {
  private notificationService = inject(NotificationService);
  private accountRecoveryService = inject(AccountRecoveryService);
  private router = inject(Router);

  inputConfig: any[] = [];

  ngOnInit(): void {
    this.setUpInputs();
  }

  ngAfterViewInit(): void {
    if (!this.accountRecoveryService.code() || !this.accountRecoveryService.email()) {
      this.router.navigate(['/account-recovery/check-email']);
    }
  }

  private setUpInputs() {
    this.inputConfig = [
      {
        label: `New password`,
        ngModel: signal(''),
        passwordValidations: this.setUpPasswordsValidators(),
        hidePassword: signal(true),
      },
      {
        label: `Confirm password`,
        ngModel: signal(''),
        passwordValidations: this.setUpPasswordsValidators(),
        hidePassword: signal(true),
      },
    ]
  }

  private setUpPasswordsValidators() {
    return [
      {
        label: 'At least 8 Characters',
        error: true
      },
      {
        label: 'At least 1 Number (0-9)',
        error: true
      },
      {
        label: 'At least 1 Symbol',
        error: true
      },
      {
        label: 'At least 1 Lowercase (a-z)',
        error: true
      },
      {
        label: 'At least 1 Uppercase (A-Z)',
        error: true
      },
      {
        label: 'Do not include spaces',
        error: true
      },
    ]
  }

  validatePassword(event: any, inputConfig: any) {
    if (!event?.target?.value) return
    const value = event.target.value;

    // Min length
    inputConfig.passwordValidations[0].error = value.length < 8;

    // At least one number:
    inputConfig.passwordValidations[1].error = !/\d/.test(value);

    // At least a symbol:
    inputConfig.passwordValidations[2].error = !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);

    // At least one lower case:
    inputConfig.passwordValidations[3].error = !/[a-z]/.test(value);

    // At least one upper case:
    inputConfig.passwordValidations[4].error = !/[A-Z]/.test(value);

    // Do not include spaces:
    inputConfig.passwordValidations[5].error = value.includes(' ');

    inputConfig.isInvalid = inputConfig.passwordValidations.map((e: any) => e.error).some((e: boolean) => e);
  }

  togglePasswordVisibility(input: any): void {
    input.hidePassword.update((current: boolean) => !current);
  }

  resetPassword() {
    if (this.inputConfig.map((e: any) => e.isInvalid).some((isInvalid: boolean) => isInvalid)) {
      return this.notificationService.show({ text: 'Please validate your password', type: 'error' });
    }

    if (this.inputConfig[0].ngModel() !== this.inputConfig[1].ngModel()) {
      return this.notificationService.show({ text: 'Passwords do not match', type: 'error' });
    }

    this.accountRecoveryService.resetPassword(this.inputConfig[0].ngModel()).pipe(
      tap(() => this.router.navigate(['/account-recovery/password-restored']))
    ).subscribe();
  }
}
