<div class="nav-content animated fadeIn">
    <ul class="navbar-nav text-nowrap d-flex align-items-center justify-content-center categories-container w-100">
        <li class="nav-item d-flex justify-content-center align-items-center animated fadeInLeft search-wrap">
            <mat-form-field class="w-100 searchbar h-75">
                <input #searchInput class="flex-nowrap search-input" placeholder="Type to filter..."
                    (keydown)="onInputKeydown($event)" [(ngModel)]="searchBarContent" matInput
                    (input)="inputSearchTypeHandler($event)" />
            </mat-form-field>

            @if (searchBarContent().length > 0) {
            <ul class="list-group position-absolute w-100 z-3 bg-white mt-1 suggestion">
                @for (filterType of filterTypes.entries(); track $index) {
                <li class="
                    list-group-item
                    list-group-item-action
                    cursor-pointer
                    d-flex
                    align-items-center
                    gap-2
                    animated
                    fadeIn" [class.active]="selectedIndex === $index" (click)="onFilterTypeSelected(filterType[0])">
                    Search <b class="text-primary">{{filterType[0]}}</b> for: <span
                        class="text-decoration-underline fst-italic">{{searchBarContent()}}</span>
                </li>
                }
            </ul>
            }
        </li>
        <li class="nav-item">
            <div class="btn-group" role="group" aria-label="Basic example">
                <!-- <button class="btn btn-primary btn-sm" type="button" (click)="search()" title="Search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </button> -->
                <button class="btn btn-sm nav-category--icon" type="button" (click)="toggleSearchBar()">
                    <span class="material-symbols-outlined">close</span>
                </button>
            </div>
        </li>
    </ul>
</div>