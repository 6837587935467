import { Component, inject, input } from '@angular/core';
import { BlogPost } from '../blog.types';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-post',
    imports: [
        CommonModule
    ],
    templateUrl: './post.component.html',
    styleUrl: './post.component.scss'
})
export class PostComponent {

  #router = inject(Router);

  blog = input.required<BlogPost>();

  goToDetail() {
    this.#router.navigate([`/pages/blog-post/${this.blog().url}`])
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }
}
