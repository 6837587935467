import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { CarouselComponent } from '../../shared/carousel/carousel.component';
import { map, tap } from 'rxjs';
import { ProductsService } from '../../product/products.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-how-it-works',
  imports: [
    CommonModule,
    CarouselComponent
  ],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss'
})
export class HowItWorksComponent implements OnInit {

  private meta = inject(Meta);
  private title = inject(Title);

  //#region Services

  #productsService = inject(ProductsService);

  //#endregion

  //#region Properties

  howSteps = [
    {
      step: '1',
      alternate: '',
      img: 'assets/images/landing/home/how/how-1.png',
      text: 'Choose Your Farmbox',
      ulText: [
        'Choose your base Farmbox and how often you’d like to receive it.',
        'Add items weekly: local eggs, fresh bread, small-batch pantry staples.',
        'Choose your delivery start day.',
        'Enjoy free delivery over $30.'
      ]
    },
    {
      step: '2',
      alternate: 'is-alternate',
      img: 'assets/images/landing/home/how/how-2.png',
      text: 'Shop & Customize',
      ulText: [
        'View what’s coming in your box on Fridays.',
        'Edit contents up to Sunday by 5pm.',
        'Swap items you don\'t like with more of what you love.',
        'Browse incredible add-ons to complete your weekly grocery needs.',
        'Skip a week if you’re out of town.'
      ]
    },
    {
      step: '3',
      alternate: '',
      img: 'assets/images/landing/home/how/how-3.png',
      text: 'Farmer’s Harvest',
      ulText: [
        'We let our farmers know what’s been ordered and they harvest it just for you.',
        'Everything is freshly picked, prepared or baked to order.',
        'We’re helping eliminate food waste since we only receive what we need.',
        'Nothing sits on a grocery store shelf.'
      ]
    },
    {
      step: '4',
      alternate: 'is-alternate',
      img: 'assets/images/landing/home/how/how-4.png',
      text: 'We Deliver',
      ulText: [
        'All of your goodies are assembled and loaded into our delivery vehicle.',
        'Thermal packaging is used on all cold items to ensure freshness, especially if you’re not home to receive it.',
        'Your order will arrive between 8am-9pm. And we’ll always text you when it arrives.',
        'We deliver to most areas in Dallas/Fort Worth, Waco, Austin and a growing number of zip codes in Texas.',
      ]
    },
  ]

  weeklyGroceries = [
    {
      title: 'Bakery',
      img: '../../../assets/images/landing/dummy/weekly/1.webp',
      url: ''
    },
    {
      title: 'Fruits',
      img: '../../../assets/images/landing/dummy/weekly/2.webp',
      url: ''
    },
    {
      title: 'Prepared Food',
      img: '../../../assets/images/landing/dummy/weekly/3.webp',
      url: ''
    },
  ]

  foodItem = [
    {
      title: 'Bakery',
      tag: 'Farmer Market',
      img: '../../../assets/images/landing/dummy/box/1.webp',
      price: '$35 - $40',
      url: ''
    },
  ]

  bestSellingProducts = signal<any[]>([]);

  //#endregion

  //#region Constructors

  ngOnInit() {
    this.setUpSEOTags();
    this.#getBestSellingProducts();
  }

  //#endregion

  //#region Methods

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/how-it-works'
    const component = 'How it Works'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

  #getBestSellingProducts() {

    this.bestSellingProducts.set([]);

    this.#productsService
      .getBestSellingProducts(15)
      .pipe(
        map(response => response.data.map(x => ({ content: { product: x } }))),
        tap(response => this.bestSellingProducts.set(response))
      )
      .subscribe();
  }

  //#endregion
}
