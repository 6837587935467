@if (product()) {
<div class="product-card-addon" [ngClass]="product().isPremiumAddon && removable() ? 'premium' : ''">

  <a (click)="goToProduct()" class="product-card-addon__img"
    [class.opacity-50]="(isOOS() || isOOSPerQtty()) && !removable()">
    <img [src]="product()?.img || 'assets/images/product-placeholder.webp'" alt="image"
      [class.disliked]="product().disliked" (error)="handleImageError($event)" />
  </a>

  <div class="product-card-addon__detail">

    <!-- Name and dislike -->
    <div class="d-flex justify-content-between align-items-center gap-3">

      <!-- Product Name -->
      <h4 class="title" (click)="goToProduct()" [class.opacity-50]="(isOOS() || isOOSPerQtty()) && !removable()"
        ngbTooltip="{{product()?.name}}">
        {{ product()?.name }}
      </h4>

      <!-- Premium Addons -->
      @if (product()?.isPremiumAddon) {
      <span class="material-symbols-outlined text-primary">
        verified
      </span>
      }

      <!-- Dislike action -->
      @if (canBeDisliked()) {
      @if (product().disliked) {
      <span (click)="prevDislikeProduct()" class="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
          <path
            d="M240-840h400v520L360-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 1.5-15t4.5-15l120-282q9-20 30-34t44-14Zm480 520v-520h160v520H720Z" />
        </svg>
      </span>
      } @else {
      <span (click)="prevDislikeProduct()" class="action-icon cursor-pointer" ngbTooltip="Dislike this product">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#736e6e">
          <path
            d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
        </svg>
      </span>
      }
      }
    </div>

    <div class="d-flex justify-content-between">

      <!-- Package -->
      @if (product()?.attribute?.name || product()?.packageName) {
      <span class="meta fw-medium">
        {{ product()?.attribute?.name || product()?.packageName }}
      </span>
      } @else if(product()?.display) {
      <!-- Display -->
      <span class="meta mt-2">
        {{ product()?.display }}
      </span>
      }

      <!-- Price -->
      @if (((!isLimitedUser() || isLockedBundle()) && !isFixedBundle()) || product().isPremiumAddon) {
      <h4 class="price">
        {{ product()?.price | currency }}
      </h4>
      }

    </div>

  </div>

  @if (isOOSPerQtty()) {
  <div class="product-card-addon__full-widht">
    <app-empty-message [displayIcon]="true" [tinny]="true" class="text-center" [marginTop0]="true" [width100]="true"
      [alertMartginBottom0]="true" [message]="oosMessage()"></app-empty-message>
  </div>
  }

  @if (isOOS() && !removable()) {
  <div class="product-card-addon__full-widht">
    <app-empty-message [displayIcon]="true" [marginTop0]="true" [tinny]="true" [alertMartginBottom0]="true"
      [width100]="true" class="text-center" [message]="oosMessage()"></app-empty-message>
  </div>
  } @else {

  <div class="product-card-addon__action">
    @if (!isLockedBundle() || product().isPremiumAddon) {
    <div class="action-input">
      <mat-form-field class="field-small" subscriptSizing="dynamic">
        <mat-select [value]="product()?.quantity" (selectionChange)="handleChangeQuantitySelect($event.value)">
          @for(item of availableQuantity(); track $index){
          <mat-option [value]="item.id">{{ item.text }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="action-btn">
      @if (removable()) {
      <!-- @if (isFixedBundle() && product().isReplaceable) {
        <button type="button" (click)="substitute()" class="btn btn-outline-primary btn-sm px-4 py-2">
          <span class="material-symbols-outlined icon">autorenew</span>
          <span class="text">Replace</span>
        </button>
        } @else { -->
      <button type="button" (click)="remove()" class="btn btn-outline-primary btn-sm px-4 py-2">
        <span class="material-symbols-outlined icon">delete</span>
        <span class="text">Remove</span>
      </button>
      <!-- } -->
      } @else {
      <button type="button" (click)="add()" [disabled]="isOOSPerQtty()" class="btn btn-primary btn-sm px-4 py-2">
        <span class="material-symbols-outlined icon">add</span>
        <span class="text">Add</span>
      </button>
      }
    </div>
    } @else {
    <mat-form-field class="d-flex " subscriptSizing="dynamic">
      <input type="text" readonly matInput appOnlyNumber [value]="newQuantity()">
    </mat-form-field>
    }
  </div>

  }
  <!-- Tags Icons -->
  <!-- <ul class="product-card__tags">
    @for (tag of product()?.tags || []; track $index) {
    <li>
      @if (tag.icon) {
      <img src="{{tag.icon}}" [alt]="tag.name" [ngbTooltip]="tag.name" />
      } @else {
      <span class="material-symbols-outlined" [ngbTooltip]="tag.name">
        info
      </span>
      }
    </li>
    }
  </ul> -->
</div>
}