import { CommonModule } from '@angular/common';
import { Component, WritableSignal, signal, input, output, inject, InputSignal, computed, Signal } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OnlyNumberDirective } from '../../directives/only-number.directive';
import { Router } from '@angular/router';
import { ProductCardService } from '../../product-card.service';
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ProductsService } from '../../../product/products.service';
import { tap } from 'rxjs';
import { ModalContentService } from '../../modal-content/modal-content.service';
import { ModalContentTypes } from '../../constants/modal-content-types';
import { SignalsStoreService } from '../../signals-store.service';
import { EmptyMessageComponent } from '../../empty-message/empty-message.component';
import { BundleItems } from '../../../product/product.types';

@Component({
    selector: 'app-addable-product-card',
    templateUrl: './addable-product-card.component.html',
    styleUrl: './addable-product-card.component.scss',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        OnlyNumberDirective,
        NgbTooltip,
        EmptyMessageComponent
    ],
    providers: [ProductCardService]
})

export class AddableProductCard {
  productCardService = inject(ProductCardService);
  private router = inject(Router);

  #productService = inject(ProductsService);
  #signalsStoreService = inject(SignalsStoreService);
  #modalService = inject(ModalContentService);
  stock: WritableSignal<any> = signal(null);
  isOOS: WritableSignal<boolean> = signal(false);
  isOOSPerQtty: WritableSignal<boolean> = signal(false);
  stockLimit: WritableSignal<number> = signal(1);
  oosMessage: Signal<string> = computed(() => {
    const isOOS = this.isOOS();
    const isOOSPQ = this.isOOSPerQtty();

    if (isOOS) return 'Product temporarily out of stock.';
    if (isOOSPQ) return 'Insufficient stock.'
    return ''
  })

  product: any = input(null, {
    transform: (product: any) => {
      const qtty = product?.quantity || 1;
      product.quantity = qtty;

      if (product.stock)
        this.stock.set(product.stock);

      const availableQuantityArray = [];
      let availableQuantity = product.qtyLimit || 3;
      // if (product.packageId && product.packageQty)
      //   availableQuantity = Math.floor(availableQuantity / product.packageQty);
      if (product.quantity > availableQuantity)
        availableQuantity = product.quantity;

      for (let index = 1; index <= availableQuantity; index++) {
        availableQuantityArray.push({
          id: index,
          text: index.toString()
        });
      }

      this.availableQuantity.set(availableQuantityArray);

      if (qtty >= 10)
        this.productCardService.wasValueHigher10.set(true);

      this.productCardService.newQuantity.set(product?.quantity);
      return product;
    }
  });

  isLimitedUser: InputSignal<boolean> = input(false);
  isLockedBundle: InputSignal<boolean> = input(false);
  isFixedBundle = input(false);
  productKey: any = input(null)
  addItem = output<any>();
  removable: InputSignal<boolean> = input(false);
  removeItem = output<any>();
  dislikeItem = output<any>();
  substituteItem = output<BundleItems>();
  removeDislikeFromProduct = output<any>();
  addSubstitutionItem = output<any>();
  changeQuantity = output<any>();

  canBeDisliked = input<boolean>(false);
  availableQuantity: WritableSignal<{ id: number, text: string }[]> = signal([]);

  newQuantity = computed(() => {
    const quantity = this.productCardService.newQuantity();
    if (quantity) {
      return this.productCardService.newQuantity()
    }
    return 1
  });

  handleKeyUp(event: any) {
    this.productCardService.onKeyUp(event.value);
    this.#validateOOSPerQuantity();
    if (this.removable())
      this.changeQuantity.emit({ ...this.product(), validationQuantity: this.productCardService.newQuantity() - this.product().quantity, quantity: this.productCardService.newQuantity() })
  }

  handleChangeQuantitySelect(value: any) {
    this.productCardService.onChangeQuantitySelect(value);
    this.#validateOOSPerQuantity();
    if (this.removable())
      this.changeQuantity.emit({ ...this.product(), validationQuantity: this.productCardService.newQuantity() - this.product().quantity, quantity: this.productCardService.newQuantity() })
  }

  add() {
    const productMapped = { ...this.product(), quantity: this.productCardService.newQuantity() }
    this.addItem.emit(productMapped)
  }

  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/images/product-placeholder.webp';
  }

  goToProduct() {
    if (!this.isLimitedUser())
      this.router.navigate(['/shop/' + this.product()?.productURL]);
  }

  remove() {
    const productMapped = { ...this.product() }
    this.removeItem.emit(productMapped)
  }

  substitute() {
    if (!this.isFixedBundle())
      return;

    this.substituteItem.emit(this.product());
  }

  prevDislikeProduct() {

    if (this.product().disliked) return this.#handleDislikeChange(false)
    if (this.#signalsStoreService.userPreferences().dontShowAgainPrevDislike?.value)
      return this.#handleDislikeChange(true);

    this.#modalService
      .openModal(ModalContentTypes.DISLIKE_PRODUCT_CONFIRMATION, {
        title: 'Are you sure?',
        textContent: `You are about to dislike a product. We will do our best to never send you a product that you don't like. You can always adjust these settings later in your profile.`
      })
      .closed
      .subscribe((res: { isConfirm: boolean }) => {
        if (!res.isConfirm) return;
        this.#handleDislikeChange(true);
      });
  }

  #handleDislikeChange(isDislike: boolean) {
    if (!isDislike) {
      this.#productService.removeDislike(this.product().id).pipe(tap(() => this.removeDislikeFromProduct.emit(this.product()))).subscribe();
    } else {
      this.dislikeItem.emit(this.product());
    }
  }

  #validateOOSPerQuantity() {
    const newqtty = this.productCardService.newQuantity();
    const stock = this.stock()?.stock || null;

    if (!stock) return

    const limit = this.stock().limit;


    const newStock = stock - newqtty + (limit * -1);
    this.isOOSPerQtty.set(newStock <= 0);
  }
}
