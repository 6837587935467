<div class="signup">

  <div class="signup__header animated fadeInDown">
    <div class="container my-3">
      <a routerLink="/shop">
        <img class="signup__logo" src="/assets/images/logo.svg?v={{logoVersionNumber()}}" alt="Farmbox - Logo">
      </a>
    </div>
  </div>

  @if (isLoadingContent() || accountService.isLoading() || isWaitingResponse()) {
  <mat-progress-bar mode="indeterminate" value="accent"></mat-progress-bar>
  }

  <div class="d-flex flex-column align-items-center">
    <div class="{{this.matStepper().selectedIndex !== 2 ? 'signup__body' : 'signup__body-step-2'}} animated fadeInUp">
      <h2 class="h2-semibold d-flex justify-content-center">Create your account</h2>
      <div class="container-fluid">
        <!-- Icon overrides. -->
        <!-- <ng-template matStepperIcon="phone">
          <mat-icon>call_end</mat-icon>
        </ng-template> -->

        <mat-stepper [linear]="true" #stepper class="animated fadeIn">

          <mat-step #firstStep [completed]="isCompleteStep1()" [editable]="false" label="Delivery Information">
            <ng-template matStepperIcon="edit">
              1
            </ng-template>
            <div>
              <app-check-address-signup [stepper]="matStepper()" [firstStep]="firstStep"
                (deliveryAddressChanged)="deliveryAddressInformation = $event"
                (selectedDayChanged)="deliverySelectedDay = $event"
                (deliveryOptionChanged)="deliveryOptionSelected = $event" (onNextStep)="continue()">
              </app-check-address-signup>
            </div>
          </mat-step>

          <mat-step [completed]="formValidityPersonalInformation && firstStep.completed" [editable]="false"
            label="Personal Information">
            <app-personal-information-signup #personalInformationComponent [stepper]="matStepper()" [firstStep]="firstStep"
              (formValidityChanged)="formValidityPersonalInformation = $event"
              (personalInformationChanged)="personalInformationData = $event"
              [peopleOptions]="personalInformation()?.peopleShoppingFor"
              [dietaryRestrictions]="personalInformation()?.dietaryRestrictions" [referralSources]="referralSource"
              (dietaryChanged)="dietaryRestrictionsValues = $event" (onNextStep)="createNewClient()">
            </app-personal-information-signup>
          </mat-step>

          <mat-step [completed]="isCompleteStep3() && firstStep.completed" [editable]="false"
            label="Select Your Farmbox">
            <div class="animated fadeIn">
              <app-farmbox-choosing-signup [stepper]="matStepper()" [firstStep]="firstStep"
                [subscriptionCard]="bundleProducts()" (outProductSelected)="isCompleteStep3.set($event)"
                (onNextStep)="continue()">
              </app-farmbox-choosing-signup>
            </div>
          </mat-step>

          <mat-step [completed]="isCompleteStep4() && firstStep.completed" [editable]="false" label="Preferences">
            <div class="animated fadeIn">
              <app-preferences-signup [subscriptionCard]="subscriptionCard()" (onNextStep)="continue()"
                (outCanContinue)="isCompleteStep4.set($event)"></app-preferences-signup>
            </div>
          </mat-step>

          <mat-step [completed]="skipPaymentMethodStep || (formValidityPaymentMethod && !isWaitingResponse())"
            [editable]="false" [stepControl]="paymentMethodStepControl" label="Payment Information">
            <div>
              <app-payment-method-signup (formValidityChanged)="formValidityPaymentMethod = $event">
              </app-payment-method-signup>
            </div>
          </mat-step>

        </mat-stepper>

      </div>
    </div>
  </div>

</div>