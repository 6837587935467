<section class="animated fadeInUp section-hero" id="scroll-to-top-ref">
  <div class="container section-padding-lg">
    <div class="d-flex flex-column align-items-center">
      <div class="title is-center">
        <h1 class="title__main head-font">Farmers Market Delivered</h1>
        <p class="title__sub">Fresh, local produce at your doorstep</p>
      </div>
      <div class="spacer"></div>
      <div class="d-flex gap-3 flex-column  flex-sm-row justify-content-center align-items-center">
        <a class="btn btn-lg btn-light" routerLink='/shop'>Shop Now</a>
        <a class="btn btn-lg btn-outline-primary" routerLink='/pages/how-it-works'>How it Works?</a>
      </div>
    </div>
  </div>
</section>

<section class="section-feature-categories">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main head-font text-start">Featured Categories</h2>
    </div>
    <div class="mb-5">
      <div class="row gy-3">
        @for (item of featureProducts; track $index){
        <div class="col-6 col-md-3">
          <div class="feature-product" style="background-image: url('{{item.img}}')" (click)="goToShop(item.category)">
            <div class="feature-product__title">{{item.title}}</div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</section>

<section class="section-mid-banner px-3">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-5 col-lg-4">
        <h2 class="head-font text-center text-md-start mb-3">We’re different from typical grocery stores & big box
          retailers</h2>
      </div>
      <div class="col-md-7 col-lg-8">
        <ul class="ul-checklist">
          <li>Backing small local farmers using organic practices and producers committed to real ingredients.</li>
          <li>Champions of clean eating, which means our food is all about real food and simple ingredients.</li>
          <li>Utilizing reusable boxes and bags with each delivery to minimize our environmental footprint.</li>
          <li>Stringent quality standards, meticulously vetting hundreds of restricted ingredients.</li>
        </ul>
      </div>
    </div>
  </div>
</section>


<section class="section-how has-padding-block-5 px-3">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main head-font">How it works</h2>
    </div>
    <div class="how-step-wrap has-animation fadeInUp">
      <div class="how-step">
        <span class="icon">1</span>
        <div class="detail">
          <img [src]="'assets/images/landing/home/how/how-1.png'" alt="Choose yout farmbox" width="101" height="100" />
          <div class="text">Choose your farmbox</div>
        </div>
      </div>
      <div class="how-step is-alternate">
        <span class="icon">2</span>
        <div class="detail">
          <img [src]="'assets/images/landing/home/how/how-2.png'" alt="Shop & Customize image" width="101"
            height="100" />
          <div class="text">Shop & customize</div>
        </div>
      </div>
      <div class="how-step">
        <span class="icon">3</span>
        <div class="detail">
          <img [src]="'assets/images/landing/home/how/how-3.png'" alt="Farmers Harvest image" width="101"
            height="100" />
          <div class="text">Farmers harvest</div>
        </div>
      </div>
      <div class="how-step is-alternate">
        <span class="icon">4</span>
        <div class="detail">
          <img [src]="'assets/images/landing/home/how/how-4.png'" alt="We Deliver image" width="101" height="100" />
          <div class="text">We deliver</div>
        </div>
      </div>
    </div>
    @if (!hasSession()) {
    <div class="mt-3 d-flex justify-content-center">
      <a class="btn btn-secondary" routerLink='/signup'>Join Us</a>
    </div>
    }
  </div>
</section>

@if (!hasSession()) {
<section class="section-join-banner px-4">
  <div class="container">
    <h2 class="head-font">Ready to get started?</h2>
    <p>Join us today and start getting high-quality groceries delivered for less</p>
    <a class="btn btn-light" routerLink='/signup'>Create your box</a>
  </div>
</section>
}

<section class="section-testimonial">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="testimonial-title">
          <img src="assets/images/landing/home/quote.svg" alt="Quote image" />
          <h2 class="head-font mb-0">We couldn’t have said it better ourself</h2>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="testimonial">
          <div class="testimonial-card" *ngFor="let item of testimonials">
            <blockquote class="quote">{{item.quote}}</blockquote>
            <cite class="author">{{item.name}}</cite>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="testimonial-static">
          <div class="testimonial-static-card" *ngFor="let item of testimonials">
            <blockquote class="quote">{{item.quote}}</blockquote>
            <cite class="author">{{item.name}}</cite>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="section-blog">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main head-font">There's More to Explore</h2>
      <p class="title__sub">Visit our blog for how-tos, recipes and the latest from Farmbox Delivery</p>
    </div>

    <div class="row">
      <div class="col-sm-4" *ngFor="let post of posts">
        <div class="blog">
          <img class="blog_img" [src]="post.featured_image_url" alt="Image of blog article" />
          <div class="blog-category">
            <div *ngFor="let category of  post.categoriesData">
              <span class="category category-{{category.modifiedId}}">{{ category.name }}</span>
            </div>

          </div>
          <div class="blog_detail">
            <a class="blog_title" [href]="post.link" target="_blank">{{post.title.rendered}}</a>
            <div class="blog_desc" [innerHTML]="post.excerpt.rendered"></div>
          </div>
        </div>
      </div>
    </div>


    <div class="d-flex align-items-center justify-content-center mt-5">
      <a class="btn btn-secondary" target="_blank" [href]="blogUrl+'/blog'">Visit our blog</a>
    </div>
  </div>
</section>

@if (instagramPost().length) {
<section class="section-instagram">
  <div class="container">

    <div class="title is-center is-full-width">
      <h2 class="title__main head-font">Follow us on Instagram</h2>
    </div>

    <div class="row g-3">
      @for (post of instagramPost(); track post.id) {
      <div class="col-6 col-sm-3 col-md-3">
        <div class="insta">
          @if (post.media_type === IGImage) {
          <a [href]="post.permalink" rel="noopener noreferrer" target="_blank">
            <img class="insta-img cursor-pointer" [src]="post.media_url" alt="Image of instagram post" />
          </a>
          } @else {
          <div class="keen-slider" id="instagram-slider-{{post.id}}">
            @for (item of post.childrens; track item.id) {
            <div class="keen-slider__slide cursor-pointer">
              <a [href]="post.permalink" rel="noopener noreferrer" target="_blank">
                <img class="insta-img" [src]="item.media_url" alt="Image of instagram post" />
              </a>
            </div>
            }
          </div>

          <div class="dots">
            @for (dot of post.dotHelper; track dot; let index = $index) {
            <button type="button" title="Slide {{index + 1}}" (click)="post.slider?.moveToIdx(index)"
              [class]="'dot ' + (index === post.currentSlider ? 'active' : '')"></button>
            }
          </div>
          }
        </div>
      </div>
      }
    </div>

  </div>
</section>
}

<app-footer></app-footer>