<section class="container-fluid custom-box">
  @defer (when !isLoadingContent()) {

  <!-- Breadcrumbs: -->
  @if (!isLimitedUser()) {
  <div class="row">
    <div class="col align-self-start justify-content-start">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          @if (!isCustomBoxSignupFlow()) {
          <li class="breadcrumb-item">
            <a>
              <span class="material-symbols-outlined" [routerLink]="'/shop'">home</span>
            </a>
          </li>
          <li class="breadcrumb-item  breadcrum-text-item" (click)="gotoCategory(product()?.category?.name)">
            {{ product()?.category?.name }}
          </li>
          } @else {
          <li class="breadcrumb-item  breadcrum-text-item" [routerLink]="'/signup'">
            Go Back
          </li>
          }
          <li class="breadcrumb-item active" aria-current="page">
            <span class="fw-bold breadcrum-text-item">
              {{ product()?.name }}
            </span>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  }

  @if (isMobile()) {
  <div class="row">
    <div class="col-md-12">
      <ng-container *ngTemplateOutlet="mainTabsComponent; context: { $implicit: mainTabs() }"></ng-container>
    </div>
  </div>
  } @else {
  <div class="row g-5">

    <!-- My Box -->
    <section class="col-md-5">
      <ng-container *ngTemplateOutlet="myBoxComponent;"></ng-container>
    </section>

    <!-- Add-ons -->
    <section class="col-md-7">
      <ng-container *ngTemplateOutlet="mainTabsComponent; context: { $implicit: mainTabs() }"></ng-container>
    </section>

  </div>
  }

  } @placeholder (minimum 2s) {
  <div class="loading">
    <div class="loader">
      @for (item of [0,0,0,0,0]; track $index) {
      <div class="bg-secondary"></div>
      }
    </div>
    <h1>
      Just a moment! We're getting your FarmBox all set up for you.
    </h1>
  </div>
  }
</section>

<ng-template #mainTabsComponent let-tabs>
  <div class="custom-box-addons">

    <!-- Main Tabs -->
    <div class="row m-0">
      <div class="col-12 custom-box-addons__tabs">
        @if (isMobile()) {
        <div class="tab" (click)="viewMyBox()" [class.selected]="youBoxTabSelected()">
          Your Box
        </div>
        }
        @for (item of tabs; track $index) {
        @if (item.show) {
        <div class="tab" [class.selected]="item.active" (click)="changeTab(item)">
          {{item.name}}
        </div>
        }
        }
      </div>
    </div>

    <!-- Categories -->
    @if (categoriesInView().length) {
    <div class="custom-box-addons__categories">
      <div class="arrow" (click)="mainCategoriesScroll(true)">
        <span class="material-symbols-outlined">
          chevron_left
        </span>
      </div>
      <div class="main" id="mainCategories">
        @for (item of categoriesInView(); track item.name) {
        <span class="category" [class.selected]="item.selected" (click)="changeCategory(item)">
          {{item.name}}
        </span>
        }
      </div>
      <div class="arrow" (click)="mainCategoriesScroll()">
        <span class="material-symbols-outlined">
          chevron_right
        </span>
      </div>
    </div>
    }

    <!-- Products -->
    @if (addOnsInView().size) {
    <div class="row">
      <div class="col-12">
        @for (item of addOnsInView().keys(); track item) {
        @if (item) {
        <h4 class="h4-bold my-3">
          {{item}}
        </h4>
        }
        <div class="row">
          @for (addableProduct of addOnsInView().get(item)?.values(); track addableProduct?.id) {
          <div class="col-sm-6 col-md-6 col-lg-4 animated fadeIn p-12">
            <app-addable-product-card [product]="addableProduct" [isLimitedUser]="isLimitedUser()"
              [isLockedBundle]="product().bundle.isLocked || false" [isFixedBundle]="product().bundle.isFixed"
              (addItem)="onAddItem($event)">
            </app-addable-product-card>
          </div>
          }
        </div>
        }
      </div>
    </div>
    }

    <!-- Your Box -->
    @if (youBoxTabSelected()) {
    <div class="py-4">
      <ng-container *ngTemplateOutlet="myBoxComponent;"></ng-container>
    </div>
    }

  </div>
</ng-template>

<ng-template #myBoxComponent>
  <div class="custom-box-main">

    <!-- Product Name -->
    <div class="row">
      <div class="col-12">
        <header class="custom-box-main__header">
          <h4>
            {{ product()?.name }}
          </h4>

          <!-- Amount total for normal flow -->
          <h4 class="text-center">
            {{product().quantity}} x {{ subTotalAmount() | currency }}
          </h4>
        </header>
      </div>
    </div>

    <!-- Frequency and Next Delivery Date -->
    <div class="row">
      @if (bundleEditionType() === bundleEditionTypes.subscription) {
      <div class="col-12">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            @if (lockFrequencyButton()) {
            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <mat-label>Frequency</mat-label>
              <input matInput placeholder="Frequency" [readonly]="true"
                [value]="selectedFrequency()?.value?.name ?? ''">
            </mat-form-field>
            } @else {
            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <mat-label>Frequency</mat-label>
              <mat-select [value]="selectedFrequency()?.value?.id || selectedFrequency()?.id || ''"
                (selectionChange)="onChangeFrequency( $event)">
                @for (freq of signalStoreService.frequencies(); track $index) {
                <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            }
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <mat-label>
                Next Delivery Date
              </mat-label>
              <input matInput placeholder="Next Delivery Date" [readonly]="true" [value]="selectedStartDate() || ''" />
            </mat-form-field>
          </div>
        </div>
      </div>
      }
    </div>

    <!-- Save button -->
    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-secondary w-100" [disabled]="shouldDisableSaveButton()"
          (click)="saveAndSubscribe()">
          @if (bundleEditionType() === bundleEditionTypes.subscription) {
          Save Customizations
          @if (!product()?.existsInOrder) {
          & Subscribe
          }
          } @else {
          @if(product()?.existsInOrder) {
          Save Customizations
          } @else {
          Add to cart
          }
          }
        </button>
      </div>
    </div>

    <!-- Minimum box items -->
    @if (showLimitedMinimumOrder()) {
    <div class="row my-2">
      <div class="col-12">
        <div class="custom-box-main__maximum_indicator">
          <h5 class="title">
            Your box minimum order
          </h5>
          <span class="item">
            {{boxMinimumOrder()}} items
          </span>
          <section class="d-flex align-items-center gap-2 justify-content-around order-scale">
            <mat-progress-bar mode="determinate" [value]="boxPercentOfMinLimit()"></mat-progress-bar>
            <small class="text-nowrap item">
              {{ addedItemsQuantity() }} items
            </small>
          </section>
        </div>
      </div>
    </div>
    }

    <!-- Maximum box items -->
    @if (boxMaximumOrder()) {
    <div class="row">
      <div class="col-12">
        <div class="custom-box-main__maximum_indicator">
          <h5 class="title">
            Your Box Maximum
          </h5>
          <span class="item">
            {{boxMaximumOrder()}} items
          </span>
          <section class="d-flex align-items-center gap-2 justify-content-around order-scale">
            <mat-progress-bar mode="determinate" [value]="boxPercentOfLimit()"></mat-progress-bar>
            <small class="text-nowrap item">
              {{ addedItemsQuantity() }} items
            </small>
          </section>
        </div>
      </div>
    </div>
    }

    <!-- Disregard option -->
    @if (isAbleToDiscardChanges()) {
    <div class="row">
      <div class="col d-flex justify-content-end">
        <a href="javascript:void(0)" (click)="undoChanges()">
          <small>
            Discard Changes
          </small>
        </a>
      </div>
    </div>
    }

    <!-- Products -->
    @if (items().size) {
    <div class="row">
      @for (bundleItem of items().values(); track bundleItem.id) {
      <div class="col-sm-6 col-md-6 col-lg-6 animated fadeIn p-12">
        <app-addable-product-card [removable]="true" [product]="bundleItem" [isLimitedUser]="isLimitedUser()"
          [isLockedBundle]="product().bundle.isLocked || false" [isFixedBundle]="product().bundle.isFixed"
          (removeItem)="onRemoveItem($event)" (changeQuantity)="onChangeQuantity($event)"
          (dislikeItem)="onItemDisliked($event)" (substituteItem)="onItemSubstituted($event)"
          (removeDislikeFromProduct)="onRemoveDislikeFromProduct($event)">
        </app-addable-product-card>
      </div>
      }
    </div>
    }

  </div>
</ng-template>
