import { Component, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-sustainable',
    imports: [],
    templateUrl: './sustainable.component.html',
    styleUrl: './sustainable.component.scss'
})
export class SustainableComponent implements OnInit {

    private meta = inject(Meta);
    private title = inject(Title);

    ngOnInit(): void {
        this.setUpSEOTags();
    }


    private setUpSEOTags() {
        const { baseTitle, baseURL, type } = environment.config.seo;
        const url = 'pages/sustainable'
        const component = 'Sustainability'
        const title = `${component} | ${baseTitle}`;

        // Page title
        this.title.setTitle(title);

        // Open Graph for social networks:
        this.meta.addTag({ property: 'og:title', content: title });
        this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
        this.meta.addTag({ property: 'og:type', content: type });
    }


}
