<div class="page-title" style="background-image: url('assets/images/landing/why/title-bg.png')">
  <h1 class="h1-semibold head-font">Why FarmBox?</h1>
</div>
<div class="container has-padding-block-5">
  <div class="row">
    <div class="col-md-5 text-center text-md-start">
      <h3 class="h2-semibold head-font mt-sm-5 mb-3">Texas Whenever Possible</h3>
      <p>While other delivery companies and grocery stores in Texas mainly provide produce, meats, dairy, and pantry
        items from outside the state or Mexico, we collaborate with local producers.</p>
      <p>We take great pride in our local producers. By supporting them, we contribute to sustaining and strengthening
        our local economy with every FarmBox we deliver.</p>
    </div>
    <div class="col-md-7">
      <div class="why-cycle">

        <svg class="svg-curve curve-1" xmlns="http://www.w3.org/2000/svg" width="147" height="92" viewBox="0 0 147 92"
             fill="none">
          <path d="M145 89.6877C114.784 29.3658 82 7.633 1.99996 2.00008" stroke="#B3D7DF" stroke-width="3"
                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="16 16"/>
        </svg>
        <svg class="svg-curve curve-2" xmlns="http://www.w3.org/2000/svg" width="153" height="91" viewBox="0 0 153 91"
             fill="none">
          <path d="M1.99998 88.8219C29 32.5 82 1.49965 151 1.49965" stroke="#B3D7DF" stroke-width="3"
                stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="16 16"/>
        </svg>
        <svg class="svg-curve curve-3" xmlns="http://www.w3.org/2000/svg" width="260" height="24" viewBox="0 0 260 24"
             fill="none">
          <path d="M2 1.5C65.5 25.5 203 31.9994 258 1.5" stroke="#B3D7DF" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round" stroke-dasharray="16 16"/>
        </svg>


        <svg class="svg-curve-sm curve-1" xmlns="http://www.w3.org/2000/svg" width="102" height="232" viewBox="0 0 102 232" fill="none">
          <path d="M92 230.5C-28.5 177.5 -29.5 48.5 100.5 1.5" stroke="#B3D7DF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="16 16"/>
        </svg>
        <svg class="svg-curve-sm curve-2" xmlns="http://www.w3.org/2000/svg" width="102" height="233" viewBox="0 0 102 233" fill="none">
          <path d="M10.5 231C131 178 132 49 2 2" stroke="#B3D7DF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="16 16"/>
        </svg>

        <div class="cycle-info-wrap">
          <div class="cycle-info-adjust order-3 order-sm-1">
            <div class="cycle-info info-1">
              <img class="cycle-info__img" src="assets/images/landing/why/cycle/3.png"/>
              <div class="cycle-info__title">Windy Meadow Farms and Greener Pastures Chicken</div>
              <div class="cycle-info__desc">Supply pasture-raised poultry, preferred by many top chefs across Texas.
              </div>
            </div>
          </div>
          <div class="cycle-info-adjust order-1 order-sm-2">
            <div class="cycle-info info-2">
              <img class="cycle-info__img" src="assets/images/landing/why/cycle/1.png"/>
              <div class="cycle-info__title">Denton Creek Farms in Ponder, Texas</div>
              <div class="cycle-info__desc">Celebrated for their succulent tomatoes, exceptionally sweet cantaloupes,
                and rich leafy greens.
              </div>
            </div>
          </div>
          <div class="cycle-info-adjust order-2 order-sm-3">
            <div class="cycle-info info-3">
              <img class="cycle-info__img" src="assets/images/landing/why/cycle/2.png"/>
              <div class="cycle-info__title">Mill-King Creamery in McGregor, Texas</div>
              <div class="cycle-info__desc">Provides fresh, high-quality dairy products.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section-d-info">
  <div class="container">
    <div class="row">
    <div class="col-md-7 order-2 order-sm-1">
      <div class="d-info">
      <img  class="d-info__img" src="assets/images/landing/why/d-info-img.png"/>
      <ul>
        <li><span></span><span class="material-symbols-outlined">check_circle</span>No Additives</li>
        <li><span></span><span class="material-symbols-outlined">check_circle</span>Local Production</li>
        <li><span></span><span class="material-symbols-outlined">check_circle</span>Natural Ingredients</li>
        <li><span></span><span class="material-symbols-outlined">check_circle</span>Transparency</li>
      </ul>
      </div>
    </div>
    <div class="col-md-5 order-1 order-sm-2">
      <div class="d-grid h-100 justify-content-center align-items-center text-center text-md-end">
      <h3 class="h2-semibold head-font mt-5 mb-3 ">No Shady Ingredients!</h3>
      <h4 class="h4-semibold ">Simple Ingredients, No Confusing Additives</h4>
      <p class="">We champion clean eating, which means our focus is on simple ingredients and no confusing additives. Imagine a list of ingredients you can read without stumbling, with things you know and trust.</p>
        <p class="">We've set the bar high to keep anything unnecessary out of your pantry. While we can't list them all here, if you're curious, feel free to contact us!</p>
      </div>
    </div>
    </div>
  </div>
</div>
<div class="section-d-info-2">
  <div>
  <div class="container">
    <div class="d-reverse">
      <div class="d-reverse__col">
        <div class="d-reverse__text">
          <h3 class="h2-semibold head-font mb-4 ">Sustainable, Community Centered </h3>
          <p class="">We are committed to positively contributing to the world around us. Being environmentally friendly is a part of who we are. We utilize reusable boxes and bags with each delivery. We also contribute to our community through supporting local food banks.</p>
        </div>
      </div>
      <div class="d-reverse__col">
        <div class="d-reverse-info">
          <svg class="svg-top" xmlns="http://www.w3.org/2000/svg" width="115" height="33" viewBox="0 0 115 33" fill="none">
            <path d="M0.906418 0.00149536C17.8587 0.0310173 19.8654 18.1345 8.41642 30.7076C1.98434 37.7712 71.6257 23.6079 101.5 13C124.845 4.71061 109.5 0.00223541 109.5 0.00223541C109.5 0.00223541 14.5378 0.00164795 0.906418 0.00149536Z" fill="#E7EDD4"/>
          </svg>
          <svg class="svg-bottom" xmlns="http://www.w3.org/2000/svg" width="115" height="33" viewBox="0 0 115 33" fill="none">
            <path d="M0.906418 32.6049C17.8587 32.5754 19.8654 14.472 8.41642 1.89888C1.98434 -5.16472 71.6257 8.99856 101.5 19.6064C124.845 27.8958 109.5 32.6042 109.5 32.6042C109.5 32.6042 14.5378 32.6048 0.906418 32.6049Z" fill="#E7EDD4"/>
          </svg>

        <img  class="d-reverse-info__img" src="assets/images/landing/why/d-reverse-info-img.png"/>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>


<div class="section-road">
  <h3 class="h1-semibold head-font mb-5  text-center">Then and Now</h3>
  <div class="label-sm">Start</div>
  <div class="road-grid">
    <div class="bubble-content">
      <div class="num-sm">1</div>
      <img src="assets/images/landing/why/thennow/1.png" />
      <p>In April 2013, Greg Hoover pitched the idea of making it easier for local farm enthusiasts to get fresh, amazing ingredients without having to scour farmers markets.</p>
    </div>
    <div></div>
    <div class="bubble-content">
      <div class="num-sm">2</div>
      <img src="assets/images/landing/why/thennow/2.png" />
      <p>We're not settling for business as usual. We've upgraded with more homegrown food options and innovative technology to make your shopping experience easier.</p>
    </div>
    <div></div>
  </div>
  <div class="path-svg">
    <svg xmlns="http://www.w3.org/2000/svg" width="1134" height="107" viewBox="0 0 1134 107" fill="none">
      <path d="M2 53.5098C26.5 30.792 65.2398 2.00977 143.25 2.00977C221.26 2.00977 255.5 30.792 284.5 53.5098C313.5 76.2275 347.74 105.01 425.75 105.01C503.76 105.01 545.295 74.0789 567 53.5098C591.5 30.292 630.24 2.00977 708.25 2.00977C791.975 2.00977 823 26.292 849.5 53.5098C872.533 77.167 912.74 105.01 990.75 105.01C1068.76 105.01 1105.5 83.792 1132 53.5098" stroke="#B3D7DF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="16 16"/>
    </svg>
    <div class="start">Start</div>
    <div class="num num-1">1</div>
    <div class="num num-2">2</div>
    <div class="num num-3">3</div>
    <div class="num num-4">4</div>
    <div class="future">Future</div>
  </div>
  <div class="road-grid is-bottom">
    <div></div>
    <div class="bubble-content">
      <div class="num-sm">3</div>
      <img src="assets/images/landing/why/thennow/3.png" />
      <p>Today we continue to connect our customers with the best local producers and food creators who appreciate natural and healthy.</p>
    </div>
    <div></div>
    <div class="bubble-content">
      <div class="num-sm">4</div>
      <img src="assets/images/landing/why/thennow/4.png" />
      <p>Every day is a moment of amazement to see how our initial vision has grown into a growing community, thanks to the support of our farmers and customers.</p>
    </div>
  </div>
  <div class="label-sm">Future</div>
</div>

<section class="section-blog">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main head-font">There's More to Explore</h2>
      <p class="title__sub">Visit our blog for how-tos, recipes and the latest from Farmbox Delivery</p>
    </div>

    <div class="row">
      <div class="col-sm-4" *ngFor="let post of posts">
        <div class="blog">
          <img class="blog_img" [src]="post.featured_image_url" alt="Image of blog article" />
          <div class="blog-category">
            <div *ngFor="let category of  post.categoriesData">
              <span class="category category-{{category.modifiedId}}">{{ category.name }}</span>
            </div>

          </div>
          <div class="blog_detail">
            <a class="blog_title" [href]="post.link" target="_blank">{{post.title.rendered}}</a>
            <div class="blog_desc" [innerHTML]="post.excerpt.rendered"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center mt-5">
      <a class="btn btn-secondary" target="_blank" [href]="blogUrl">Visit our blog</a>
    </div>
  </div>
</section>

<section class="section-instagram">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main head-font">Follow us on Instagram</h2>
    </div>
    <div class="row g-3">
      <div class="col-sm-6 col-md-3" *ngFor="let item of insta">
        <div class="insta">
          <img class="insta-img" src="{{item.img}}" alt="Image of instagram post" />
        </div>
      </div>
    </div>
  </div>
</section>
