@if ((!this.hasPendingChanges() || hasAutoApplicableCoupon()) && coupons().length) {
<section class="coupons-section" [class.my-3]="fromCartPreview()">
  @for (coupon of coupons(); track $index;) {
  @if (!hasPendingChanges() || coupon.autoApply) {
  <div class="animated fadeInRigth coupons-section__coupon">

    <div class="d-flex gap-3 align-items-center">

      <!-- Tag Icon -->
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M11.172 2a3 3 0 0 1 2.121 .879l7.71 7.71a3.41 3.41 0 0 1 0 4.822l-5.592 5.592a3.41 3.41 0 0 1 -4.822 0l-7.71 -7.71a3 3 0 0 1 -.879 -2.121v-5.172a4 4 0 0 1 4 -4zm-3.672 3.5a2 2 0 0 0 -1.995 1.85l-.005 .15a2 2 0 1 0 2 -2" />
        </svg>
      </span>

      <!-- Coupon Name -->
      <span class="name">
        {{coupon.discount}}
      </span>

    </div>

    <!-- Price or Free Product Tag -->
    <div>
      @if (coupon.amount) {
      <span class="name">
        {{coupon.amount | currency}}
      </span>
      } @else {
      <span class="badge bg-success">
        Free Product
      </span>
      }
    </div>

  </div>
  }
  }
</section>
@if (!fromCartPreview()) {
<hr />
}
} @else if (this.hasPendingChanges() && coupons().length) {
<div class="animated fadeInRigth">
  <div class="alert alert-warning d-flex align-items-center gap-3" role="alert">
    <i class="fa fa-info-circle text-primary me-2"></i>
    <div class="h7-regular">
      Coupon shown after order submitted
    </div>
  </div>
</div>
@if (!fromCartPreview()) {
<hr>
}
}