<aside class="cart-preview-page-container" [class.h-100]="!isMobile()" [class.padding-bottom-mobile]="isMobile()">

  <header class="d-flex flex-column gap-4">

    <!-- Delivery date -->
    @if (deliveryInfo()) {
    <section class="card-delivery-date animated fadeIn">
      <div class="d-flex justify-content-center align-items-center">
        <i class="material-symbols-outlined text-secondary-800">calendar_today</i>
      </div>
      <div class="d-flex flex-column">
        <h5 class="h6-bold fw-bold m-0 text-secondary-800">
          {{deliveryInfo()?.deliveryDateText}}
        </h5>
        <span class="h7-semibold text-center text-secondary-700">
          Edit before {{deliveryInfo()?.cutoffDateText}}
        </span>
      </div>
    </section>
    }

    <!-- Go to order link -->
    <button type="button" class="btn btn-secondary btn-sm d-block w-100 animated fadeInDown h6-semibold"
      (click)="hideShowCartPreview()" [routerLink]="['/order']">
      Go to Checkout
    </button>

    <!-- Free delivery -->
    @if (orderDeliveryFee() && showFreeDeliverySlider()) {
    <div class="row">
      <section class="d-flex align-items-center gap-2 justify-content-around">

        <small class="h7-regular text-secondary-800">
          Get free delivery
        </small>

        <mat-progress-bar mode="determinate" [value]="percentMinSpendForFreeDelivery()"></mat-progress-bar>

        <small class="h7-regular text-secondary-800">
          {{ minSpendForFreeDelivery() | currency }}
        </small>

      </section>
    </div>
    }

  </header>

  <section>

    <!-- Subscriptions -->
    @if (groupedProducts()?.subscription.length || odooOrderProducts()?.subscription.length) {

    <hr class="mt-2 border-hr">

    <div class="d-flex justify-content-between">
      <span class="h7-semibold text-gray">
        Subscriptions
      </span>
      <a [routerLink]="['/settings/account/subscriptions']" class="h8-bold text-secondary-800">
        Manage subscriptions
      </a>
    </div>

    <!-- Firebase "subscription" products -->
    @for (product of groupedProducts()?.subscription; track product.id) {
    <app-product-cart-preview [product]="product" productKey="subscription" [isInOrder]="false"
      class="my-3"></app-product-cart-preview>
    }

    <!-- Odoo "subscription" products -->
    @for (product of odooOrderProducts()?.subscription; track $index) {
    <app-product-cart-preview [allowModification]="false" [product]="product" [isInOrder]="true"
      productKey="subscription" class="my-3"></app-product-cart-preview>
    }
    }

    <!-- A la carte -->
    @if (groupedProducts()?.common.length || odooOrderProducts()?.common.length) {

    <hr class="mt-3 border-hr">

    <span class="h7-semibold text-gray">
      A la carte
    </span>

    <!-- Firebase "a la carte" products -->
    @for (product of groupedProducts()?.common; track product.id) {
    <app-product-cart-preview [product]="product" productKey="common" [isInOrder]="false"
      class="my-3"></app-product-cart-preview>
    }

    <!-- Odoo "a la carte" products -->
    @for (product of odooOrderProducts()?.common; track $index) {
    <app-product-cart-preview [allowModification]="false" [product]="product" [isInOrder]="true" productKey="common"
      class="my-3"></app-product-cart-preview>
    }
    }

    <!-- Empty card -->
    @if (!groupedProducts()?.subscription.length &&
    !odooOrderProducts()?.subscription.length &&
    !groupedProducts()?.common.length &&
    !odooOrderProducts()?.common.length) {

    <div class="d-flex flex-column align-items-center mt-5 animated fadeInRight">

      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#007893"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
        <path d="M12.5 17h-6.5v-14h-2" />
        <path d="M6 5l14 1l-.854 5.977m-2.646 1.023h-10.5" />
        <path d="M19 22v-6" />
        <path d="M22 19l-3 -3l-3 3" />
      </svg>

      <span class="h3-semibold text-center text-secondary mt-3">
        Your cart is empty!
      </span>

      <span class="h5-semibold text-center text-secondary mt-2">
        Start shopping now to fill it with amazing items.
      </span>

      <button type="button" class="btn btn-outline-primary mt-3" (click)="goToMarket()">
        Go to Market
      </button>

    </div>
    }

  </section>

  <footer class="d-flex flex-column gap-1 animated fadeInRight mt-auto">

    <!-- Promotions -->
    <app-promotion-tags [coupons]="odooOrder()?.paymentDetails?.coupons ?? []"
      [hasAutoApplicableCoupon]="hasAutoApplicableCoupon()" [fromCartPreview]="true"
      [hasPendingChanges]="hasPendingChanges()"></app-promotion-tags>

    <hr class="m-0 border-hr">

    <!-- Subtotal -->
    <div class="d-flex justify-content-between">
      <span class="h7-bold text-gray">
        Subtotal
      </span>
      <span class="h7-bold text-secondary-700 font-variant-nums">
        {{orderSubTotal().toFixed(2) | currency}}
      </span>
    </div>

    <!-- Additional Fees -->
    @for (fee of additionalFees(); track $index) {
    <div class="d-flex justify-content-between">
      <span class="h7-bold text-gray">
        {{fee.name}}
      </span>
      <span class="h7-bold text-secondary-700 font-variant-nums">
        {{fee.total | currency}}
      </span>
    </div>
    }

    <!-- Taxes -->
    <div class="d-flex justify-content-between">
      <span class="h7-bold text-gray">
        Taxes
      </span>
      <span class="h7-bold text-secondary-700 font-variant-nums">
        {{orderTaxes().toFixed(2) | currency}}
      </span>
    </div>

    <!-- Delivery -->
    <div class="d-flex justify-content-between">
      <span class="h7-bold text-gray">
        Delivery
      </span>
      @if (orderDeliveryFee()) {
      <span class="h7-bold text-secondary-700 font-variant-nums">
        {{orderDeliveryFee().toFixed(2) | currency}}
      </span>
      } @else {
      <span class="h7-bold text-secondary-700">FREE</span>
      }
    </div>

    <!-- Tip -->
    <div class="d-flex justify-content-between">

      <span class="h7-bold text-gray">
        Tip
        @if (odooOrder()?.paymentDetails?.tip?.isRecurrent) {
        <i class="fa fa-refresh text-gray" aria-hidden="true"></i>
        }
      </span>

      <div class="d-flex {{isMobile() ? 'gap-5': 'gap-4'}}">

        @if (!orderTipAmount()) {
        <a href="javascript:void(0)" (click)="openModalTip(createTip)" class="h7-semibold">
          Add
        </a>
        } @else {
        <a href="javascript:void(0)" (click)="openModalTip(updateTip)" class="h7-semibold">
          Edit
        </a>

        <a href="javascript:void(0)" (click)="deleteTip()" class="h7-semibold">
          Delete
        </a>
        }

        <span class="h7-bold text-secondary-700 font-variant-nums">
          {{orderTipAmount().toFixed(2) | currency}}
        </span>

      </div>

    </div>

    <!-- Donation -->
    @if (hasVoluntaryDonationFlow()) {
    <div class="d-flex justify-content-between">
      <span class="h7-bold text-gray">
        Donation
      </span>
      <span class="h7-bold text-secondary-700 font-variant-nums">
        {{orderDonationAmountVoluntary().toFixed(2) | currency}}
      </span>
    </div>
    }

    <hr class="m-0 border-hr">

    <!-- Credits -->
    <div class="d-flex justify-content-between">
      <span class="h7-bold text-gray">
        Credits
      </span>
      <span class="h7-bold text-secondary-700 font-variant-nums">
        {{orderCredits().toFixed(2) | currency}}
      </span>
    </div>

    <hr class="m-0 border-hr">

    <!-- Total -->
    <div class="d-flex justify-content-between mb-2">
      <span class="h6-bold text-secondary-700">
        Total
      </span>
      <span class="h6-bold text-secondary-700 font-variant-nums">
        {{orderTotal().toFixed(2) | currency}}
      </span>
    </div>

  </footer>

  @if (carouselFavoritesProducts()?.length) {
  <div class="product-highlight">
    <h3 class="product-highlight__title">Your favorites</h3>
    <div class="product-highlight__body">
      <app-carousel [items]="carouselFavoritesProducts()"></app-carousel>
    </div>
  </div>
  }

  @if (carouselBuyAgainProducts()?.length) {
  <div class="product-highlight">
    <h3 class="product-highlight__title">Buy again</h3>
    <div class="product-highlight__body">
      <app-carousel [items]="carouselBuyAgainProducts()"></app-carousel>
    </div>
  </div>
  }

  @if (carouselSuggestedProducts()?.length) {
  <div class="product-highlight">
    <h3 class="product-highlight__title">Other people also added</h3>
    <div class="product-highlight__body">
      <app-carousel [items]="carouselSuggestedProducts()"></app-carousel>
    </div>
  </div>
  }

</aside>