import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Signal, WritableSignal, computed, inject, signal, viewChild } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule, Location, isPlatformBrowser } from '@angular/common';
import { ProductsService } from '../../product/products.service';
import { SignalsStoreService } from '../signals-store.service';
import { formatDateToReadableString } from '../utils/formatting';
import { DeliveriesService } from '../../settings/account/deliveries/deliveries.service';
import { DeliveryInformation } from '../../settings/account/deliveries/intarfaces';
import { MatTabsModule } from '@angular/material/tabs';
import { StockService } from '../../stock/stock.service';
import { OrderService } from '../order.service';
import { NotificationService } from '../notification/notification.service';
import { combineLatest, of, switchMap, tap } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { delay, filter, map } from 'rxjs/operators';
import { arrayToMap, isANoDeliveryDayUser, isANoPaymentMethodUser, trackBeginCheckoutEvent, trackSubscribeEvent, unableOperationMessage } from '../common/utils';
import { AddableProductCard } from '../product-card/addable-product-card/addable-product-card.component';
import { Session } from '../types/session.type';
import { MatProgressBar } from '@angular/material/progress-bar';
import { BundleEditionTypes, BundleEditionType } from '../types/order.type';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from '../constants/databases';
import { ResolutionService } from '../resolution.service';
import { ModalContentService } from '../modal-content/modal-content.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentTypes } from '../constants/modal-content-types';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../../../environments/environment';
import { BundleItems, Product } from '../../product/product.types';
import { FirebaseCrudService } from '../firebase-crud.service';
import { GroupedItems, ItemSelected, MainTab } from '../types/custom-box.types';
import { PaymentMethodSignupService } from '../../authentication/signup/payment-method-signup/payment-method-signup.service';
import { DataLayerService } from '../data-layer/data-layer.service';
import { CalculationsService } from '../calculations.service';

export enum CUSTOM_BOX_FLOW {
  ORDER = 'ORDER',
  PRODUCT = 'PRODUCT',
  SHOP = 'SHOP',
  SIGNUP = 'SIGNUP',
}

@Component({
  selector: 'app-custom-box',
  templateUrl: './custom-box.component.html',
  styleUrl: './custom-box.component.scss',
  imports: [
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    AddableProductCard,
    MatProgressBar,
    RouterLink
  ]
})
export class CustomBoxComponent implements OnInit, OnDestroy {
  signalStoreService = inject(SignalsStoreService);
  private deliveriesService = inject(DeliveriesService);
  private notificationService = inject(NotificationService);
  private orderService = inject(OrderService);
  private productsService = inject(ProductsService);
  private location = inject(Location);
  private localStorageService = inject(LocalStorageService);
  private signalsStoreService = inject(SignalsStoreService);
  private stockService = inject(StockService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private resolutionService = inject(ResolutionService);
  private modalContentService = inject(ModalContentService);
  private activeModal = inject(NgbModal);
  private firebaseCrudService = inject(FirebaseCrudService);
  private paytmentMethodSignupService = inject(PaymentMethodSignupService)
  #dataLayerService = inject(DataLayerService);
  #calculationsService = inject(CalculationsService);
  isMobile = computed(() => this.resolutionService.isMobile());

  stock = computed(() => this.stockService.stockSignal());
  stock$ = toObservable(this.stockService.stockSignal);
  firebaseOrder$ = toObservable(this.signalsStoreService.firebaseOrder);
  odooOrder$ = toObservable(this.orderService.odooOrder);
  productSignal$ = toObservable(this.productsService.productSignal);

  isOrderSkiped: WritableSignal<boolean> = signal(false);

  firebaseOrder = computed(() => this.signalStoreService.firebaseOrder());

  subscriptions$ = toObservable(this.signalStoreService.subscriptions);

  customBoxFlow: any
  deliveryInfo: Signal<any | null> = computed(() => this.getDeliveryInfo());
  items: any = signal(null)
  isLoadingContent = signal(true);
  lockFrequencyButton = signal(false);
  lockSaveButton = false;
  product: any = signal(null)
  selectedFrequency: WritableSignal<any> = signal('')
  selectedStartDate: Signal<any | null> = computed(() => !this.deliveryInfo() ? '' : this.deliveryInfo()?.deliveryDate?.monthNumber + '/' + this.deliveryInfo()?.deliveryDate?.dayNumber + '/' + this.deliveryInfo()?.deliveryDate?.year)
  subTotalAmount = signal(0)
  totalAmount: Signal<number> = computed(() => this.getTotalAmount())
  taxesAmount: Signal<number> = computed(() => this.getTaxesAmount(this.product()?.bundle?.items))

  productId: any = signal(null)
  redirectTo!: string;
  bundleEditionType: WritableSignal<BundleEditionType> = signal(BundleEditionTypes.subscription);
  bundleEditionTypes = BundleEditionTypes;

  isLimitedUser = computed(() => this.signalStoreService.isLimitedUser());
  addressInfo: Signal<any | null> = computed(() => this.setUpAddressInfo());
  boxMaximumOrder = computed(() => {
    const bundle = this.product()?.bundle;
    return bundle.limited?.maximum || null;
  });
  boxMinimumOrder = computed(() => {
    const bundle = this.product()?.bundle;
    return bundle.limited?.minimum || null;
  });
  addedItemsQuantity = signal(0);
  boxPercentOfLimit = computed(() => (this.addedItemsQuantity() * 100) / (this.boxMaximumOrder() || this.addedItemsQuantity()))
  boxPercentOfMinLimit = computed(() => (this.addedItemsQuantity() * 100) / (this.boxMinimumOrder() || this.addedItemsQuantity()))
  showLimitedMinimumOrder = computed(() => this.boxMinimumOrder() && this.addedItemsQuantity() < this.boxMinimumOrder());
  frequencies$ = toObservable(this.signalStoreService.frequencies);
  isLimitedUser$ = toObservable(this.isLimitedUser);
  preventSetUp: boolean = false;

  shouldPreventRedirection: boolean = false;

  isCustomBoxSignupFlow = computed(() => this.signalsStoreService.isCustomBoxSignupFlow());

  permissions = computed(() => this.signalsStoreService.permissions());

  isAbleToDiscardChanges = computed(() => this.#setUpDiscardChanges());

  #replacementVariantsId: number[] = [];
  showCartPreview = computed(() => this.signalStoreService.showCartPreview());

  hasSession = computed(() => this.signalStoreService.showCartPreview());

  //#region Properties

  mainTabs = signal<MainTab[]>([
    {
      name: 'Add-Ons',
      active: false,
      show: false,
      isPremium: false,
      key: 'addables',
      categories: [],
      products: new Map(),
    },
    {
      name: 'Premium Add-Ons',
      active: false,
      show: false,
      isPremium: true,
      key: 'premiumAddables',
      categories: [],
      products: new Map(),
    },
  ]);

  categoriesInView = signal<ItemSelected[]>([]);

  addOnsInView = signal<Map<string, Map<string, any>>>(new Map());

  youBoxTabSelected = signal(false);

  //#endregion

  //#region Constructors

  constructor(@Inject(PLATFORM_ID) private platformId: any) {

    toObservable(this.isMobile)
      .subscribe(value => {
        if (value || !this.youBoxTabSelected()) return;
        this.youBoxTabSelected.set(false);
        this.#setActiveMainTab();
      });
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.setUpUrl();

    const storedReplacementVariantsId: number[] = JSON.parse(this.localStorageService.get(LOCALSTORAGE_KEYS.REPLACEMENT_VARIANTS_ID) ?? '[]') || [];
    if (storedReplacementVariantsId.length) this.#replacementVariantsId = storedReplacementVariantsId;
  }

  ngOnDestroy(): void {
    this.localStorageService.remove(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
    this.localStorageService.remove(LOCALSTORAGE_KEYS.WELCOME_CUSTOM_BOX);
  }

  //#endregion

  private insertAtBeginning(map: any, key: string, value: any) {
    let newMap = new Map();
    newMap.set(key, value);

    for (let [k, v] of map) {
      newMap.set(k, v);
    }

    return newMap;
  }

  private insertAtPosition(map: Map<any, any>, key: string, value: any, position: number) {

    let newMap = new Map();
    let counter = 0;
    const array = Array.from(map);

    for (let index = 0; index < map.size + 1; index++) {
      if (index === position)
        newMap.set(key, value);
      else {
        const [k, v] = array[counter];
        newMap.set(k, v);
        counter++;
      }
    }

    return newMap;
  }

  private handleRouteChanges(): any {
    // Validations apply if product exists in firebaseOrder but not in odooOrder
    this.route.params
      .pipe(
        switchMap((params) => {

          const productId = params['productId'];

          if (productId) {
            this.productId.set(productId)
            return of(productId)
          }

          // Validate if the user is tagged as limited so we should get the productId from the subscription
          if (this.signalStoreService.isLimitedUser())
            return this.setUpLimitedUserInitialData();

          return of(null);
        }),
        filter((productId) => {
          // At this point, we should notify to user of an unexpected error and stop the pipes execution
          if (!productId)
            this.notificationService.show({ text: 'Failed to load the information. Please contact support for assistance.', type: 'warning' });
          return !!productId
        }),
        switchMap((productId) => {
          if (productId)
            return this.productsService.getProductById(productId, this.bundleEditionType()).pipe(map((res) => res.data as Product));
          return of(null);
        }),
        filter((product: any) => {
          if (!product)
            unableOperationMessage(this.modalContentService)
          return !!product;
        }),
        tap((product: Product) => {
          if (product) {
            const ids = this.#getBundleAndComponentsIdsForStock(product);
            this.stockService.getStock(undefined, ids);

          }
        }),
        switchMap((product: Product) =>
          combineLatest([
            this.productSignal$,
            this.odooOrder$,
            this.firebaseOrder$,
            this.stock$
          ])
        ),
        filter(([productFromOdoo, odooOrder, firebaseOrder, stock]) => {
          return !!productFromOdoo && !!stock?.length;
        }),
        switchMap(([productFromOdoo, odooOrder, firebaseOrder, stock]) => this.setUpInitialData(productFromOdoo, odooOrder, firebaseOrder, stock)),
        tap(() => this.#setUpMainTabs()),
        delay(1000),
        tap(() => this.isLoadingContent.set(false))
      )
      .subscribe();
  }

  #getBundleAndComponentsIdsForStock(product: Product): any[] {
    let ids = [product.id];

    const setProductIds = (currentData: number[], items: BundleItems[]) => {
      for (const item of items) {
        if (!item.productId) continue;
        currentData.push(item.productId)
      }
      return currentData;
    };

    if (product.bundle?.items.length)
      ids = [...setProductIds(ids, product.bundle.items)]
    if (product.bundle?.addables?.length)
      ids = [...setProductIds(ids, product.bundle.addables)]
    if (product.bundle?.premiumAddables?.length)
      ids = [...setProductIds(ids, product.bundle.premiumAddables)]

    return ids;
  }

  private setUpLimitedUserInitialData() {
    return combineLatest([this.subscriptions$, this.frequencies$, this.isLimitedUser$]).pipe(
      filter(([subscriptions, frequencies, isLimitedUser]) => (!!subscriptions && !!subscriptions.length && !!subscriptions[0].product?.id) && (!!frequencies && !!frequencies.length) && (typeof isLimitedUser === 'boolean')),
      map(([subscriptions, frequencies, isLimitedUser]) => {
        if (isLimitedUser) {
          const subscriptionFrequencyId = subscriptions[0].frequency;
          const subscriptionFrequency = frequencies.find(f => f.id === subscriptionFrequencyId);
          if (subscriptionFrequency) this.selectedFrequency.set(subscriptionFrequency);
        }
        return subscriptions[0].product.id
      }),
      tap((productId) => {
        if (productId)
          this.productId.set(productId);
      }),
      switchMap((productId) => of(productId))
    );
  }

  private setUpInitialData(productFromOdoo: any, odooOrder: any, firebaseOrder: any, stock: any) {
    this.isOrderSkiped.set(odooOrder?.isSkipped ?? false);
    if (this.preventSetUp) {
      return of(null)
    }
    let product: any;
    if (productFromOdoo)
      product = productFromOdoo;

    if (stock && stock.length) {
      product.variant = this.getVariant(product);

      if (this.orderService.odooOrder()?.products?.subscription) {
        const odooOrderProduct = this.orderService.odooOrder()?.products?.subscription.find((odooProduct: any) => odooProduct?.variant?.id === product?.variant?.id || odooProduct?.variant?.id === product?.variant?.variantId);
        if (odooOrderProduct)
          this.lockFrequencyButton.set(true);
      }

      const productFromFirebase = this.orderService.checkIfExistsProductInFirebaseOrder(product, firebaseOrder, this.bundleEditionType())
      if (productFromFirebase) {
        product = { ...product, ...productFromFirebase };
        product['existsInOrder'] = true;
      }

      const odooOrderProductData = this.orderService.odooOrder()?.products?.[this.bundleEditionType()].find((odooProduct: any) => odooProduct?.variant?.id === product?.variant?.id || odooProduct?.variant?.id === product?.variant?.variantId);
      if (this.shouldPreventRedirection && odooOrderProductData) {
        product.bundle.items = product.bundle.items.map((p: any) => {
          const fromOrder = odooOrderProductData.bundle.items.find((op: any) => op.id === p.id);
          return {
            ...p,
            quantity: fromOrder?.quantity ?? p.quantity
          }
        })

      }
    }

    if (product?.subscription?.frequency?.id) {
      this.selectedFrequency.set({
        ...product?.subscription.frequency,
        value: {
          id: product?.subscription.frequency?.id,
          name: product?.subscription.frequency?.name,
        }
      });
    } else if (!this.selectedFrequency() && this.signalStoreService.frequencies().length) {
      this.selectedFrequency.set(this.signalsStoreService.frequencies()[0]);
    }

    if ((odooOrder || firebaseOrder)) {
      const subscriptionFBOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'id', firebaseOrder)
      const subscriptionOdooOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'id', odooOrder)
      const oneTimeFBOrderProductsMap = this.getOrderProductsMapByKey('common', 'id', firebaseOrder)
      const oneTimeOdooOrderProductsMap = this.getOrderProductsMapByKey('common', 'id', odooOrder)

      const mapKey = product?.id;

      const existsInFBOrderSub = subscriptionFBOrderProductsMap.get(mapKey);
      const existsInOdooOrderSub = subscriptionOdooOrderProductsMap.get(mapKey);
      const existsInFBOrderOneTime = oneTimeFBOrderProductsMap.get(mapKey);
      const existsInOdooOrderOneTime = oneTimeOdooOrderProductsMap.get(mapKey);

      product['existsInOrder'] =
        !!(existsInFBOrderSub ||
          existsInOdooOrderSub ||
          existsInFBOrderOneTime ||
          existsInOdooOrderOneTime)
    }

    if (!(product.quantity > 0)) product.quantity = 1;

    if (product.bundle?.limited && product.bundle?.limited?.maximum) {
      const maxItems = product.bundle.items.reduce((a: number, b: any) => a + (b.isPremiumAddon ? 0 : b.quantity), 0);
      if (maxItems > product.bundle.limited.maximum)
        product.bundle.limited.maximum = maxItems
    }

    this.product.set(product)
    this.items.set(this.getBundleItems('items'));
    this.updateAddedItemsQuantity(true);
    this.localStorageService.set('customBoxPrevious', product)
    const currentItems: Map<number, any> = this.items();
    const itemsIds = Array.from(currentItems.keys()).sort().join();
    this.localStorageService.set(this.product().id + '_BundleItems', itemsIds)
    this.subTotalAmount.set(this.getTotalPrice(product))
    return of(null);
  }

  private getOrderProductsMapByKey(productKeyType: string, mapKey: string, sourceOrder: any,) {
    if (!sourceOrder || !sourceOrder?.products?.[productKeyType]?.length)
      return new Map()

    const orderProductsMap = arrayToMap(mapKey, sourceOrder.products[productKeyType]);

    return orderProductsMap;
  }

  setUpUrl() {
    const fullUrl = this.router.url;
    const fullUrlSplitteed = fullUrl.split('/');
    const editionType = fullUrlSplitteed[3] as BundleEditionType;
    this.bundleEditionType.set(editionType);

    const customBoxFlowSelected = fullUrlSplitteed[1]?.toUpperCase();
    switch (customBoxFlowSelected) {
      case CUSTOM_BOX_FLOW.ORDER:
      case CUSTOM_BOX_FLOW.PRODUCT:
      case CUSTOM_BOX_FLOW.SHOP:
        this.redirectTo = '/order'
        break;
      case CUSTOM_BOX_FLOW.SIGNUP:
        this.redirectTo = '/shop'
        break;
      default:
        break;
    }

    this.handleRouteChanges();
  }

  private getDeliveryInfo() {
    if (!this.deliveriesService.deliveryZoneInfo()) return null

    const { deliveryDate, cutoffDate, cutoffTime, deliveryWindow, cutoffDay } = (this.deliveriesService.deliveryZoneInfo()?.order || this.deliveriesService.deliveryZoneInfo()) as DeliveryInformation;
    const deliveryDateFormatted = formatDateToReadableString(deliveryDate)
    const cutoffDateFormatted = formatDateToReadableString(cutoffDate)

    const deliveryPickupDateText = this.signalsStoreService.isAddressRequired() ? 'Delivery Date: ' : 'Pick Up Date: '
    return {
      deliveryDate: deliveryDateFormatted,
      cutoffDate: cutoffDateFormatted,
      cutoffTime,
      cutoffDay,
      deliveryWindow,
      deliveryDateText: `${deliveryPickupDateText}${deliveryDateFormatted.dayName} ${deliveryDateFormatted.month} ${deliveryDateFormatted.day}`,
      cutoffDateText: `${cutoffDateFormatted.dayName} ${cutoffDateFormatted.month} ${cutoffDateFormatted.day} at ${cutoffTime}`
    }
  }

  onChangeFrequency(target: any) {
    const frequencyId = target.value
    this.selectedFrequency.set(this.signalStoreService.frequencies().find(frequency => frequency.id === frequencyId))
  }

  private updateAddedItemsQuantity(firstTime: boolean = false) {
    const items = !this.items().size ? [] : Array.from(this.items().values())
    const quantity = items.reduce((total: number, currentItem: any) => {
      return total + (currentItem.isPremiumAddon ? 0 : currentItem.quantity);
    }, 0);
    this.addedItemsQuantity.set(quantity);
    if (firstTime)
      this.localStorageService.set(this.product().id + '_BundleItemsQuantities', quantity);
  }

  onChangeQuantity(item: any) {
    if (!item.isPremiumAddon && !this.validateBoxLimit(item.validationQuantity)) {
      this.items.set([]);
      setTimeout(() => {
        this.items.set(this.getBundleItems('items'))
      }, 1);

      return;
    }

    const currentItem = this.items().get(item.id);
    currentItem.quantity = item.quantity;

    this.updateSubTotal();
    this.updateAddedItemsQuantity();
  }

  private updateSubTotal() {
    this.product.update((product: any) => {
      const items = !this.items().size ? [] : Array.from(this.items().values())
      product.bundle.items = items
      this.subTotalAmount.set(this.getTotalPrice(product))

      return product
    })
  }

  private validateBoxLimit(newQuantity: number = 0): boolean {
    if (this.boxMaximumOrder() && this.boxMinimumOrder()) {

      const maxMessage = "You've reached the maximum allowed number of items in this bundle.";
      const minMessage = "You've reached the minimum required number of items in this bundle."

      if (this.addedItemsQuantity() === this.boxMaximumOrder() && newQuantity >= 0) {
        this.notificationService.show({ text: maxMessage, type: 'warning' });
        return false
      }
      if (this.addedItemsQuantity() === this.boxMinimumOrder() && newQuantity < 0) {
        this.notificationService.show({ text: minMessage, type: 'warning' });
        return false
      }
      if ((this.addedItemsQuantity() + newQuantity) > this.boxMaximumOrder()) {
        this.notificationService.show({ text: maxMessage, type: 'warning' });
        return false
      }
      if (newQuantity < 0 && (this.addedItemsQuantity() + newQuantity) < this.boxMinimumOrder()) {
        this.notificationService.show({ text: minMessage, type: 'warning' });
        return false
      }
    }
    return true;
  }

  #setUpDiscardChanges() {
    const currentQuantity = this.addedItemsQuantity();
    const storedQuantity = this.localStorageService.get(this.product().id + '_BundleItemsQuantities');

    const currentItems: Map<number, any> = this.items();
    const currentItemsIds = Array.from(currentItems.keys()).sort().join();
    const storedItemsIds = this.localStorageService.get(this.product().id + '_BundleItems');

    return currentQuantity !== storedQuantity || currentItemsIds !== storedItemsIds;
  }

  undoChanges() {
    this.product.set(this.localStorageService.get('customBoxPrevious'))
    this.items.set(this.getBundleItems('items'));

    this.#recalculateSelectedCategory(true);

    this.updateAddedItemsQuantity();
    this.updateSubTotal();
  }

  getBundleItems(key: string) {
    const customBox = this.product()
    const customBoxItems = customBox?.bundle?.[key];

    return this.getItemsAsMap(customBox, customBoxItems);
  }

  private getItemsAsMap(array: any[], arrayItems: any[]) {
    const map = new Map();
    if (array && arrayItems?.length)
      for (const item of arrayItems) {
        map.set(item.id, item)
      }

    return map;
  }

  private saveItemsInMap(attributeKey: keyof CustomBoxComponent, array: any[], arrayItems: any[]) {
    if (array && arrayItems?.length)
      for (const item of arrayItems) {
        if (this?.[attributeKey])
          this[attributeKey].set(item.id, item)
      }
  }

  gotoCategory(category: string) {
    this.router.navigate(['/shop/' + (category || '').toLowerCase().replace(/\s/g, '-')]);
  }


  getMapValues(map: Map<string, Map<string, any>>, key: string) {
    const gettedMap = map.get(key) || new Map();
    return Array.from(gettedMap.values())
  }

  removeCharByRegex(text: string, char: string) {
    return text.replace(`/${char}/g`, "")
  }

  replaceSpacesByRegex(text: string) {
    if (!text) return '';
    return text.replace(`/ /g`, "_")
  }

  private getSubTotalAmount(product: number): number {
    return this.getTotalPrice(product);
  }

  private getTotalAmount(): number {
    return this.subTotalAmount() + this.taxesAmount();
  }

  private getTaxesAmount(items: any[]): number {
    if (!items || !items.length)
      return 0

    return items.reduce((total: number, product: any) => {
      let bundleTotalPriceWithoutTax = 0;
      let bundleTotalTax = 0;

      if (product?.bundle?.items?.length) {
        product.bundle.items.forEach((bundleItem: any) => {
          const itemPrice = (+bundleItem.price * +bundleItem.quantity)
          const itemTax = !bundleItem?.taxes ? 0 : +(bundleItem.taxes.reduce((total: number, item: any) =>
            total + +(item?.percentage)
            , 0) / 100).toFixed(2)

          bundleTotalPriceWithoutTax += itemPrice;
          bundleTotalTax += +(itemPrice * (itemTax)).toFixed(2);
        });
      }

      let productTaxPercent = 0

      if (!product?.bundle?.items?.length) {
        productTaxPercent = product.taxes.reduce((total: number, item: any) =>
          total + +(item?.percentage)
          , 0)
      }

      const productPrice = bundleTotalPriceWithoutTax
        ? bundleTotalPriceWithoutTax * +product.quantity
        : product.price
      const productTotalPrice = +productPrice * +product.quantity
      const productTaxAmount = +(+productTaxPercent * +productTotalPrice / 100).toFixed(2)
      const finalPrice = +(total + productTaxAmount + (bundleTotalTax * +product.quantity)).toFixed(2);

      return finalPrice;
    }, 0);
  }

  private getTotalPrice(product: any): number {
    let totalPrice = 0;
    if (!product?.bundle?.items?.length)
      return totalPrice

    if (product?.bundle?.isFixed) {
      // Fixed bundle:
      totalPrice = +product.price;
      const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
      if (premiumItems?.length) {
        totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
          const bundleItemPrice = (+bundleItem?.price * +bundleItem?.quantity) || 0
          return total + bundleItemPrice
        }, 0);
      }
    } else if (product?.bundle?.items?.length) {
      // Cummulative bundle
      totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
        const bundleItemPrice = (bundleItem?.isRemoved ? 0 : (+bundleItem?.price * +bundleItem?.quantity) || 0)
        return total + bundleItemPrice
      }, 0);
    }

    if (totalPrice === 0)
      totalPrice = +product?.price

    return totalPrice //* (product?.quantity || 1);
  }

  private getVariantFromPackage(product: any) {
    let stock = this.stockService.stockSignal();
    if (!stock)
      return null;

    // WHEN BE A PACKAGE CASE, attribute key will be null
    const variant = stock.find((item: any) => {
      // WHEN the product already have variantId because it comes from buy-again or favorites:
      if (item.variantId === product?.variantId) return item.variantId;
      // WHEN a product has packages just needs validate the productId:
      if (item.productId === product?.id) return item.variantId;
    });

    variant.id = variant.variantId
    // const selectedAttribute = null;
    // variant.attribute = this.selectedAttribute() || null
    return variant ? variant : null
  }

  getVariant(product: any) {
    let stock = this.stockService.stockSignal();
    if (!stock)
      return null;

    const hasPackages: boolean = !!product?.packages?.length;
    if (hasPackages)
      return this.getVariantFromPackage(product)

    // WHEN BE A PACKAGE CASE, attribute key will be null
    const variant = stock.find((item: any) => {
      // WHEN the product already have variantId because it comes from buy-again or favorites:
      if (item.variantId === product?.variantId) return item.variantId;

      if (product?.id === item?.productId) {
        // WHEN we dont have attributes because is a product without variants.
        if (!item?.attribute?.id && !item?.attribute?.value?.id)
          return item.variantId
      }
    });
    if (variant)
      variant.id = variant?.variantId
    return variant ? variant : null
  }

  private getSubscriptionDate(deliveryDate: any) {
    const { year, monthNumber, dayNumber } = deliveryDate;

    return `${year}-${monthNumber}-${dayNumber}`
  }

  private getProduct(product: any) {
    const subscriptionDate = this.getSubscriptionDate(this.deliveryInfo()?.deliveryDate)
    const frequency = this.selectedFrequency();
    const subscription = !frequency ? null : {
      startDate: subscriptionDate || null,
      frequency
    }
    const packageItem = null; // for now, in this flow doesnt exists
    const size = null; // for now, in this flow doesnt exists
    const totalPrice = this.getSubTotalAmount(product)
    const variant = this.getVariant(product);

    const addableItems = this.mainTabs().find(x => !x.isPremium)?.products;
    const premiumAddableItems = this.mainTabs().find(x => x.isPremium)?.products;

    // Addables items:
    const addablesItemsMap = !addableItems?.size ? [] : Array.from(addableItems.values())
    const addablesItems = !addablesItemsMap.length ? [] : addablesItemsMap.map(
      (currentMap: any) => Array.from(currentMap.values())
    ).flat()
    // Premium addables items:
    const premiumAddablesItemsMap = !premiumAddableItems?.size ? [] : Array.from(premiumAddableItems.values())
    const premiumAddablesItems = !premiumAddablesItemsMap.length ? [] : premiumAddablesItemsMap.map(
      (currentMap: any) => Array.from(currentMap.values())
    ).flat()

    const bundle = {
      ...product?.bundle,
      addables: addablesItems,
      premiumAddables: premiumAddablesItems,
      items: !this.items().size ? [] : [...Array.from(this.items().values())]
    }

    const quantity = product.quantity > 0 ? product.quantity : 1;
    const previousProduct = {
      ...product,
      bundle,
      quantity: this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX) || quantity,
      package: packageItem, // for now, in this flow doesnt exists
      size, // for now, in this flow doesnt exists
      subscription,
      totalPrice,
      variant
    }

    return this.getFirebaseProductOrder(previousProduct)
  }

  private getFirebaseProductOrder(product: any) {
    return {
      bundle: product?.bundle,
      category: product?.category,
      id: product?.id,
      img: product?.img,
      isSubscription: product?.isSubscription,
      isASubscription: this.bundleEditionType() === BundleEditionTypes.subscription,
      hasPremiumAddables: product?.bundle?.hasPremiumAddables,
      isFromCard: true,
      name: product?.name,
      package: product?.package || null,
      presentation: product?.presentation || null,
      price: +(+(product.price || 0)).toFixed(2),
      quantity: product?.quantity,
      size: product?.size,
      subCategory: product?.category,
      subscription: product?.subscription,
      taxes: product?.taxes,
      totalPrice: product?.totalPrice,
      variant: product?.variant,
      bundleQuantityChanged: product?.bundleQuantityChanged || false,
      isPublishedBundle: product?.isPublishedBundle ?? false
    }
  }

  private validateVariant(product: any) {
    if (!product?.variant) {
      unableOperationMessage(this.modalContentService);

      return false
    }

    if (!product?.bundle && !product?.variant?.stock) {
      unableOperationMessage(this.modalContentService);
      return false
    }

    return true
  }

  saveAndSubscribe(redirect = true) {
    if (isANoDeliveryDayUser(this.localStorageService, this.modalContentService)) return;
    if (isANoPaymentMethodUser(this.localStorageService, this.modalContentService, this.activeModal)) return;

    if (redirect)
      this.lockSaveButton = true;

    const product = this.getProduct(this.product())
    if (!this.stockService.stockSignal()?.length || !this.validateVariant(product) || (this.bundleEditionType() === BundleEditionTypes.subscription && !this.selectedFrequency()))
      return;

    this.preventSetUp = true;
    this.shouldPreventRedirection = false;
    if (this.isCustomBoxSignupFlow())
      this.#signupCustomBoxFlow(product);
    else
      this.#customBoxNormalFlow(product, redirect);
  }

  #customBoxNormalFlow(product: any, redirect: boolean) {
    this.orderService
      .addBundleToFirebaseOrder(this.firebaseOrder(), product, this.bundleEditionType())
      .pipe(
        tap(() => {
          if (!redirect)
            return;
          // Signup flow
          const signupCustomBox = this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
          if (signupCustomBox)
            this.afterChangesSavedSignupFlow();
          // Save to navego
          this.paytmentMethodSignupService
            .updateOrder(false, true)
            ?.pipe(
              tap((res) => {
                trackSubscribeEvent(this.#dataLayerService, res.newSubscriptionProducts);
                trackBeginCheckoutEvent(this.#dataLayerService, this.#calculationsService, [...res.subscriptionProducts, ...res.commonProducts], this.totalAmount());
              }),
            )
            .subscribe(() => {
              // Default flow
              this.localStorageService.set(LOCALSTORAGE_KEYS.SUBMIT_ORDER_FROM_CUSTOM_BOX, true);
              setTimeout(() => this.reloadPage(this.redirectTo), 200);
            });
        })
      ).subscribe();
  }

  #signupCustomBoxFlow(product: any) {
    const order: any = { products: { [this.bundleEditionType()]: [product] } };
    this.orderService.updateOrder({
      order,
      orderId: this.orderService.odooOrder().id,
      showDefaultMessage: false,
      deliveryInfo: this.isLimitedUser() ? this.deliveryInfo() : undefined,
      isUpdatingBundle: true
    }).pipe(
      tap((response) => {
        this.lockSaveButton = false;

        // Signup flow
        const signupCustomBox = this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
        if (signupCustomBox) this.afterChangesSavedSignupFlow();

        let subKeyPathProducts = `orderProducts.products.${this.bundleEditionType()}`
        const updateData: any = {};
        updateData[subKeyPathProducts] = [product];

        this.shouldPreventRedirection = !!(response.data?.order?.outOfStockProducts?.length);
        this.#removeBundleFromFirebase(
          this.bundleEditionType(),
          product,
          updateData,
          subKeyPathProducts,
          this.bundleEditionType() === this.bundleEditionTypes.subscription
        )
      })
    ).subscribe();
  }

  #removeBundleFromFirebase(productKey: string, product: any, updateData: any, subKeyPathProducts: string, subscription: boolean) {
    const defaultFlow = () => {
      if (!this.isLimitedUser()) {
        const isFromWelcome = this.localStorageService.get(LOCALSTORAGE_KEYS.WELCOME_CUSTOM_BOX);

        if (isFromWelcome) {
          this.localStorageService.set(LOCALSTORAGE_KEYS.NEW_ACCOUNT, true);
          this.location.replaceState(this.redirectTo);
          window.location.reload();
        } else {
          if (!this.shouldPreventRedirection) {
            this.router.navigate([this.redirectTo]);
          } else {
            this.preventSetUp = false;
          }
        }
      }
    }

    const productsGroup = this.firebaseOrder()?.products?.[productKey] || [];
    if (!productsGroup.length) return defaultFlow();
    const productsGroupUpdated = this.removeProductFromArray(product, productsGroup);
    if (productsGroupUpdated.length !== productsGroup.length)
      updateData[subKeyPathProducts] = productsGroupUpdated;
    const payload = { updateData }
    this.orderService.editSubKeys(payload, this.firebaseOrder());

    defaultFlow();
  }

  private removeProductFromArray(product: any, productList: any[]): any[] {
    return productList.filter((productItem) => productItem.package?.id && product.package?.id ? product.package.id !== productItem.package.id : productItem.variant.id !== product.variant.id);
  }

  private afterChangesSavedSignupFlow() {
    this.redirectTo = '/shop';
    this.localStorageService.set(LOCALSTORAGE_KEYS.NEW_ACCOUNT, true);
    this.localStorageService.remove(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
    this.localStorageService.remove(LOCALSTORAGE_KEYS.REPLACEMENT_VARIANTS_ID);
    this.signalStoreService.isCustomBoxSignupFlow.set(false);
  }


  reloadPage(route: string) {
    this.signalsStoreService.signUpStep.set(2)
    this.location.replaceState(route);
    window.location.reload();
  }

  private setUpAddressInfo() {
    const session: Session = this.signalsStoreService.sessionSignal() as any;

    if (!session) return null;

    const { street, city, zip: zipCode, stateCode: state } = session.address;
    const { location, name } = session?.pickUpInfo || {};

    const isAddressRequired = this.signalsStoreService.isAddressRequired();
    return {
      title: isAddressRequired ? 'Delivery Address' : 'Pick Up Address',
      text: isAddressRequired ? [street, city, state, zipCode].join(', ') : `${name} ${location ? `at ${location}` : ''}`
    };
  }

  shouldDisableSaveButton() {
    let res = (this.bundleEditionType() === BundleEditionTypes.subscription && !this.selectedFrequency()) || !this.items().size || this.lockSaveButton;
    res = res || !this.isValidQuantityLimit();
    return res;
  }

  private isValidQuantityLimit() {
    const qty = this.addedItemsQuantity();
    const max = this.boxMaximumOrder() ?? qty;
    const min = this.boxMinimumOrder() ?? qty;
    return qty <= max && qty >= min;
  }

  unskipOrder() {
    this.orderService.skipOrder({ orderId: this.orderService.odooOrder().id, donationAmount: null, isSkipping: false }).pipe(
      tap(() => window.location.reload())
    ).subscribe();
  }

  //#region Methods

  changeTab(tab: MainTab) {

    if (tab.active)
      return;

    this.youBoxTabSelected.set(false);

    this.mainTabs.update(value => {
      value.forEach(x => x.active = x.key === tab.key);
      return [...value];
    });

    this.categoriesInView
      .set(this.mainTabs().find(x => x.active)?.categories ?? []);

    this.addOnsInView.set(new Map());

    const name = this.categoriesInView().find(x => x.selected)?.name ?? '';
    this.#getAddOnsByCategory(name);
  }

  #setUpMainTabs() {

    for (const tab of this.mainTabs()) {
      const { categories, groupedItems } = this.#getGroupedItems(tab.key);
      tab.categories = categories;
      tab.products = groupedItems;
      tab.show = !!categories.length;
    }

    // Select the first category with categories
    if (this.isMobile())
      this.youBoxTabSelected.set(true);
    else
      this.#setActiveMainTab();
  }

  #setActiveMainTab() {

    const tab = this.mainTabs().find(x => x.categories.length);

    if (tab)
      this.changeTab(tab);
  }

  #getGroupedItems(key: string): GroupedItems {

    const groupedItems = new Map<string, Map<string, any>>();

    if (!this.product()?.bundle?.[key].length)
      return { groupedItems, categories: [] };

    const stock = this.stock();

    this.product()?.bundle?.[key]?.forEach((item: any) => {
      const categoryName = this.replaceSpacesByRegex(item.category.name);
      if (!groupedItems.has(categoryName)) {
        groupedItems.set(categoryName, new Map());
      }

      if (stock?.length) {
        const stockData = stock.find((s: any) => s.variantId === item.id);

        if (stockData)
          item.stock = stockData;
      }

      groupedItems.get(categoryName)!.set(item.id, item);
    });

    const categories = Array.from(groupedItems.keys()).map(x => ({ name: x, selected: false }));
    categories.unshift({ name: 'All', selected: true });

    return {
      groupedItems,
      categories
    };
  }

  changeCategory(category: ItemSelected) {

    if (category.selected)
      return;

    this.categoriesInView.update(value => {
      value.forEach(x => x.selected = x.name === category.name);
      return value;
    });

    this.#getAddOnsByCategory(category.name);
  }

  mainCategoriesScroll(isLeft = false) {
    document.getElementById('mainCategories')
      ?.scrollBy({
        left: 200 * (isLeft ? -1 : 1),
        behavior: 'smooth'
      });
  }

  #getAddOnsByCategory(category: string) {

    const activeTab = this.mainTabs().find(x => x.active);

    if (!activeTab)
      return;

    if (category.toLocaleLowerCase() === 'all')
      this.addOnsInView.set(activeTab.products);
    else
      this.addOnsInView.set(new Map().set('', activeTab.products.get(category)));
  }

  onAddItem(item: any) {

    if (!item.isPremiumAddon && !this.validateBoxLimit(item.quantity))
      return;

    // Add product to bundle

    this.items.set(this.insertAtBeginning(this.items(), item.id, item));

    // Delete the product from category

    const productCategory = this.replaceSpacesByRegex(item.category.name);
    let category = '';

    if (!this.addOnsInView().has(''))
      category = productCategory;

    this.addOnsInView().get(category)?.delete(item.id);

    if (!this.addOnsInView().get(category)?.size) {

      this.mainTabs.update(value => {

        const tab = value.find(x => x.active);
        if (!tab) return value;

        // Remove the category
        const index = tab.categories.findIndex(x => x.name === productCategory);
        if (index !== -1)
          tab.categories.splice(index, 1);

        // Remove the category in products
        tab.products.delete(productCategory);

        if (tab.categories.length === 1) {
          tab.categories = [];
          tab.active = false;
          tab.show = false;
          this.categoriesInView.set([]);
        } else
          this.changeCategory(tab.categories[0]);

        return value;
      });

      // Set active the other main tab with categories
      if (!this.mainTabs().some(x => x.active))
        this.#setActiveMainTab();
    }

    // Update quantity and subtotal

    this.updateAddedItemsQuantity();
    this.updateSubTotal();
  }

  onRemoveItem(item: any) {

    if (!item.isPremiumAddon && !this.validateBoxLimit((item.quantity * -1)))
      return;

    const categoryName = this.replaceSpacesByRegex(item.category.name);

    this.mainTabs.update(value => {

      const tab = value.find(x => x.isPremium === !!item.isPremiumAddon);

      if (!tab) return value;

      if (tab.products.has(categoryName))
        tab.products.get(categoryName)?.set(item.id, item);
      else {
        tab.products.set(categoryName, new Map().set(item.id, item));

        if (!tab.categories.length) {
          tab.categories.push({
            name: 'All',
            selected: true,
          });
        }

        tab.categories.push({
          name: categoryName,
          selected: false,
        });

        tab.show = true;
      }

      return value;
    });

    if (!this.mainTabs().some(x => x.active) && !this.youBoxTabSelected())
      this.changeTab(this.mainTabs()[0]);

    this.items()?.delete(item.id);

    this.#recalculateSelectedCategory();

    this.updateAddedItemsQuantity();
    this.updateSubTotal();
  }

  viewMyBox() {
    this.#clearAddOnsView();
    this.youBoxTabSelected.set(true);

  }

  #clearAddOnsView() {
    this.categoriesInView.set([]);
    this.addOnsInView.set(new Map());

    this.mainTabs.update(value => {
      value.forEach(x => x.active = false);
      return value;
    });
  }

  //#endregion

  //#region Bundle Substitution

  onItemDisliked(item: BundleItems) {
    this.#getSuggestionsProduct(item, false);
  }

  onItemSubstituted(item: BundleItems) {
    this.#getSuggestionsProduct(item, true);
  }

  #getSuggestionsProduct(item: BundleItems, isSubstitution: boolean) {
    if (!this.validateBoxLimit((item.quantity * -1)))
      return;

    if (!item.isReplaceable) {

      if (isSubstitution)
        this.#openModalNoSubstitutionProductFound();
      else
        this.#dislikeProductNoRepleacable(item);

      return;
    }

    // this.#openModalPlaceHolder();

    this.productsService
      .setDislikeWithSuggeestions(item.itemId, this.#replacementVariantsId || [], false, isSubstitution)
      .pipe(
        tap(res => {

          this.activeModal.dismissAll();

          if (res.length)
            this.#openModalBundleSubstitution(res, item.id, isSubstitution);
          else {

            if (!isSubstitution) {
              this.#updateDislikedItemsFirebase(item.id, true, false);
              item.disliked = true;
              this.localStorageService.set('customBoxPrevious', this.product());
            }

            this.#openModalNoSubstitutionProductFound();
          }
        })
      )
      .subscribe();
  }

  #dislikeProductNoRepleacable(item: BundleItems) {

    this.productsService
      .setDislike(item.id, true)
      .pipe(
        tap(() => {
          item.disliked = true;
          this.activeModal.dismissAll();
          this.localStorageService.set('customBoxPrevious', this.product());
          this.#openModalNoSubstitutionProductFound();
          this.#updateDislikedItemsFirebase(item.id, true, false);
        })
      )
      .subscribe();
  }

  #openModalPlaceHolder() {
    let cards = '';
    for (let index = 0; index < 4; index++) {
      cards += `
      <section class="col col-lg-3 col-md-6 col-sm-6 col-6">
        <div class="card mb-3 w-100">
          <div class="row g-0">
            <div class="col-4 bg-light-subtle">
              <img class="img-fluid rounded-start opacity-0" alt="...">
            </div>
            <div class="col-8">
              <div class="card-body">
                <div class="row placeholder-glow px-3">
                  <span class="placeholder p-2 col-8 placeholder-sm"></span>
                  <span class="placeholder p-2 col-4 placeholder-sm"></span>
                </div>
                <div class="row placeholder-glow px-3 mt-2">
                  <span class="placeholder p-2 col-6 placeholder-sm"></span>
                </div>
                <div class="row placeholder-glow px-3 mt-2">
                  <span class="placeholder p-2 col-6 placeholder-sm"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      `

    }
    this.modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
      textContent: `

      <div class="row placeholder-glow px-3">
        <span class="placeholder p-2 col-6 mx-auto placeholder-lg"></span>
      </div>
      <div class="row placeholder-glow px-3">
        <span class="placeholder p-2 col-6 mx-auto placeholder-lg"></span>
      </div>

      <p class="placeholder-glow mt-4">
        <span class="placeholder  placeholder-lg col-7"></span>
      <p>

      <div class="row">
        ${cards}
      </div>
      `,
      closeable: false,

    }, { size: 'xl' })
  }

  #openModalNoReplaceable() {
    this.modalContentService.openModal(ModalContentTypes.CONFIRMATION,
      {
        title: 'Product no Replaceable',
        textContent: `This product is marked as non-replaceable. If you have any questions or need assistance, please contact us at <b><i>${environment.config.contactEmail}</i></b>`,
        confirmButtonText: 'I Understand'
      }
    );
  }

  onRemoveDislikeFromProduct(item: BundleItems) {
    item.disliked = false;
    this.localStorageService.set('customBoxPrevious', this.product());
    this.#updateDislikedItemsFirebase(item.id, false, false);
  }

  #deleteDislikedProduct(id: number): number {

    const index = Array
      .from(this.items())
      .findIndex((x: any) => x[0] === id);

    this.items()?.delete(id);
    this.updateAddedItemsQuantity();
    this.updateSubTotal();

    return index;
  }

  #openModalBundleSubstitution(data: BundleItems[], idDelete: number, isSubstitution: boolean) {
    this.modalContentService.openModal(ModalContentTypes.BUNDLE_SUBSTITUTION,
      {
        title: 'Choose Replacement Product',
        textContent: `Select one product you'd prefer ${isSubstitution ? '' : 'instead of the disliked one'} and it will be added to you box.`,
        bundleSubstitutions: data,
        isSubstitution,
        closeable: false,
      }, { backdrop: 'static', size: 'xl' }
    )
      .closed
      .pipe(
        tap((item: BundleItems) => {

          if (!item) {
            if (!isSubstitution)
              this.#removeDislike(idDelete);
            return;
          }

          const index = this.#deleteDislikedProduct(idDelete);
          this.#onAddReplacement(item, index);
        })
      ).subscribe();
  }

  #openModalNoSubstitutionProductFound() {
    this.modalContentService.openModal(ModalContentTypes.CONFIRMATION,
      {
        title: 'No Available Replacement Found',
        textContent: `We did not find an available product for substitution please reach out to our customer support team if you have any questions at <b><i>${environment.config.contactEmail}</i></b>`,
        confirmButtonText: 'I Understand'
      }
    );
  }

  #onAddReplacement(item: BundleItems, index: number) {
    if (!this.validateBoxLimit(item.quantity)) return;
    const id: any = item.id;
    this.#replacementVariantsId.push(id);
    this.localStorageService.set(LOCALSTORAGE_KEYS.REPLACEMENT_VARIANTS_ID, JSON.stringify(this.#replacementVariantsId));
    this.items.set(this.insertAtPosition(this.items(), id, item, index));
    this.updateAddedItemsQuantity();
    this.updateSubTotal();

    // Save the item in firebase
    this.saveAndSubscribe(false);
  }

  #removeDislike(variantId: number) {
    this.productsService
      .removeDislike(variantId, false)
      .subscribe();
  }

  //#endregion

  //#region Update like/dislike property in firebase

  #updateDislikedItemsFirebase(productId: number, disliked: boolean, fav: boolean) {

    const session = this.signalStoreService.sessionSignal();
    if (!session) return;

    const userId = session.accountInfo.id.toString();

    this.firebaseCrudService
      .getByIdNoValueChange(FIREBASE_COLLECTIONS.ORDERS, userId)
      .pipe(
        tap(async res => {

          this.#updateDislikeFirebase(res?.orderProducts?.products?.subscription ?? [], productId, disliked, fav);
          this.#updateDislikeFirebase(res?.orderProducts?.products?.common ?? [], productId, disliked, fav);

          await this.firebaseCrudService
            .update(FIREBASE_COLLECTIONS.ORDERS, userId, res);
        })
      )
      .subscribe();
  }

  #updateDislikeFirebase(products: any[], productId: number, disliked: boolean, fav: boolean) {
    for (const product of products) {
      if (product.bundle.items.length) {
        const productIndex = product.bundle.items.findIndex((x: any) => x.id === productId);
        if (productIndex !== -1) {
          product.bundle.items[productIndex].disliked = disliked;
          product.bundle.items[productIndex].fav = fav;
        }
      } else if (product.id === productId) {
        product.disliked = disliked;
        product.fav = fav;
      }
    }
  }

  #recalculateSelectedCategory(setupTabs: boolean = false) {
    if (setupTabs) this.#setUpMainTabs();
    const selectedCategory = this.categoriesInView().find(x => x.selected)?.name ?? '';
    if (selectedCategory)
      this.#getAddOnsByCategory(selectedCategory);
  }

  //#endregion
}
