import { Component, OnInit, Signal, WritableSignal, computed, inject, signal, Renderer2, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, RouterLink } from '@angular/router';
import { CarouselComponent } from "../shared/carousel/carousel.component";
import { ProductsService } from '../product/products.service';
import { OrderService } from '../shared/order.service';
import { CommonModule } from '@angular/common';
import { DeliveriesService } from '../settings/account/deliveries/deliveries.service';
import { formatDateToReadableString } from '../shared/utils/formatting';
import { DeliveryInformation } from '../settings/account/deliveries/intarfaces';
import { FirebaseOrder, SignalsStoreService } from '../shared/signals-store.service';
import { ModalContentService } from '../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../shared/constants/modal-content-types';
import { BundleEditionType, BundleEditionTypes, ProductOrder, RELATED_ORDER_PRODUCTS, Fee, OrderResponse } from '../shared/types/order.type';
import { LocalStorageService } from '../shared/local-storage.service';
import { Session } from '../shared/types/session.type';
import { NgbCollapse, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { capitalizeWords, generateMapKeyFromValues, isANoDeliveryDayUser, isANoPaymentMethodUser, isNoAddressUser, openModalTanksForSubmit, trackBeginCheckoutEvent, trackSubscribeEvent } from '../shared/common/utils';
import { ResolutionService } from "../shared/resolution.service";
import { NumberRestrictionDirective } from '../shared/directives/number-restriction.directive';
import { environment } from '../../environments/environment';
import { TipsDonationsService } from '../shared/tips-donations.service';
import { CanDeactivateType } from '../shared/guards/deactivate/can-deactivate.types';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BundleModifyTypes } from '../shared/types/flows-config.types';
import { SubscriptionsService } from '../settings/account/subscriptions/subscriptions.service';
import { finalize, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { MEDIUM } from "../../scss/responsive/responsive";
import { BundleItems } from '../product/product.types';
import { afterNextRender } from '@angular/core';
import { LOCALSTORAGE_KEYS } from '../shared/constants/databases';
import { EmptyMessageComponent } from '../shared/empty-message/empty-message.component';
import { CalculationsService } from '../shared/calculations.service';
import { FirebaseCrudService } from '../shared/firebase-crud.service';
import { PromotionTagsComponent } from '../shared/promotion-tags/promotion-tags.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { DataLayerService } from '../shared/data-layer/data-layer.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
  providers: [SubscriptionsService],
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    CarouselComponent,
    NgbCollapse,
    NumberRestrictionDirective,
    MatProgressBarModule,
    EmptyMessageComponent,
    NgbModule,
    PromotionTagsComponent
  ]
})

export class OrderComponent implements OnInit, AfterViewInit {
  @ViewChild('layoutOrder', { static: false }) layoutOrder!: ElementRef;
  @ViewChild('orderActionWrap', { static: false }) orderActionWrap!: ElementRef;
  private mutationObserver!: MutationObserver;
  private renderer = inject(Renderer2);

  private modalContentService = inject(ModalContentService);
  deliveriesService = inject(DeliveriesService)
  localStorageService = inject(LocalStorageService);
  orderService = inject(OrderService);
  tipsDonationsService = inject(TipsDonationsService);
  signalsStoreService = inject(SignalsStoreService);
  private router = inject(Router);
  private subscriptionsService = inject(SubscriptionsService);
  private activeModal = inject(NgbModal);
  #calculationsService = inject(CalculationsService);
  #dataLayerService = inject(DataLayerService);

  private resolutionService = inject(ResolutionService);
  isMobile = computed(() => this.resolutionService.isMobile());

  BundleEditionTypes = BundleEditionTypes;
  updateDonation = ModalContentTypes.UPDATE_DONATION;
  createDonation = ModalContentTypes.CREATE_DONATION;
  updateTip = ModalContentTypes.UPDATE_TIP;
  createTip = ModalContentTypes.CREATE_TIP;

  addressInfo: Signal<any | null> = computed(() => this.setUpAddressInfo())
  bundleCloned: WritableSignal<any> = signal(null);
  bundlesChanged: WritableSignal<Map<number, Map<number, Partial<BundleItems>>>> = signal(new Map());
  bundlesCloned: WritableSignal<Map<number, ProductOrder>> = signal(new Map());
  bundlesRemoved: WritableSignal<Map<number, Map<number, BundleItems>>> = signal(new Map());
  carouselBuyAgainProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.BUY_AGAIN, false));
  carouselFavoritesProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.FAVORITES, false));
  carouselSuggestedProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.SUGGESTED));
  collapseStatuses: { [key: string]: boolean } = {};
  coupon: WritableSignal<string> = signal(''); // actionTrigger

  showCouponErrorMessage: Signal<boolean> = computed(() => this.orderService.showCouponErrorMessage());

  couponErrorMessage: Signal<string> = computed(() => this.orderService.couponErrorMessage());

  creditsAmount: Signal<number> = computed(() => +this.getCredits());
  creditsBalance: Signal<number> = computed(() => this.getCreditsBalance());
  appliedCredits: Signal<number> = computed(() => this.getAppliedCredits());
  odooOrder: Signal<any | null> = computed(() => this.getOdooOrder());
  deliveryFee: Signal<number> = computed(() => this.#calculationsService.getOrderDeliveryFee(this.odooOrder(), this.subTotal(), this.products()));
  additionalFees: Signal<Fee[]> = computed(() => this.#calculationsService.calculateOrderAdditionalFees(this.odooOrder(), this.subTotal()));
  additionalFeesTotal: Signal<number> = computed(() => this.#calculationsService.calculateOrderAdditionalFeesTotal(this.additionalFees()));
  hiddenCarouselProducts: Signal<number[]> = computed(() => this.signalsStoreService.hiddenCarouselProducts());

  minSpendForFreeDelivery: Signal<number> = computed(() => this.odooOrder()?.paymentDetails?.deliveryFee?.minSpend || 0);
  percentMinSpendForFreeDelivery: Signal<number> = computed(() => this.calculatePercentForFreeDelivery());

  deliveryInfo: Signal<any | null> = computed(() => this.setUpDeliveryInfo());
  donationAmount: WritableSignal<number> = signal(0);
  shouldShowTipsAndDonations: Signal<boolean> = computed(() => {
    const p = this.products();
    return p?.subscription?.length || p?.common?.length
  })
  tipAmount: Signal<number> = computed(() => this.getTipAmount());
  donationAmountVoluntary: Signal<number> = computed(() => this.getDonationVoluntaryAmount());

  firebaseOrder: Signal<any | null> = computed(() => this.setUpFirebaseOrder());
  hasPendingBundleChanges: WritableSignal<boolean> = signal(false)
  hasSelectedCustomQuantity: boolean = false;

  isAllowedOrder: Signal<boolean> = computed(() => this.hasMinOrder());
  hasPendingChanges: Signal<boolean> = computed(() => this.checkPendingChanges());

  hasAutoApplicableCoupon: Signal<boolean> = computed(() => this.#checkAutoApplicableCoupon());

  isCheckedSkipWeekDelivery: boolean = false;
  isConfirmedSkipWeekDelivery: boolean = false;
  isContentLoaded: Signal<any> = computed(() => this.signalsStoreService.isContentLoaded());
  isEditingBundle: boolean = false;
  mapQuantityChanges: any = new Map()
  products: Signal<any | null> = computed(() => this.setUpProducts());
  skippedSubscriptions: Signal<any | null> = computed(() => this.#setUpSkippedSubscriptions());
  subTotal: Signal<number> = computed(() => this.#getSubTotal());
  originalPriceSubtotal: Signal<number> = computed(() => this.#getOriginalPriceSubtotal());
  grossSubTotal: Signal<number> = computed(() => this.#getGrossSubTotal());
  totalSavings: Signal<number> = computed(() => this.#getTotalSavings());
  coupons: Signal<number> = computed(() => this.#getCoupons());
  couponsOriginalPrice: Signal<number> = computed(() => this.#getCouponsOriginalPrice());
  taxes: Signal<number> = computed(() => +this.getTaxes());
  total: Signal<number> = computed(() => +this.#getTotal());
  isTemporaryRouteChange = signal(false);
  isDeletingProduct = signal(false);
  temporaryRouteChangeMessage = signal('');
  //#region flows config
  hasMembershipFlow = signal(environment.config.flows.membership);
  showFreeDeliverySlider = signal(environment.config.flows.order.showFreeDeliverySlider);
  hasVoluntaryDonationFlow = signal(environment.config.flows.order.voluntaryDonationFlow);
  bundleModifyType = signal(environment.config.flows.bundles.modifyType);
  //#endregion

  allowPageExit = false;

  bundleModifyTypes = BundleModifyTypes;

  userChooseContinueShopping: boolean = false;

  /**
   * This property is isued to validate if the users can submit thier order despite not having reached the minimum price to save the order
   * because the bundle has not been published (its price is equal to zero).
   */
  orderHasUnpublishedBundle: Signal<boolean> = computed(() => {
    const products = this.products();
    const existsUnpublishedSubscriptionBundle = products?.subscription.some((p: any) => p?.bundle?.items && p.totalPrice === 0) || false;
    const existsUnpublishedAlaCarteBundle = products?.common.some((p: any) => p?.bundle?.items && p.totalPrice === 0) || false;
    return existsUnpublishedAlaCarteBundle || existsUnpublishedSubscriptionBundle;
  });

  marketStatus = computed(() => this.signalsStoreService.marketStatus());
  closedMarket = computed(() => {
    const im = this.isMobile();
    const ms = this.marketStatus();
    return {
      title: !ms.isOpen && im ?
        `Our Online Farmer's Market Opens ${capitalizeWords(ms.openingDay)} at ${ms.openingHour}!` :
        `Our Online Farmer's Market Opens ${capitalizeWords(ms.openingDay)} at ${ms.openingHour}!`,
      legend: `We're busy sourcing next week's availability! Check back ${capitalizeWords(ms.openingDay)} to begin shopping.`
    };
  })

  isUpdating = signal(false);

  contactEmail = signal('');
  preOrdersSkipDisclaimer = computed(() => {
    const ce = this.contactEmail();
    if (!ce) return '';
    return `You cannot skip this week’s order as it contains pre-ordered products. For assistance, please contact us at ${ce}.`
  })
  preOrderedProductDisclaimer = signal('This pre-ordered product cannot be modified or removed.')
  hasPreorderedProducts = computed(() => {
    const odooOrder = this.odooOrder();
    return (odooOrder?.products?.common || []).some((p: { isPreOrder: boolean; }) => p.isPreOrder);
  });

  async canDeactivate(): Promise<CanDeactivateType> {
    const canExit = await this.canExitOrder();
    return canExit || this.userChooseContinueShopping;
  }

  private async canExitOrder(): Promise<CanDeactivateType> {
    return new Promise(async (resolve, reject) => {
      const hasMinOrder = this.isAllowedOrder()
      const hasPendingProducts = this.hasPendingChanges()

      if (this.allowPageExit) {
        this.allowPageExit = false;
        return resolve(true);
      }

      if (hasMinOrder && hasPendingProducts) {
        const canExit = await this.openModalOrderNotSubmitted() as boolean;
        return resolve(canExit);
      }

      return resolve(true);
    })
  }

  ngOnInit(): void {
    this.orderService.getOrder(this.signalsStoreService.marketStatus);
    this.setUpDeliveryInfo();
  }
  ngAfterViewInit(): void {
    this.mutationObserver = new MutationObserver((mutations) => {
      this.setPaddingBottom();
    });

    this.#setMutationObserver();

    setTimeout(() => {
      this.setPaddingBottom();
    }, 1);
  }

  constructor() {
    afterNextRender(() => {
      this.#validateTemporaryRoute()
      this.contactEmail.set(environment.config.contactEmail);
    });

    toObservable(this.deliveryInfo)
      .pipe(
        tap(value => value && this.#showThanksModal()))
      .subscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.setPaddingBottom();
  }

  isIphone(): boolean {
    return /iPhone/i.test(navigator.userAgent);
  }

  setPaddingBottom(): void {
    if ((window.innerWidth < MEDIUM) || this.isIphone()) {
      if (!this.orderActionWrap?.nativeElement) return;
      // setTimeout(() => {
      const orderActionWrapHeight = this.orderActionWrap.nativeElement.offsetHeight;
      // Set the padding-bottom to the height of .order-action-wrap
      this.renderer.setStyle(
        this.layoutOrder.nativeElement,
        'padding-bottom',
        `${orderActionWrapHeight}px`
      );
      // }, 2000);
    }
    else {
      if (!this.layoutOrder?.nativeElement) return;
      this.renderer.removeStyle(this.layoutOrder.nativeElement, 'padding-bottom');
    }
  }

  checkPendingChanges() {
    return !!(this.products() &&
      [...this.products()?.common, ...this.products()?.subscription].some(product => product.hasPendingChanges));
  }

  getTipAmount() {
    const odooTipAmount = this.odooOrder()?.paymentDetails?.tip?.amount || 0
    const firebaseTipAmount = this.firebaseOrder()?.paymentDetails?.tip?.amount || 0
    const showTipOrDonation = this.shouldShowTipsAndDonations();
    if (!showTipOrDonation) return 0
    return firebaseTipAmount || odooTipAmount || 0
  }

  getDonationVoluntaryAmount() {
    const odooDonationAmount = this.odooOrder()?.paymentDetails?.donation?.amount || 0
    const firebaseDonationAmount = this.firebaseOrder()?.paymentDetails?.donation?.amount || 0
    const showTipOrDonation = this.shouldShowTipsAndDonations();
    if (!showTipOrDonation) return 0
    return firebaseDonationAmount || odooDonationAmount || 0
  }

  private processProducts(products: any[], subscription: boolean): any[] {
    return products.map(product => {
      const totalPrice = this.#calculationsService.calculateProductTotalPrice(product);
      this.collapseStatuses[`${product.id}${subscription ? '_1' : '_0'}`] = true;
      return { ...product, totalPrice };
    });
  }

  getOdooOrder() {
    const odooOrder = this.orderService.odooOrder()

    if (!odooOrder || !odooOrder?.products?.common || !odooOrder?.products?.subscription)
      return odooOrder

    this.isCheckedSkipWeekDelivery = odooOrder?.isSkipped || false;
    odooOrder.products.common = this.processProducts(odooOrder?.products?.common || [], false);
    odooOrder.products.subscription = this.processProducts(odooOrder?.products?.subscription || [], true);

    return odooOrder
  }

  private setUpFirebaseOrder() {
    const firebaseOrder = this.signalsStoreService.firebaseOrder();

    if (!firebaseOrder)
      return null

    if (!firebaseOrder?.products)
      return firebaseOrder

    firebaseOrder.products.common = this.processProducts(firebaseOrder.products.common || [], false);
    firebaseOrder.products.subscription = this.processProducts(firebaseOrder.products.subscription || [], true);

    return firebaseOrder;
  }

  private setUpProducts(): any {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();

    const { common: odooCommonProducts = [], subscription: odooSubscriptionProducts = [] } = odooOrder?.products || {};
    const { common: fbCommonProducts = [], subscription: fbSubscriptionProducts = [] } = firebaseOrder?.products || {};

    const commonProducts = this.combineArrays(fbCommonProducts, odooCommonProducts, false)
    const subscriptionProducts = this.combineArrays(fbSubscriptionProducts, odooSubscriptionProducts, true)

    return {
      common: commonProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
      subscription: subscriptionProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
    }
  }

  private combineArrays(fbProducts: any[], odooProducts: any[], isSubscription: boolean): any[] {
    // Create a map of fbProducts based on ID for efficient lookup
    const fbProductsMap = new Map();
    fbProducts.forEach(product => {
      product.isSubscription = isSubscription
      let key = product.variant.id;
      if (product.package?.id) {
        key += `_${product.package.id}`;
      }
      fbProductsMap.set(key.toString(), product);
    });

    // Combine the products
    const combinedProducts: any[] = [];
    odooProducts.forEach(product => {
      product.isSubscription = isSubscription
      const variantId = product.variant.id;
      const packageId = product.package?.id || null;
      const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
      product['isInOdooOrder'] = true;
      const productData = {
        previousValue: product.quantity,
        quantity: product.quantity,
        validations: {
          isInputValid: false,
          isValueChanged: false,
          wasValueHigher10: product.quantity >= 10,
        },
      }
      const quantityChangesMapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
      product['firebaseMapKey'] = fbMapKey;
      if (fbProductsMap.has(fbMapKey)) {
        // If there's a match, take quantity from fbProducts
        const fbProduct = fbProductsMap.get(fbMapKey);
        product.quantity = fbProduct.quantity;
        productData.quantity = fbProduct.quantity;
        productData.validations.wasValueHigher10 = fbProduct.quantity >= 10
        product.bundle = fbProduct.bundle
        product.totalPrice = fbProduct.totalPrice
        product['hasPendingChanges'] = true;
        product.updatedAt = fbProduct.updatedAt;
        combinedProducts.push(product);
      } else {
        // If there's no match, take information from odooProducts
        product['hasPendingChanges'] = false;
        combinedProducts.push(product);
      }

      this.mapQuantityChanges.set(quantityChangesMapKey, JSON.parse(JSON.stringify(productData)))
    });

    // Add elements from fbProducts if includeFromFb is true
    fbProducts.forEach(product => {
      if (!odooProducts.some(odooProduct => product.package?.id && odooProduct.package?.id ? (product.package.id === odooProduct.package.id) : (odooProduct.variant.id === product.variant.id))) {
        product['hasPendingChanges'] = true;
        product['isInOdooOrder'] = false;

        const productData = {
          previousValue: product.quantity,
          quantity: product.quantity,
          validations: {
            isInputValid: false,
            isValueChanged: false,
            wasValueHigher10: product.quantity >= 10,
          },
        }
        const variantId = product.variant.id;
        const packageId = product.package?.id || null;
        const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
        const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
        this.mapQuantityChanges.set(mapKey, JSON.parse(JSON.stringify(productData)))
        product['firebaseMapKey'] = fbMapKey;
        combinedProducts.push(product);
      }
    });

    return combinedProducts;
  }

  private getCredits() {
    return +(this.odooOrder()?.paymentDetails?.credits || 0)
  }

  #getSubTotal() {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();
    return this.#calculationsService.getOrderSubTotal(odooOrder, firebaseOrder);
  }

  #getGrossSubTotal() {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();
    const couponsOriginalPrice = this.couponsOriginalPrice();
    return this.#calculationsService.getOrderGrossSubTotal(odooOrder, firebaseOrder, couponsOriginalPrice);
  }

  #getOriginalPriceSubtotal() {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();
    const couponsOriginalPrice = this.couponsOriginalPrice();
    return this.#calculationsService.getOriginalPriceSubtotal(odooOrder, firebaseOrder);
  }

  #getTotalSavings() {
    const subtotal = this.subTotal();
    const grossSubtotal = this.grossSubTotal();
    const saving = grossSubtotal - subtotal;
    return saving > 0 ? saving : 0;
  }

  private getTaxes() {
    const products = this.#calculationsService.setUpOrderProducts(this.odooOrder(), this.firebaseOrder());
    return this.#calculationsService.getOrderTaxes(products, this.odooOrder(), this.deliveryFee());
  }

  #getTotal(): number {
    return this.#calculationsService.getOrderTotal({
      subTotal: this.subTotal(),
      deliveryFee: this.deliveryFee(),
      taxes: this.taxes(),
      tipAmount: this.tipAmount(),
      donationAmountVoluntary: this.donationAmountVoluntary(),
      creditsAmount: this.creditsAmount(),
      coupons: (this.coupons() * -1),
      includeCoupons: !this.checkPendingChanges(),
      seasonalDeposits: this.odooOrder()?.paymentDetails?.seasonalDeposits || 0,
      additionalFees: this.additionalFeesTotal()
    });
  }

  #getCoupons() {
    const coupons = this.odooOrder()?.paymentDetails?.coupons || [];
    return this.#calculationsService.getOrderCoupons(coupons);
  }

  #getCouponsOriginalPrice() {
    const coupons = this.odooOrder()?.paymentDetails?.coupons || [];
    return this.#calculationsService.getOrderCouponsOriginalPrice(coupons);
  }

  private getCreditsBalance() {
    return this.#calculationsService.getOrderCreditsBalance({
      creditsAmount: this.creditsAmount(),
      subTotal: this.subTotal(),
      deliveryFee: this.deliveryFee(),
      taxes: this.taxes(),
      tipAmount: this.tipAmount(),
      donationAmountVoluntary: this.donationAmountVoluntary(),
      additionalFees: this.additionalFeesTotal()
    })
  }

  private getAppliedCredits() {
    const creditsAmount = this.creditsAmount()
    if (!creditsAmount)
      return 0

    const total = this.subTotal() + this.deliveryFee() + this.taxes() + this.tipAmount() + this.donationAmountVoluntary()
    return total > creditsAmount ? creditsAmount : total;
  }

  private hasMinOrder(): boolean {
    return this.originalPriceSubtotal() >= (this.odooOrder()?.paymentDetails?.minOrder || 0) || this.orderHasUnpublishedBundle();
  }

  onChangeSkipWeekDelivery() {
    this.isConfirmedSkipWeekDelivery = this.odooOrder()?.isSkipped ? false : true;
    if (this.isCheckedSkipWeekDelivery || !this.odooOrder()?.isSkipped) {
      if (environment.config.flows.order.askForDonationOnSkip)
        return this.openModalDonationSkippable();
      return this.openModalSkipWithDonationFlow();
    }

    const odooOrder = this.odooOrder();
    if (!odooOrder?.id) return

    const args = {
      orderId: +odooOrder.id,
      isSkipping: false,
      donationAmount: 0,
    }

    this.orderService.skipOrder(args)
      .subscribe({
        next: (res) => {
          const isOrderSkipped = res?.data?.order?.isSkipped || false;
          this.isCheckedSkipWeekDelivery = isOrderSkipped;
          this.isConfirmedSkipWeekDelivery = isOrderSkipped;

          setTimeout(() => {
            this.#setMutationObserver();
          }, 500);
        }
      })
  }

  onChangeInput(event: any, product: any, isSubscription: boolean = false) {
    product.quantity = +event.target.value;

    const productMapped = product
    const productKey = isSubscription ? `subscription` : `common`;
    const firebaseProductsOrder = this.firebaseOrder()?.products?.[productKey] ?? [];
    const newValue = this.updateProductInFirebase(productMapped, firebaseProductsOrder);
    const payload = {
      subKeyPath: `orderProducts.products.${productKey}`,
      newValue
    }

    this.orderService.editSubKey(payload);
  }

  onChangeQuantity(target: any, product: any, isSubscription: boolean = false, bundleProduct?: any) {
    if (!bundleProduct)
      product.quantity = +target.value;
    else {
      bundleProduct.quantity = +target.value;
      if (!product)
        return
    }

    const productMapped = product
    if (product?.bundle?.items)
      productMapped.bundle = product.bundle

    const productKey = isSubscription ? `subscription` : `common`;
    const firebaseProductsOrder = this.firebaseOrder()?.products?.[productKey] ?? [];
    const newValue = this.updateProductInFirebase(productMapped, firebaseProductsOrder);
    const payload = {
      subKeyPath: `orderProducts.products.${productKey}`,
      newValue
    }

    this.orderService.editSubKey(payload);
  }

  private validateInput(fbMapKey: any, inputValue: number, isSubscription: boolean): void {
    const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    const regex = /^\d+$/;
    if (!productData) return

    productData.validations.isInputValid = regex.test(inputValue.toString());
  }

  private checkValueChange(fbMapKey: number, inputValue: number, isSubscription: boolean, validatePreviousValue: boolean = true): void {
    const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData) return

    if (productData?.validations.isValueChanged || !validatePreviousValue) return
    productData.validations.isValueChanged = inputValue != productData?.previousValue;
  }

  onKeyUp(fbMapKey: any, event: any, isSubscription: boolean): void {
    const { value } = event?.target
    this.validateInput(fbMapKey, +value, isSubscription);
    this.checkValueChange(fbMapKey, +value, isSubscription);

    const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData?.validations?.isInputValid)
      return

    if (+value >= 10)
      productData.validations.wasValueHigher10 = true;

    productData.quantity = +value
  }

  onQuantityChange(product: any, target: any, isSubscription: boolean) {
    product.isASubscription = isSubscription;
    const mapKey = generateMapKeyFromValues([product.firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData) return

    if (+target?.value >= 10)
      productData.validations.wasValueHigher10 = true;

    productData.quantity = +target?.value
    this.validateInput(product.firebaseMapKey, +target?.value, isSubscription);

    let validatePreviousValue = true;
    if (+target?.value <= 9) {
      this.updateQuantity(product, isSubscription);
      validatePreviousValue = false;
    }

    this.checkValueChange(product.firebaseMapKey, +target?.value, isSubscription, validatePreviousValue);
  }

  updateQuantity(product: any, isSubscription: boolean = false) {
    product.isASubscription = isSubscription;
    product.bundleQuantityChanged = true;
    const mapKey = generateMapKeyFromValues([product.firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData) return

    const productMapped = product
    productMapped.quantity = productData.quantity;

    if (product?.bundle?.items)
      productMapped.bundle = product.bundle

    const productKey = isSubscription ? `subscription` : `common`;
    const firebaseProductsOrder = this.firebaseOrder()?.products?.[productKey] ?? [];
    const newValue = this.updateProductInFirebase(productMapped, firebaseProductsOrder);
    if (!this.firebaseOrder()) {
      // Should create the firebase order:
      this.orderService.addProductToFirebaseOrder(productMapped, false, true);
    } else {
      const payload = {
        subKeyPath: `orderProducts.products.${productKey}`,
        newValue
      }

      this.orderService.editSubKey(payload);
    }
  }

  showInputQuantity(firebaseMapKey: any, isSubscription: boolean) {
    const mapKey = generateMapKeyFromValues([firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    return productData?.validations?.wasValueHigher10 || productData?.quantity >= 10
      || (productData?.validations?.isValueChanged && productData?.quantity >= 10)
  }

  checkQuantityChange(firebaseMapKey: any, isSubscription: boolean) {
    const mapKey = generateMapKeyFromValues([firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    return !productData?.validations?.isInputValid || !productData?.validations?.isValueChanged
  }

  onChangeInputBundleItem(bundleProduct: any, event?: any) {
    this.bundleCloned.update((productItem: any) => {
      if (!productItem || !productItem?.bundle || !productItem?.bundle?.items?.length) return

      let totalPrice = 0;
      productItem.bundle.items.forEach((bundle: any) => {
        if (event.target && bundle.id === bundleProduct.id)
          bundle.quantity = +event.target.value

        if (!bundle.isRemoved)
          totalPrice += (bundle?.price ? (+bundle.price * +bundle.quantity) : 0)
      })

      productItem.totalPrice = +totalPrice * +productItem.quantity;

      return productItem
    })
    this.hasPendingBundleChanges.set(true)
  }

  onChangeQuantityBundleItem(bundleProduct: any, target?: any) {
    this.bundleCloned.update((productItem: any) => {
      if (!productItem || !productItem?.bundle || !productItem?.bundle?.items?.length) return

      let totalPrice = 0;
      productItem.bundle.items.forEach((bundle: any) => {
        if (target && bundle.id === bundleProduct.id)
          bundle.quantity = +target.value

        if (!bundle.isRemoved)
          totalPrice += (bundle?.price ? (+bundle.price * +bundle.quantity) : 0)
      })

      productItem.totalPrice = +totalPrice * +productItem.quantity;

      return productItem
    })
    this.hasPendingBundleChanges.set(true)
  }

  openModalTip(action: string) {
    this.signalsStoreService.odooTipAmount.set(this.tipAmount());
    this.modalContentService.openModal(ModalContentTypes.TIP)
      .closed
      .subscribe((res) => {
        if (!res?.amount) return;

        const payload = {
          amount: res?.amount,
          isRecurrent: res?.isRecurrent,
          applyToAllOrders: res?.applyToAllOrders
        }

        if (action === this.createTip) {
          this.tipsDonationsService.create(payload, ModalContentTypes.TIP);
        } else {
          this.tipsDonationsService.update(payload, ModalContentTypes.TIP);
        }
      })
  }

  openModalDonation(action: string) {
    if (this.donationAmountVoluntary())
      this.signalsStoreService.odooDonationAmountVoluntary.set(this.donationAmountVoluntary());

    this.modalContentService.openModal(ModalContentTypes.DONATION)
      .closed
      .subscribe((res) => {
        if (!res?.amount) return;

        const payload = {
          amount: res?.amount,
          isRecurrent: res?.isRecurrent,
          applyToAllOrders: res?.applyToAllOrders
        }

        if (action === ModalContentTypes.CREATE_DONATION) {
          this.tipsDonationsService.create(payload, ModalContentTypes.DONATION);
        } else if (action === ModalContentTypes.UPDATE_DONATION) {
          this.tipsDonationsService.update(payload, ModalContentTypes.DONATION);
        }
      })
  }

  deleteDonationVoluntary() {
    if (this.odooOrder()?.paymentDetails?.donation?.isRecurrent) {
      this.modalContentService.openModal(ModalContentTypes.DELETE_TIP_AND_DONATION)
        .closed
        .subscribe((res) => {
          const applyToAllOrders = Number(res?.applyToAllOrders);
          this.tipsDonationsService.delete(applyToAllOrders, ModalContentTypes.DONATION);
        })
    } else {
      this.tipsDonationsService.delete(0, ModalContentTypes.DONATION);
    }
  }

  deleteTip() {
    if (this.odooOrder()?.paymentDetails?.tip?.isRecurrent) {
      this.modalContentService.openModal(ModalContentTypes.DELETE_TIP_AND_DONATION)
        .closed
        .subscribe((res) => {
          const applyToAllOrders = Number(res?.applyToAllOrders);
          this.tipsDonationsService.delete(applyToAllOrders, ModalContentTypes.TIP);
        });
    } else {
      this.tipsDonationsService.delete(0, ModalContentTypes.TIP);
    }
  }

  openModalDonationSkippable() {
    this.signalsStoreService.totalOrderAmount.set(this.odooOrder()?.paymentDetails?.subTotal || 0)
    this.modalContentService.openModal(ModalContentTypes.DONATION_SKIPPABLE).closed
      .subscribe((res) => {
        if (!('isDonationCanceled' in res) || res?.isDonationCanceled)
          this.updateSkipWeekDeliveryFlags(false);
        else {
          const odooOrder = this.odooOrder();
          if (!odooOrder?.id) return;
          const args = {
            orderId: +odooOrder.id,
            isSkipping: true,
            donationAmount: res?.donationAmount || 0,
          }
          this.orderService.skipOrder(args)
            .subscribe({
              next: (skipRes) => {
                const isOrderSkipped = skipRes?.data?.order?.isSkipped || false;
                this.updateSkipWeekDeliveryFlags(isOrderSkipped);
              }
            });
        }
      })
  }

  openModalSkipWithDonationFlow() {
    this.modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
      title: 'Are you sure?',
      textContent: `You are about to skip your delivery and you will not be receiving your order this week.  Please note that any customized or a la carte products will need to be re-added in future orders.`,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }, { backdrop: 'static' }).closed
      .subscribe((res: { confirm: boolean } | null) => {
        if (!res?.confirm) return this.updateSkipWeekDeliveryFlags(false);
        const odooOrder = this.odooOrder();
        if (!odooOrder?.id) return;
        const args = {
          orderId: +odooOrder.id,
          isSkipping: true,
          donationAmount: 0,
        }
        this.orderService.skipOrder(args)
          .subscribe({
            next: (skipRes) => {
              const isOrderSkipped = skipRes?.data?.order?.isSkipped || false;
              this.updateSkipWeekDeliveryFlags(isOrderSkipped);
            }
          });
      })
  }

  private updateSkipWeekDeliveryFlags(isOrderSkipped: boolean) {
    this.isCheckedSkipWeekDelivery = isOrderSkipped;
    this.isConfirmedSkipWeekDelivery = isOrderSkipped;
  }

  private async openModalOrderNotSubmitted() {
    return new Promise((resolve, reject) => {
      this.modalContentService.openModal(ModalContentTypes.CHECK_SUBMIT_ORDER, { closeable: true })
        .closed
        .subscribe((res) => {
          if (!res?.submitOrder) {
            this.orderService.openModalOrderNotSubmitted.set(false)

            if (res.canDeactivate) {
              this.userChooseContinueShopping = true;
              return resolve(true);
            }
            return resolve(false);
          }

          this.updateOrder(this.odooOrder()?.id);

          return resolve(false);
        })
    })
  }

  private updateProductInFirebase(product: any, productList: any[]): any[] {
    let isProductInList: boolean = false;
    const newProductList = productList.map((productItem) => {
      if ((productItem.package?.id && product.package?.id && productItem.package.id == product.package.id) || ((!productItem.package?.id || !product.package?.id) && productItem.variant.id === product.variant.id)) {
        isProductInList = true;
        return product;
      }
      return productItem;
    });

    product.updatedAt = DateTime.now().toMillis();

    if (!isProductInList)
      newProductList.push(product)

    return newProductList
  }

  private removeProductFromArray(product: any, productList: any[]): any[] {
    return productList.filter((productItem) => productItem.package?.id && product.package?.id ? product.package.id !== productItem.package.id : productItem.variant.id !== product.variant.id);
  }

  parseNumber(num: any) {
    return isNaN(num) ? 0 : num
  }

  deleteAllProducts(isCommonKey: boolean = false) {
    const productKey = !isCommonKey ? `subscription` : `common`;
    const updatedProducts: any = [];
    const payload = {
      subKeyPath: `orderProducts.products.${productKey}`,
      newValue: updatedProducts
    }

    this.orderService.editSubKey(payload);
  }

  deleteProduct(product: any, isSubscription: boolean = false) {

    if (this.isDeletingProduct())
      return;

    product.isDeleting = true;
    this.isDeletingProduct.set(true);

    if (!product.hasPendingChanges) {
      const odooOrder = this.odooOrder();
      if (!odooOrder?.id) return;
      this.orderService
        .deleteProductOrder(+odooOrder.id, product.lineId)
        .pipe(
          finalize(() => {
            this.isDeletingProduct.set(false);
            product.isDeleting = false;
          })
        )
        .subscribe()
      return
    }

    const productKey = isSubscription ? `subscription` : `common`;

    const updateData: any = {}

    if (this.firebaseOrder()?.products) {
      const productsGroup = this.firebaseOrder()?.products?.[productKey]
      const productsGroupUpdated = this.removeProductFromArray(product, productsGroup);
      if (productsGroupUpdated.length !== productsGroup.length) {
        let subKeyPath2 = `orderProducts.products.${productKey}`
        updateData[subKeyPath2] = productsGroupUpdated
      }
    }

    const payload = {
      updateData
    }
    this.orderService.editSubKeys(payload, this.firebaseOrder());

    this.orderService.trackAddedOrDeletedProductLog(product, true).pipe(tap(res => {
      this.isDeletingProduct.set(false);
      product.isDeleting = false;
    })).subscribe();

    if (product.lineId) {
      const odooOrder = this.odooOrder();
      if (!odooOrder?.id) return;

      this.orderService
        .deleteProductOrder(+odooOrder.id, product.lineId)
        .pipe(
          finalize(() => {
            this.isDeletingProduct.set(false);
            product.isDeleting = false;
          })
        )
        .subscribe();
    }
  }

  removeProductFromBundle(productId: number, bundleItem: BundleItems, product: ProductOrder) {
    bundleItem.isRemoved = true;

    if (!this.bundlesRemoved().size) {
      const bundlesRemoved = new Map<number, Map<number, BundleItems>>();
      const bundleRemoved = new Map<number, BundleItems>();
      bundleRemoved.set(bundleItem.id, bundleItem);
      bundlesRemoved.set(productId, bundleRemoved);
      this.bundlesRemoved.set(bundlesRemoved)
    }
    else {
      this.bundlesRemoved.update(productsMap => {
        if (!productsMap.has(productId)) {
          const bundleRemoved = new Map<number, BundleItems>();
          bundleRemoved.set(productId, bundleItem);
          productsMap.set(productId, bundleRemoved)
        }
        else {
          if (!productsMap.get(productId)?.has(bundleItem.id))
            productsMap.get(productId)?.set(bundleItem.id, bundleItem)
        }

        return productsMap
      })
    }

    if (!this.bundlesChanged().size) {
      const bundlesChanged = new Map<number, Map<number, BundleItems>>();
      const bundleChanged = new Map<number, BundleItems>();
      bundleChanged.set(bundleItem.id, bundleItem);
      bundlesChanged.set(productId, bundleChanged);
      this.bundlesChanged.set(bundlesChanged)
    }
    else {
      this.bundlesChanged.update(productsMap => {
        if (!productsMap.has(productId)) {
          const bundleChanged = new Map<number, Partial<BundleItems>>();
          bundleChanged.set(productId, { id: bundleItem.id, isRemoved: true });
          productsMap.set(productId, bundleChanged)
        }
        else {
          if (!productsMap.get(productId)?.has(bundleItem.id))
            productsMap.get(productId)?.set(bundleItem.id, bundleItem)
        }

        return productsMap
      })

    }

    this.onChangeQuantityBundleItem(product)
    this.hasPendingBundleChanges.set(true)

  }

  restoreProductFromBundle(productId: number, bundle: BundleItems, product: ProductOrder) {
    bundle.isRemoved = false;

    // Find product in original bundle items:
    const productBundleItem = product.bundle?.items.find(e => e.id === bundle.id);
    if (productBundleItem) productBundleItem.isRemoved = false;

    if (!this.bundlesRemoved().size)
      return

    this.bundlesRemoved.update(productsMap => {
      if (productsMap.has(productId)) {
        if (productsMap.get(productId)?.has(bundle.id)) {
          if (!productsMap.get(productId)?.size)
            productsMap.delete(productId)
          else
            productsMap.get(productId)?.delete(bundle.id)
        }
      }

      return productsMap
    })

    if (!this.bundlesChanged().size)
      return

    this.bundlesChanged.update(productsMap => {
      if (productsMap.has(productId)) {
        if (productsMap.get(productId)?.has(bundle.id)) {
          if (!productsMap.get(productId)?.size)
            productsMap.delete(productId)
          else
            productsMap.get(productId)?.delete(bundle.id)
        }
      }

      return productsMap
    })

    this.onChangeQuantityBundleItem(product)
    this.hasPendingBundleChanges.set(true)
  }

  discardBundleChanges(product: ProductOrder, subscription: boolean) {
    this.bundlesChanged.set(new Map())
    this.bundlesRemoved.set(new Map())
    this.bundleCloned.set(null)
    this.isEditingBundle = false;
    this.hasPendingBundleChanges.set(false)
    this.toggleCollapse(`${product.id}${subscription ? '_1' : '_0'}`);

  }

  saveBundleChanges(subscription: boolean) {
    const product = this.bundleCloned();
    const productMapped = product
    if (product?.bundle?.items)
      productMapped.bundle = product.bundle

    const productKey = product?.isASubscription || product?.isSubscription ? `subscription` : `common`;

    let subKeyPathProducts = `orderProducts.products.${productKey}`
    const updateData: any = {};
    updateData[subKeyPathProducts] = [productMapped];

    const removeBundleFromFirebaseTrigger = () =>
      this.#removeBundleFromFirebase(productKey, product, updateData, subKeyPathProducts, subscription)

    // TODO WE NEED A BETTER WAY TO VALIDATE IF ALL PRODUCTS WERE REMOVED FROM BUNDLE
    if (!product.totalPrice) {
      if (product.lineId) {
        const odooOrder = this.odooOrder();
        if (!odooOrder?.id) return;
        this.orderService.deleteProductOrder(+odooOrder.id, product.lineId).subscribe();
      }
    } else {
      const order = { products: { [productKey]: [productMapped] } } as FirebaseOrder;
      this.orderService.updateOrder({ order, orderId: this.odooOrder()?.id || undefined, isUpdatingBundle: true }).pipe(
        tap(() => removeBundleFromFirebaseTrigger()),
        tap((res) => {
          trackSubscribeEvent(this.#dataLayerService, res.newSubscriptionProducts);
          trackBeginCheckoutEvent(this.#dataLayerService, this.#calculationsService, [...res.subscriptionProducts, ...res.commonProducts], this.total(), this.coupon(), this.taxes(), this.deliveryFee(), this.tipAmount());
        })
      ).subscribe();
    }

  }

  #removeBundleFromFirebase(productKey: string, product: any, updateData: any, subKeyPathProducts: string, subscription: boolean) {
    this.hasPendingBundleChanges.set(false);
    const productsGroup = this.firebaseOrder()?.products?.[productKey] || [];
    if (!productsGroup.length) return;
    const productsGroupUpdated = this.removeProductFromArray(product, productsGroup);
    if (productsGroupUpdated.length !== productsGroup.length)
      updateData[subKeyPathProducts] = productsGroupUpdated;
    const payload = { updateData }
    this.orderService.editSubKeys(payload, this.firebaseOrder());
    this.bundleCloned.set(null)
    this.isEditingBundle = false;
    this.toggleCollapse(`${product.id}${subscription ? '_1' : '_0'}`);
  }

  cloneBundle(product: ProductOrder, bundleEditionType: BundleEditionType) {
    this.isEditingBundle = true;
    this.bundleCloned.set(JSON.parse(JSON.stringify(product)));
    this.collapseAll();
    this.toggleCollapse(`${product.id}${bundleEditionType === BundleEditionTypes.subscription ? '_1' : '_0'}`);

    this.bundlesRemoved.set(new Map())
    this.bundlesChanged.set(new Map())
    for (const bundleItem of this.bundleCloned().bundle.items) {
      if (bundleItem.isRemoved) {
        this.removeProductFromBundle(this.bundleCloned().id, bundleItem, this.bundleCloned())
      }
    }
  }

  private collapseAll() {
    for (const key in this.collapseStatuses) {
      if (Object.prototype.hasOwnProperty.call(this.collapseStatuses, key)) {
        this.collapseStatuses[key] = true;
      }
    }
  }

  private calculatePercentForFreeDelivery() {
    const subtotal = this.subTotal();
    const minSpend = this.minSpendForFreeDelivery();
    return (subtotal * 100) / minSpend;
  }

  private setUpAddressInfo() {
    const session: Session = this.signalsStoreService.sessionSignal() as any;

    if (!session) return null;

    const { street, city, zip: zipCode, stateCode: state } = session.address;
    const { location, name } = session?.pickUpInfo || {};

    const isAddressRequired = this.signalsStoreService.isAddressRequired();
    return {
      title: isAddressRequired ? 'Delivery Address' : 'Pick Up Address',
      text: isAddressRequired ? [street, city, state, zipCode].join(', ') : `${name} ${location ? `at ${location}` : ''}`
    };
  }

  private setUpDeliveryInfo() {
    if (!this.deliveriesService.deliveryZoneInfo()) return null

    const { deliveryDate, cutoffDate, cutoffTime, deliveryWindow } = (this.deliveriesService.deliveryZoneInfo()?.order || this.deliveriesService.deliveryZoneInfo()) as DeliveryInformation;
    const deliveryDateFormatted = formatDateToReadableString(deliveryDate)
    const cutoffDateFormatted = formatDateToReadableString(cutoffDate)

    const deliveryPickupDateText = this.signalsStoreService.isAddressRequired() ? 'Delivery Date: ' : 'Pick Up Date: '
    return {
      deliveryWindow,
      deliveryDateText: `${this.odooOrder()?.isSkipped ? 'Next ' : ''}${deliveryPickupDateText}${deliveryDateFormatted.dayName} ${deliveryDateFormatted.month} ${deliveryDateFormatted.day}`,
      cutoffDateText: `${cutoffDateFormatted.dayName} ${cutoffDateFormatted.month} ${cutoffDateFormatted.day} at ${cutoffTime}`,
      thanksMessage: this.signalsStoreService.isAddressRequired() ? 'Get excited for your delivery, which will arrive on:' : 'Your order will be ready for pick-up on:'
    }
  }

  private setUpCarouselItems(orderProductType: RELATED_ORDER_PRODUCTS, isOrderSuggestedProduct: boolean = true) {
    const hiddenProducts: number[] = this.hiddenCarouselProducts();
    if (this.odooOrder()?.isSkipped || this.isMobile()) return [];

    if (!this.isContentLoaded || !this.odooOrder()?.relatedProducts?.[orderProductType] || !this.odooOrder()?.relatedProducts?.[orderProductType]?.length)
      if (!this.odooOrder()?.relatedProducts?.[orderProductType] || !this.odooOrder()?.relatedProducts?.[orderProductType]?.length)
        return []

    let items: any = this.odooOrder()?.relatedProducts?.[orderProductType] || [];

    if (isOrderSuggestedProduct) {
      const allProductsId = [...this.products().common, ...this.products().subscription].map((product: any) => product.id)
      items = items.filter((product: any) => !allProductsId.includes(product.id));
    }

    if (hiddenProducts.length)
      items = items.filter((product: any) => hiddenProducts.indexOf(product.variantId) === -1)


    items = items.map((product: any) => {
      product.image = 'assets/images/logo-main.svg';
      product.name = product.name;
      if (typeof product?.price === 'number')
        product.price = product.price.toFixed(2);

      return ({
        content: {
          orderId: this.odooOrder()?.id,
          productDetails: { ...product, isSubscription: false },
          settings: { isCardInOrderPage: true, hideWhenWasOOS: true }
        },
      })
    })

    return items
  }

  toggleCollapse(id: string) {
    this.collapseStatuses[id] = !this.collapseStatuses[id];
  }

  getBundleEditable(product: ProductOrder): any {
    if (this.bundleCloned() && this.bundleCloned()?.id === product.id)
      return this.bundleCloned() as ProductOrder;
    else
      return product as ProductOrder
  }

  updateOrder(odooOrderId?: number | string) {
    if (this.isUpdating())
      return;

    this.isUpdating.set(true);

    if (isANoDeliveryDayUser(this.localStorageService, this.modalContentService))
      return this.isUpdating.set(false);

    if (isANoPaymentMethodUser(this.localStorageService, this.modalContentService, this.activeModal))
      return this.isUpdating.set(false);

    if (isNoAddressUser(this.localStorageService, this.modalContentService, this.activeModal, this.router))
      return this.isUpdating.set(false);

    this.orderService.updateOrder({
      order: this.firebaseOrder(),
      firebaseOrderSignal: this.signalsStoreService.firebaseOrder,
      orderId: odooOrderId,
      deliveryInfo: this.deliveryInfo(),
      showDefaultMessage: false
    })
      .pipe(
        tap((res) => {
          trackSubscribeEvent(this.#dataLayerService, res.newSubscriptionProducts);
          trackBeginCheckoutEvent(this.#dataLayerService, this.#calculationsService, [...res.subscriptionProducts, ...res.commonProducts], this.total(), this.coupon(), this.taxes(), this.deliveryFee(), this.tipAmount());
        }),
        finalize(() => this.isUpdating.set(false))
      )
      .subscribe()
  }

  applyCoupon() {
    if (this.isUpdating()) return;

    this.isUpdating.set(true);
    const odooOrder = this.odooOrder();
    if (odooOrder?.id)
      this.orderService.applyCouponWithOrder(this.coupon, +odooOrder.id)
        .pipe(
          finalize(() => this.isUpdating.set(false))
        ).subscribe();
    else
      this.orderService.updateOrder({
        order: this.firebaseOrder(),
        firebaseOrderSignal: this.signalsStoreService.firebaseOrder,
        coupon: this.coupon,
        deliveryInfo: this.deliveryInfo()
      }).pipe(
        tap((res) => {
          trackSubscribeEvent(this.#dataLayerService, res.newSubscriptionProducts);
          trackBeginCheckoutEvent(this.#dataLayerService, this.#calculationsService, [...res.subscriptionProducts, ...res.commonProducts], this.total(), this.coupon(), this.taxes(), this.deliveryFee(), this.tipAmount());
        }),
        finalize(() => this.isUpdating.set(false))
      ).subscribe();
  }

  gotToEditBundle(product: any, bundleEditioType: BundleEditionType) {
    if (this.bundleModifyType() === this.bundleModifyTypes.default) {
      this.cloneBundle(product, bundleEditioType)
    } else {
      this.allowPageExit = true;
      this.router.navigate([`/shop/custom-box/${bundleEditioType}/${product.id}`]);
    }
  }

  skipProduct(product: any) {
    this.subscriptionsService.update(product.subscription.id, { skip: true }, true).pipe(
      tap(() => this.orderService.getOrder())
    ).subscribe()
  }

  unskipProduct(product: any) {
    this.subscriptionsService.update(product.subscription.id, { unskip: true }, true).pipe(
      tap(() => this.orderService.getOrder())
    ).subscribe()
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  #validateTemporaryRoute() {

    const currentSessionInfo: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!currentSessionInfo)
      return;

    const { temporaryChange } = currentSessionInfo?.deliveryInfo ?? {};
    this.isTemporaryRouteChange.set(!!temporaryChange?.active);

    if (this.isTemporaryRouteChange())
      this.temporaryRouteChangeMessage.set(temporaryChange?.message ?? '');
  }

  #setMutationObserver() {
    if (this.orderActionWrap && this.orderActionWrap.nativeElement) {
      this.mutationObserver.observe(this.orderActionWrap.nativeElement, {
        childList: true,
        subtree: true
      });
    }
  }

  #checkAutoApplicableCoupon() {
    const odooOrder = this.odooOrder();
    if (!odooOrder?.paymentDetails?.coupons?.length) return false;
    const exists = odooOrder.paymentDetails.coupons.some((e: any) => e.autoApply);
    return exists;
  }

  #setUpSkippedSubscriptions() {
    const skippedSubscriptions = this.odooOrder()?.skippedSubscriptions || [];
    return skippedSubscriptions;
  }

  #showThanksModal() {
    const show = this.localStorageService
      .get(LOCALSTORAGE_KEYS.SUBMIT_ORDER_FROM_CUSTOM_BOX);

    if (!show || !this.deliveryInfo())
      return;

    this.localStorageService
      .remove(LOCALSTORAGE_KEYS.SUBMIT_ORDER_FROM_CUSTOM_BOX);

    openModalTanksForSubmit(this.modalContentService, this.deliveryInfo());
  }
}
