import { afterNextRender, Component, computed, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { AccountRecoveryService } from '../account-recovery.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-new-ecommerce-experience',
    imports: [],
    templateUrl: './new-ecommerce-experience.component.html',
    styleUrl: './new-ecommerce-experience.component.scss'
})
export class NewEcommerceExperienceComponent implements OnInit {
  private signalsStoreService = inject(SignalsStoreService);
  private accountRecoveryService = inject(AccountRecoveryService);
  private router = inject(Router);

  contactEmail = signal('');

  constructor() {
    afterNextRender(() => {
      this.contactEmail.set(environment.config.contactEmail);
    })
  }

  ngOnInit(): void {
    this.accountRecoveryService.showSideLogo.set(false);
  }

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  goTo(route: string) {
    this.accountRecoveryService.showSideLogo.set(true);
    this.router.navigate([route]);
  }

}
