import { Component, computed, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from "@angular/router";
import { ResolutionService } from '../resolution.service';
import { OrderService } from "../order.service";
import { SignalsStoreService } from "../signals-store.service";
import { ModalContentService } from '../modal-content/modal-content.service';
import { ModalContentTypes } from '../constants/modal-content-types';

@Component({
    selector: 'app-mobile-footer-menu',
    imports: [RouterLink, RouterLinkActive],
    templateUrl: './mobile-footer-menu.component.html',
    styleUrl: './mobile-footer-menu.component.scss'
})
export class MobileFooterMenuComponent {
  signalsStoreService = inject(SignalsStoreService);
  private orderService = inject(OrderService);
  private resolutionService = inject(ResolutionService);
  private modalContentService = inject(ModalContentService);
  isMobile = computed(() => this.resolutionService.isMobile());
  orderProductsQuantity = computed(() => this.getOrderProductsQuantity());

  getOrderProductsQuantity() {
    const odooOrder = this.orderService.odooOrder();
    const firebaseOrder = this.signalsStoreService.firebaseOrder();
    const allProducts = [
      ...(odooOrder?.products?.common ?? []),
      ...(odooOrder?.products?.subscription ?? []),
      ...(firebaseOrder?.products?.common ?? []),
      ...(firebaseOrder?.products?.subscription ?? []),
    ];

    if (odooOrder?.isSkipped) return 0;

    return new Set(allProducts.map((product: any) => {
      const variantId = product.variant.id;
      const packageId = product.package?.id || null;
      const id = `${variantId}${packageId ? `_${packageId}` : ''}`;
      return id
    })).size;
  }

  openModalBundles() {
    this.modalContentService
      .openModal(
        ModalContentTypes.BUNDLES_SUBSCRIPTION,
        {
          title: 'Your FarmBoxes'
        }
      );
  }
}
