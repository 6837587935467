import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { PagesService } from './pages.service';
import { Location } from '@angular/common';
import { FADEIN_OUT_ANIMATION } from '../shared/constants/animations';
import {FooterComponent} from "../shared/footer/footer.component";

@Component({
    // animations: FADEIN_OUT_ANIMATION,
    selector: 'app-pages',
    imports: [RouterOutlet, RouterLink, RouterLinkActive, FooterComponent],
    providers: [PagesService],
    templateUrl: './pages.component.html',
    styleUrl: './pages.component.scss'
})
export class PagesComponent {
  pagesService = inject(PagesService);
  location = inject(Location);

  goBack() {
    this.location.back();
  }
}
