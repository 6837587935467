import { Component, Inject, OnInit, PLATFORM_ID, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { PagesService } from '../pages.service';
import { NotificationsComponent } from '../../shared/notification/notification.component';
import { Product } from '../../product/product.types';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ProductsService } from '../../product/products.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StockService } from '../../stock/stock.service';
import { tap } from 'rxjs';
import { FormBuilder, ReactiveFormsModule, Validators, FormControl } from '@angular/forms';
import { AutocompleteInputComponent } from '../../authentication/signup/autocomplete-input/autocomplete-input.component';
import { DeliveryAddress } from '../../authentication/signup/signup.types';
import { ModalContentService } from '../../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../../shared/constants/modal-content-types';
import { GiftCardService } from './gift-cards.service';
import { GiftData } from './gift-card.types';
import { NotificationService } from '../../shared/notification/notification.service';
import { unableOperationMessage } from '../../shared/common/utils';
import { Session } from '../../shared/types/session.type';
import { LocalStorageService } from '../../shared/local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../shared/constants/databases';
import { SignalsStoreService } from '../../shared/signals-store.service';
import { environment } from '../../../environments/environment';
import { PurchaseTypes } from '../../shared/types/flows-config.types';
import { ProductCardV2Component } from '../../shared/product-card-v2/product-card-v2.component';

@Component({
  selector: 'app-gift-cards',
  imports: [
    NotificationsComponent,
    CommonModule,
    ProductCardV2Component,
    MatProgressBarModule,
    ReactiveFormsModule,
    AutocompleteInputComponent
  ],
  templateUrl: './gift-cards.component.html',
  styleUrl: './gift-cards.component.scss'
})
export class GiftCardsComponent implements OnInit {

  #pagesService = inject(PagesService);
  #productsService = inject(ProductsService);
  #stockService = inject(StockService);
  #formBuilder = inject(FormBuilder);
  #modalContentService = inject(ModalContentService);
  #giftCardService = inject(GiftCardService);
  #notificationService = inject(NotificationService);
  #localStorageervice = inject(LocalStorageService);
  #signalsStoreService = inject(SignalsStoreService);

  product: Signal<Product | null> = computed(() => this.#productsService.productSignal());
  selectedVariant: WritableSignal<Product | null> = signal(null);
  productCardSummary: Signal<any> = computed(() => this.setUpProductCardSummary());
  productImages: Signal<any[]> = computed(() => this.setUpProductImages());

  hasSession: Signal<boolean> = computed(() => this.#signalsStoreService.hasSession());

  giftCardForm = this.#formBuilder.group({
    senderName: ['', Validators.required],
    senderEmail: ['', [Validators.required, Validators.email]],
    recipientName: ['', [Validators.required]],
    recipientEmail: ['', [Validators.required, Validators.email]],
  });

  personalMessage = new FormControl('');
  recipientsAddress: WritableSignal<Partial<DeliveryAddress | null>> = signal(null);

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    this.#pagesService.restartSideMenu();

    if (!isPlatformBrowser(this.platformId)) return;

    this.#giftCardService.getGiftCardData().pipe(
      tap(data => this.#productsService.productSignal.set(data)),
      tap((data) => this.#stockService.getStock(undefined, [data.id]))
    ).subscribe();

    this.#setUpSessionData();
  }

  #setUpSessionData() {
    const session: Session | null = this.#localStorageervice.get(LOCALSTORAGE_KEYS.SESSION);
    if (!session) return;
    this.giftCardForm.controls.senderEmail.setValue(session.accountInfo.email);
    this.giftCardForm.controls.senderName.setValue(`${session.accountInfo.firstName} ${session.accountInfo.lastName}`);
  }

  setUpProductCardSummary() {
    const product = this.product();
    if (!product) return null;
    localStorage.setItem('selectedProductId', product.id.toString());
    return {
      product: {
        ...product,
        allowVariantsWithoutStock: true
      },
      settings: {
        isSummary: true
      }
    };
  }

  private setUpProductImages() {
    const product = this.product();
    if (!product) return [];
    if (this.selectedVariant()?.img)
      return !!(this.selectedVariant()?.img || this.selectedVariant()?.additionalImages?.length) ? [this.selectedVariant()?.img, ...(this.selectedVariant()?.additionalImages || [])] : ['assets/images/product-placeholder.webp'];
    return !!(product?.img || product?.additionalImages?.length) ? [product?.img, ...product?.additionalImages] : ['assets/images/product-placeholder.webp'];
  }

  validateErrors(controlName: string): string | null {
    const control = this.giftCardForm.get(controlName);
    if (control?.valid || (!control?.dirty && !control?.touched)) return null;
    if (control?.hasError('required')) {
      return `This field is required`
    } else if (control?.hasError('email')) {
      return 'Invalid email format'
    }
    return null;
  }

  onPurchaseGiftCardClicked() {
    this.#openModalPurchase()
  }

  #openModalPurchase() {
    const giftData = this.#setUpGiftData();
    if (!giftData) return;
    if (environment.config.flows.purchase.type === PurchaseTypes.anet) {
      this.#modalContentService.openModal(ModalContentTypes.AUTH_NET_PURCHASE, {
        title: 'Confirm You Purchase',
        authNetPurchase: giftData
      }, {
        size: 'md'
      },)

      return;
    }

    if (environment.config.flows.purchase.type === PurchaseTypes.nmi) {
      this.#modalContentService.openModal(ModalContentTypes.NMI_PURCHASE, {
        title: 'Confirm You Purchase',
        nmiPurchase: giftData
      }, {
        size: 'md'
      },)

      return;
    }
  }

  #setUpGiftData(): GiftData | null {

    const defaultError = (isFromForm: boolean) => {
      this.#notificationService.show({ text: 'Validate mandatory fields', type: 'warning' });
      isFromForm ? this.giftCardForm.markAllAsTouched() : null;
      return null;
    }
    if (!this.giftCardForm.valid) {
      return defaultError(true);
    }

    const values = this.giftCardForm.value;

    if (!values.recipientEmail || !values.recipientName || !values.senderEmail || !values.senderName) {
      return defaultError(true);
    }

    const variant = this.selectedVariant() ?? null;
    if (!variant) {
      unableOperationMessage(this.#modalContentService);
      return null;
    }

    const { variantId, name: variantName, price: variantPrice } = variant;

    if (!this.recipientsAddress()) {
      this.#notificationService.show({ text: 'You should select a valid address', type: 'warning' });
      return null;
    }

    return {
      ...values,
      personalMessage: this.personalMessage.value ?? '',
      variantId,
      variantName,
      variantPrice
    } as GiftData
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

}
