import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, QueryList, Signal, ViewChildren, afterNextRender, computed, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalsStoreService } from '../shared/signals-store.service';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { ProductsService } from '../product/products.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { toObservable } from '@angular/core/rxjs-interop';
import { auditTime, bufferWhen, filter, map, mergeMap, of, scan, startWith, Subject, switchMap, tap, timer } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StockService } from '../stock/stock.service';
import { LARGE } from "../../scss/responsive/responsive";
import { HeaderService } from '../shared/header/header.service';
import { ResolutionService } from '../shared/resolution.service';
import { EmptyMessageComponent } from '../shared/empty-message/empty-message.component';
import { BasicBundleInfo } from '../shared/types/order.type';
import { OrderService } from '../shared/order.service';
import { MenuService } from '../shared/menu.service';
import { ShopService } from './shop.service';
import { Router } from '@angular/router';
import { FooterComponent } from '../shared/footer/footer.component';
import { FilteringTagsComponent } from './filtering-tags/filtering-tags.component';
import { Item } from '../shared/types/common.types';
import { environment } from '../../environments/environment';
import { capitalizeWords } from '../shared/common/utils';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { ProductCardV2Component } from '../shared/product-card-v2/product-card-v2.component';
import { Product, ProductResume } from '../product/product.types';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
  imports: [
    CommonModule,
    SidebarComponent,
    NgbModule,
    MatProgressBarModule,
    EmptyMessageComponent,
    FooterComponent,
    FilteringTagsComponent,
    MatFormFieldModule,
    MatChipsModule,
    ProductCardV2Component
  ]
})
export class ShopComponent implements OnInit, OnDestroy {

  @ViewChildren('subcategory')
  subcategories!: QueryList<ElementRef>;

  @ViewChildren('subcategorySon')
  subcategoriesSon!: QueryList<ElementRef>;

  #productsService = inject(ProductsService);
  #headerService = inject(HeaderService);
  private resolutionService = inject(ResolutionService);
  #orderService = inject(OrderService);
  #router = inject(Router);
  #menuService = inject(MenuService);
  #shopService = inject(ShopService);

  filteringTags: Signal<Item[]> = computed(() => this.#productsService.filteringTags());

  categoryBanner = computed(() => this.#shopService.categoryBanner());

  signalsStore = inject(SignalsStoreService);
  stockService = inject(StockService);

  isSidebarOpen = this.signalsStore.isSidebarOpen;
  hasSession = this.signalsStore.hasSession;
  selectedCategory = this.signalsStore.selectedCategory;

  productsSignal = computed(() => this.#setUpProductsSignal());
  categoriesSignal: any = computed(() => this.#productsService.productsSignal());
  marketStatus = computed(() => this.signalsStore.marketStatus());
  closedMarket = computed(() => {
    const im = this.isMobile();
    const ms = this.marketStatus();
    return {
      title: !ms.isOpen && im ?
        `Our Online Farmer's Market Opens ${capitalizeWords(ms.openingDay)} at ${ms.openingHour}!` :
        `Our Online Farmer's Market Opens ${capitalizeWords(ms.openingDay)} at ${ms.openingHour}!`,
      legend: `We're busy sourcing next week's availability! Check back ${capitalizeWords(ms.openingDay)} to begin shopping.`
    };
  })
  sidebarChanged = toObservable(this.signalsStore.sidebarFilterChanged);

  isContentLoading = computed(() => this.stockService.isLoading() || this.#productsService.isLoading());
  isContentLoaded = computed(() => this.stockService.isLoaded() || this.#productsService.isLoaded());

  isMobile = computed(() => this.resolutionService.isMobile());

  bundles = computed(() => this.signalsStore.getBundlesLikeSubscriptions(true));

  emptyMessage = 'No products were found.';
  hasGlobalMessages = computed(() => !!this.signalsStore.globalMessages().length);

  //#region flows config
  displayCategoryName = signal(environment.config.flows.shop.displayCategoryName);
  //#endregion

  productDetails = signal<Map<number, Product>>(new Map()); // Cache of product details
  #visibleProducts$ = new Subject<number[]>(); // IDs of products that are visible in the viewport

  filters = computed(() => this.signalsStore.getFilters());

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    afterNextRender(() => {
      const isMobile = window.innerWidth <= LARGE;
      if (isMobile) this.signalsStore.isSidebarOpen.set(false);
    });
  }

  ngOnInit(): void {

    this.signalsStore.setIsInShopComponent(true);

    this.sidebarChanged
      .pipe(tap(() => this.#productsService.filterBySidebarChange()))
      .subscribe();
    this.#headerService.producerRedirection();

    if (this.signalsStore.filterByProducer()) {
      setTimeout(() => {
        this.#headerService.producerRedirection();
      }, 500);
    }

    this.#setupBatchRequests();
  }

  ngOnDestroy(): void {
    this.#headerService.clearFilters(true, false);
    this.signalsStore.setIsInShopComponent(false);
  }

  scrollToTop(): void {
    const scrollToTop = document.getElementById('scroll-to-top-ref');
    if (scrollToTop) {
      scrollToTop.scrollIntoView({ block: 'start' });
    }
  }

  onScroll(event: any) {

    // Nothing products in shop

    if (!this.subcategories.length)
      return;

    const contentY = document
      .getElementsByClassName('layout-shop__main')[0]
      ?.getBoundingClientRect().y ?? 0;

    if (!contentY)
      return;

    // Search the element most near when scroll is down.

    let categoryId = null;
    let subCategoryId = null;
    let subCategorySonId = null;

    // Validate if the scroll is bottom

    let element: ElementRef<any> | undefined;
    const isBottom = event.srcElement.scrollHeight - event.srcElement.scrollTop <= event.srcElement.clientHeight + 5;

    if (isBottom) {
      element = this.subcategories.toArray().reverse()[0];
      if (element?.nativeElement?.getAttribute('sub-category-son-id'))
        element = this.subcategoriesSon.toArray().reverse()[0];
    }
    else {

      element = this.subcategories
        .find(x => {
          const diffY = x.nativeElement.getBoundingClientRect().y - contentY;

          if (diffY < 0) return false;
          return diffY >= -300 && diffY <= 100;
        });

      let elementChild = this.subcategoriesSon
        .find(x => {
          const diffY = x.nativeElement.getBoundingClientRect().y - contentY;

          if (diffY < 0) return false;
          return diffY >= -300 && diffY <= 110;
        });

      if (elementChild)
        element = elementChild;

      if (!element) {

        // Search the last element most near when scroll is up.

        const sub = this.subcategories
          .toArray()
          .reverse()
          .map(x => {
            const diffY = x.nativeElement.getBoundingClientRect().y - contentY;
            return {
              ...x,
              diffY
            };
          })
          .find(x => {
            return x.diffY < 0;
          });

        const subson = this.subcategoriesSon
          .toArray()
          .reverse()
          .map(x => {
            const diffY = x.nativeElement.getBoundingClientRect().y - contentY;
            return {
              ...x,
              diffY
            };
          })
          .find(x => {
            return x.diffY < 0;
          });

        if (subson === undefined)
          element = sub;
        else
          element = (sub?.diffY ?? 0) >= (subson?.diffY ?? 0) ? sub : subson;
      }
    }

    if (!element)
      return;

    // Get the category and subcategory attibutes

    categoryId = +element.nativeElement.getAttribute('category-id');
    subCategoryId = +element.nativeElement.getAttribute('sub-category-id');
    subCategorySonId = +element.nativeElement.getAttribute('sub-category-son-id');

    // If yet not change of category, don't do nothing.

    if (this.signalsStore.categoryInViewPort()?.categoryId === categoryId &&
      this.signalsStore.categoryInViewPort()?.subCategoryId === subCategoryId &&
      this.signalsStore.categoryInViewPort()?.subCategorySonId === subCategorySonId)
      return;

    // Emit the new visible category.

    this.signalsStore
      .categoryInViewPort
      .set({
        categoryId,
        subCategoryId,
        subCategorySonId
      });
  }

  goToCustomBox(bundle: BasicBundleInfo) {
    this.#router
      .navigate([`/shop/custom-box/subscription/${bundle.id}`]);
  }

  #getCategoryBanner(categoryId: number, subCategoryId?: number, subCategorySonId?: number) {

    let item = this.#menuService.menu()
      .find(x => x.id === categoryId);

    if (subCategoryId)
      item = item?.subCategories.find((x: any) => x.id === subCategoryId);

    if (subCategorySonId)
      item = item?.subCategories.find((x: any) => x.id === subCategorySonId);

    return this.#validateBanner(item);
  }

  #validateBanner(item: any): { banner: string, url: string, show: boolean } {

    const banner = item?.banners[this.isMobile() ? 'mobile' : 'desktop'];

    if (!banner)
      return { banner: '', url: '', show: true };

    return {
      banner,
      url: item.banners.url,
      show: true
    };
  }

  getSubCategoryBanner(categoryId: number, subCategoryId: number): { banner: string, url: string } | null {

    const category = this.#menuService.menu().find(x => x.id === categoryId);

    if (!category) return null;

    const subcategory = category.subCategories
      .find((x: any) => x.id === subCategoryId);

    const banner = subcategory.banners[this.isMobile() ? 'mobile' : 'desktop'];

    if (!banner) return null;

    return {
      banner,
      url: subcategory.banners.url
    };
  }

  #setupBatchRequests() {
    const noActivity$ = this.#visibleProducts$.pipe(
      switchMap(() => timer(100).pipe(map(() => true))), // Si no hay nuevos valores en 500ms → inactividad
      startWith(false) // Al inicio hay actividad
    );

    this.#visibleProducts$.pipe(
      bufferWhen(() => noActivity$.pipe(filter(inactive => inactive))), // Espera a que haya inactividad
      filter(productGroups => productGroups.length > 0), // Evita procesar arrays vacíos
      mergeMap(productGroups => productGroups.flat()), // 🔄 Aplana el array → number[]
      scan((acc: number[], productId: number) => [...new Set([...acc, productId])], []), // 📌 Acumula productos únicos
      auditTime(500), // ⏳ Espera estabilidad antes de hacer la petición
      switchMap(productIds => {
        // console.log("📌 Productos acumulados para fetch:", productIds);
        const idsToFetch = productIds.filter(id => !this.productDetails().has(id));
        // console.log("⚠️ Productos a buscar:", idsToFetch);
        return idsToFetch.length > 0 ? this.#productsService.getProductsDetailsByIds(idsToFetch) : of({});
      })
    ).subscribe(details => {
      this.productDetails.update(prev => {
        // console.log("✅ Detalles recibidos:", details);
        const updatedMap = new Map(prev); // Clonar el Map existente
        Object.entries(details).forEach(([index, detail]) => {
          const productDetail: Product = detail as Product;
          updatedMap.set(Number(productDetail.id), productDetail as Product); // Convertir id a número y agregarlo
        });
        return updatedMap;
      });
    });
  }

  onProductCardReady(productId: number) {
    if (this.productDetails().has(productId)) return;
    else this.#visibleProducts$.next([productId]);
  }

  onProductCardHover(productId: number) {
    if (this.productDetails().has(productId)) return;
    else this.#visibleProducts$.next([productId]);
  }

  removeChipFilter(key: string) {
    if (key && this.filters().has(key)) {
      const type = this.filters().get(key)?.type;
      if (!type) return;

      this.signalsStore.setFilterChipRemoved(key);
    }
  }

  #setUpProductsSignal() {
    const products = this.#productsService.productsSignal();

    let lastCategory: number;
    let lastSubcategory: number;
    let lastSubcategorySon: number;

    if (!products.length) return [];
    this.#gotoSelectedProduct();

    return products.map((product: any) => {

      product.subCategorySonInView = null;
      product.categoryInView = null;
      product.subCategoryInView = null;

      let categoryId = 0,
        subCategoryId = 0,
        subCategorySonId = 0;

      if (product.category) {
        categoryId = +product.category.id;
        if (categoryId !== lastCategory) {
          product.categoryInView = this.#getCategoryBanner(categoryId);
          lastCategory = categoryId;
        }
      }

      if (product.subcategory) {

        subCategoryId = +product.subcategory.id;
        if (subCategoryId !== lastSubcategory) {
          product.subCategoryInView = this.#getCategoryBanner(categoryId, subCategoryId);
          lastSubcategory = subCategoryId;
        }
      }

      if (product.subcategorySon) {
        subCategorySonId = +product.subcategorySon.id;
        if (subCategorySonId !== lastSubcategorySon) {
          product.subCategorySonInView = this.#getCategoryBanner(categoryId, subCategoryId, subCategorySonId);
          lastSubcategorySon = subCategorySonId;
        }
      }

      return product as ProductResume;
    }) as ProductResume[];
  }

  #gotoSelectedProduct() {
    setTimeout(() => {
      const selectedProductId = localStorage.getItem('selectedProductId');
      if (selectedProductId != null) {
        const scrollToTop = document.getElementById(selectedProductId!.toString());
        if (scrollToTop) {

          const parent = document.getElementById('layoutShopMain');
          parent?.scrollTo({
            top: (scrollToTop.offsetTop - 100),
            left: 0,
            behavior: 'instant'
          });

          localStorage.removeItem('selectedProductId');
        }
      }
    }, 500);
  }
}
